import React from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import { useAppContext } from '../../contexts/AppContext'
import ButtonLangSK from '../Buttons/ButtonLangSK'
import ButtonLangEN from '../Buttons/ButtonLangEN'
import MenuSection from './MenuSection'
import { useLanguageContext } from '../../contexts/LanguageContext'
import background from "../../v2/assets/PROTOTYP/UI/MENU_bg_paralax.png";
import BackgroundWave from "../../v2/components/BackgroundWave/BackgroundWave";
import ButtonMenu from "../../v2/components/Buttons/ButtonMenu";
import Logo from "../../v2/components/Logo/Logo";
import PersonAnimation from "../../v2/components/PersonAnimation/PersonAnimation";

const Menu = () => {
  const navigate = useNavigate()
  const { isShownMenu, setIsShownMenu } = useAppContext()

  const { language, setLanguage } = useLanguageContext()

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: '100',
        opacity: isShownMenu ? '1' : '0',
        pointerEvents: !isShownMenu ? 'none' : 'all',
        transition: 'opacity 500ms ease 0s',
        overflow: 'hidden',
      }}
    >
      <BackgroundWave
          background={background}
      />

      <PersonAnimation />

      <Logo />

      <ButtonMenu type={'close'} />

      {language === 'sk' ? (
          <ButtonLangEN
              onClick={() => {
                setIsShownMenu(false)
                setLanguage('en')
              }}
          />
      ) : (
          <ButtonLangSK
              onClick={() => {
                setIsShownMenu(false)
                setLanguage('sk')
              }}
          />
      )}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'start',
          justifyContent: 'center',
          padding: '2% 7%',
          gap: '24px',
        }}
      >
        <MenuSection
          title={
            {
              sk: <>&nbsp; <br/> &nbsp; <br/> &nbsp; <br/> ÚVOD DO TÉMY</>,
              en: <>&nbsp; <br/> &nbsp; <br/> Introduction to <br/> the topic</>,
            }[language]
          }
          buttons={[
            {
              text: {
                sk: 'Čo je ENERGETICKÁ kríza a ako vznikla?',
                en: 'What is the energy crisis and why did it occur',
              }[language],
              onClick: () => {
                setIsShownMenu(false)
                navigate('/chapter-2?slide=0')
              },
            },
            {
              text: {
                sk: 'Čo je klimatická kríza a ako vznikla?',
                en: 'What is the climate crisis and how did it occur?',
              }[language],
              onClick: () => {
                setIsShownMenu(false)
                navigate('/chapter-3?slide=0')
              },
            },
            {
              text: {
                sk: 'Ako zmierniť tempo klimatickej zmeny?',
                en: 'How to mitigate the pace of climate change?',
              }[language],
              onClick: () => {
                setIsShownMenu(false)
                navigate('/chapter-4?slide=0')
              },
            },
          ]}
        />

        <MenuSection
          title={
            {
              sk: <>Čo je zelená <br/> architektúra <br/> a zelené <br/> stavebníctvo</>,
              en: <>What is green <br/> architecture <br/> and green <br/> building sector</>,
            }[language]
          }
          buttons={[
            {
              text: {
                sk: 'ZELENÉ BUDOVY',
                en: 'Green buildings',
              }[language],
              onClick: () => {
                setIsShownMenu(false)
                navigate('/chapter-5?slide=1')
              },
            },
            {
              text: {
                sk: 'CIRKULÁRNA EKONOMIKA',
                en: 'Circular economy',
              }[language],
              onClick: () => {
                setIsShownMenu(false)
                navigate('/chapter-6?slide=0')
              },
            },
            {
              text: {
                sk: 'Meranie a certifikácia „zelenosti“ budov',
                en: 'Measuring and certifying the "greenness" of buildings',
              }[language],
              onClick: () => {
                setIsShownMenu(false)
                navigate('/chapter-7?slide=0')
              },
            },
            {
              text: {
                sk: 'Smart budovy A ZELENÉ MESTSKÉ ŠTVRTE',
                en: 'Smart buildings',
              }[language],
              onClick: () => {
                setIsShownMenu(false)
                navigate('/chapter-8?slide=0')
              },
            },
            {
              text: {
                sk: 'ZELENÉ STAVEBNÍCTVO',
                en: 'Green building sector',
              }[language],
              onClick: () => {
                setIsShownMenu(false)
                navigate('/chapter-9?slide=0')
              },
            },
            {
              text: {
                sk: 'ZELENÉ STRECHY A ZELENÉ FASÁDY',
                en: 'Green roofs and green facades',
              }[language],
              onClick: () => {
                setIsShownMenu(false)
                navigate('/chapter-10?slide=0')
              },
            },
            {
              text: {
                sk: 'TECHNOLÓGIE V ZELENEJ ARCHITEKTÚRE A STAVEBNÍCTVE',
                en: 'Technologies in green architecture and construction sector',
              }[language],
              onClick: () => {
                setIsShownMenu(false)
                navigate('/chapter-11?slide=0')
              },
            },
            {
              text: {
                sk: 'CIRKULÁRNE A MODULÁRNE STAVBY',
                en: 'Circular and modular buildings',
              }[language],
              onClick: () => {
                setIsShownMenu(false)
                navigate('/chapter-12?slide=0')
              },
            },
          ]}
        />

        <MenuSection
            title={
              {
                sk: <>PRÍKLADY <br/> ZELENEJ <br/> ARCHITEKTÚRY <br/> NA SLOVENKU</>,
                en: <>Examples <br/> of green <br/> architecture <br/> in Slovakia</>,
              }[language]
            }
            buttons={[
              {
                text: {
                  sk: 'Einpark Offices - Bratislava',
                  en: 'Einpark Offices - Bratislava',
                }[language],
                onClick: () => {
                  setIsShownMenu(false)
                  navigate('/chapter-13?slide=2')
                },
              },
              {
                text: {
                  sk: 'Pradiareň 1900 - Bratislava',
                  en: 'Yarn spinning mill 1900 - Bratislava',
                }[language],
                onClick: () => {
                  setIsShownMenu(false)
                  navigate('/chapter-13?slide=6')
                },
              },
              {
                text: {
                  sk: 'Zukermandel a budova ČSOB - Bratislava',
                  en: 'Zukermandel and the ČSOB building - Bratislava',
                }[language],
                onClick: () => {
                  setIsShownMenu(false)
                  navigate('/chapter-13?slide=9')
                },
              },
              {
                text: {
                  sk: 'Komplex Bastion Office Center - Košice',
                  en: 'Bastion Office Centre complex - Košice',
                }[language],
                onClick: () => {
                  setIsShownMenu(false)
                  navigate('/chapter-13?slide=11')
                },
              },
              {
                text: {
                  sk: 'Rodinný dom - Horná Mičiná',
                  en: 'Family house - Horná Mičiná',
                }[language],
                onClick: () => {
                  setIsShownMenu(false)
                  navigate('/chapter-13?slide=14')
                },
              },
              {
                text: {
                  sk: 'RIVER PARK - BRATISLAVA',
                  en: 'RIVER PARK - BRATISLAVA',
                }[language],
                onClick: () => {
                  setIsShownMenu(false)
                  navigate('/chapter-13?slide=18')
                },
              },
              {
                text: {
                  sk: 'EUROVEA CITY 2 - BRATISLAVA',
                  en: 'EUROVEA CITY 2 - BRATISLAVA',
                }[language],
                onClick: () => {
                  setIsShownMenu(false)
                  navigate('/chapter-13?slide=20')
                },
              },
              {
                text: {
                  sk: 'WESTEND - Bratislava',
                  en: 'WESTEND - Bratislava',
                }[language],
                onClick: () => {
                  setIsShownMenu(false)
                  navigate('/chapter-13?slide=22')
                },
              },
            ]}
        />

        <MenuSection
            title={
              {
                sk: <>PRÍKLADY <br/> ZELENEJ <br/> ARCHITEKTÚRY <br/> V ZAHRANIČÍ</>,
                en: <>examples <br/> of green <br/> architecture <br/> abroad</>,
              }[language]
            }
            buttons={[
              {
                text: {
                  sk: 'BUDOVA IKEA - RAKÚSKO',
                  en: 'IKEA building - Austria',
                }[language],
                onClick: () => {
                  setIsShownMenu(false)
                  navigate('/chapter-14?slide=3')
                },
              },
              {
                text: {
                  sk: 'Kancelárska budova Bloomberg - Veľká Británia',
                  en: 'Bloomberg Office Building - London - UK',
                }[language],
                onClick: () => {
                  setIsShownMenu(false)
                  navigate('/chapter-14?slide=4')
                },
              },
              {
                text: {
                  sk: 'Deloitte univerzita - Francúzsko',
                  en: 'Deloitte University of Paris - France',
                }[language],
                onClick: () => {
                  setIsShownMenu(false)
                  navigate('/chapter-14?slide=6')
                },
              },
              {
                text: {
                  sk: 'Cube - Nemecko',
                  en: 'Cube - Germany',
                }[language],
                onClick: () => {
                  setIsShownMenu(false)
                  navigate('/chapter-14?slide=7')
                },
              },
              {
                text: {
                  sk: 'BetonSilo - Nemecko',
                  en: 'BetonSilo - Germany',
                }[language],
                onClick: () => {
                  setIsShownMenu(false)
                  navigate('/chapter-14?slide=8')
                },
              },
              {
                text: {
                  sk: 'Administratívne centrum - Spojené arabské emiráty',
                  en: 'Sharjah Administrative Centre - United Arab Emirates',
                }[language],
                onClick: () => {
                  setIsShownMenu(false)
                  navigate('/chapter-14?slide=9')
                },
              },
            ]}
        />
      </Box>

      <Box
          sx={{
            padding: '0 7%',
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'center',
          }}
      >
        <Box
            sx={{
              width: 'calc(25% - 18px)',
            }}
        >
          <MenuSection
              sx={{
                maxWidth: 'unset',
              }}
              title={
                {
                  sk: 'KVÍZ',
                  en: 'KVÍZ',
                }[language]
              }
              buttons={[
                {
                  text: {
                    sk: 'SPUSTI KVÍZ',
                    en: 'SPUSTI KVÍZ',
                  }[language],
                  onClick: () => {
                    setIsShownMenu(false)
                    navigate('/quiz?slide=0')
                  },
                },
              ]}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default Menu
