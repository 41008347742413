import React from 'react'
import Animated from "../v2/components/Animated/Animated";
import ChapterTitle from "../v2/components/ChapterTitle/ChapterTitle";
import backgroundk02 from "../v2/assets/PROTOTYP/K12/K12-0.png";
import animation1 from '../v2/assets/PROTOTYP/K12/K12-1/k12-1.mp4'
import animation2sk from '../v2/assets/PROTOTYP/K12/K12-2/K12-2.mp4'
import animation2en from '../v2/assets/PROTOTYP/K12/K12-2/K12-2-EN.mp4'
import animation3sk from '../v2/assets/PROTOTYP/K12/K12-3/K12-3.mp4'
import animation3en from '../v2/assets/PROTOTYP/K12/K12-3/K12-3-EN.mp4'
import voiceoverK02_1SK from '../v2/assets/VOICEOVER/VOICEOVER_SK/K12_1.mp3'
import voiceoverK02_1EN from '../v2/assets/VOICEOVER/VOICEOVER_EN/K12_1.mp3'
import voiceoverK02_2SK from '../v2/assets/VOICEOVER/VOICEOVER_SK/K12_2.mp3'
import voiceoverK02_2EN from '../v2/assets/VOICEOVER/VOICEOVER_EN/K12_2.mp3'
import voiceoverK02_3SK from '../v2/assets/VOICEOVER/VOICEOVER_SK/K12_3.mp3'
import voiceoverK02_3EN from '../v2/assets/VOICEOVER/VOICEOVER_EN/K12_3.mp3'
import bg from "../v2/assets/PROTOTYP/K12/K12-DOPLNKOVESTUDIUM.jpg";

const title1 = {
  sk: 'CIRKULÁRNE A MODULÁRNE STAVBY',
  en: 'Circular and modular buildings',
}

const textImage1 = {
  sk: (
      <>
          <b>Viete čo sú to cirkulárne a modulárne stavby? </b>
          <br/><br/>
          Sú to také stavby, ktoré sa dajú veľmi rýchlo rozobrať na prvky a materiály, ktoré sa dajú opätovne použiť na výstavbu inej stavby.
      </>
  ),
  en: (
      <>
          <b>Do you know what circular and modular buildings are?</b>
          <br/><br/>
          They are buildings that can be disassembled very quickly into elements and materials that can be reused to build another building.
      </>
  )
}

const textImage2 = {
  sk: (
      <>
          Všetky produkty, materiály a suroviny z týchto budov môžu byť <b>opakovane využívané.</b> Preto je cirkulárny a modulárny systém výstavby pokladaný za veľmi zelený a udržateľný.
      </>
  ),
  en: (
      <>
          All the products, materials and raw materials from these buildings can be <b>reused.</b> Therefore, the circular and modular construction system is considered to be very green and sustainable.
      </>
  )
}

const textImage3 = {
  sk: (
      <>
          Predstavte si budovu, ktorá už doslúžila svojmu účelu. Napríklad v malej obci bola postavená materská škôlka takýmto <b>modulárnym systémom.</b>
          <br/><br/>
          Deti však postupne vyrástli a tak sa obec rozhodla, že už <b>nepotrebujú škôlku,</b> ale že <b>potrebujú rozšíriť budovu školy.</b>
          <br/><br/>
          Budovu materskej školy jednoducho <b>rozoberú</b> a prvky a materiály z nej využijú <b>na výstavbu rozšírenia</b> základnej školy v obci.
      </>
  ),
  en: (
      <>
          Imagine a building that has already served its purpose. For example, in a small village a kindergarten was built using such a <b>modular system.</b>
          <br/><br/>
          However, the children gradually grew up and so the village decided <b>that they no longer needed a nursery,</b> but that <b>they needed to extend the school building.</b>
          <br/><br/>
          They <b>simply dismantle</b> the kindergarten building and use the elements and materials from it <b>to build an extension</b> to the primary school in the village.
      </>
  )
}

const chapter12 = {
  url: '/chapter-12',
  name: 'chapter12',
  slides: [
    {
      Component: ChapterTitle,
      props: {
        title: {
            sk: <>CIRKULÁRNE A MODULÁRNE <br/> STAVBY</>,
            en: <>Circular and modular <br/> buildings</>,
        },
        background: backgroundk02,
      },
    },
    {
      Component: Animated,
      props: {
        placement: 'right',
        animation: animation1,
        title: title1,
        voiceover: {
          sk: voiceoverK02_1SK,
          en: voiceoverK02_1EN,
        },
        text: textImage1,
      },
    },
    {
      Component: Animated,
      props: {
        placement: 'right',
        animation: {
            sk: animation2sk,
            en: animation2en,
        },
        title: title1,
        voiceover: {
            sk: voiceoverK02_2SK,
            en: voiceoverK02_2EN,
        },
        text: textImage2,
      },
    },
    {
      Component: Animated,
      props: {
        placement: 'right',
        animation: {
            sk: animation3sk,
            en: animation3en,
        },
        title: title1,
        voiceover: {
            sk: voiceoverK02_3SK,
            en: voiceoverK02_3EN,
        },
        text: textImage3,
          additionalStudy: [
              {
                  source: 'https://www.sipeurope.eu',
                  img: bg,
                  text: {
                      sk: (
                          <>
                              Ak sú budovy postavené tak, že je ich možné veľmi jednoducho <b>rozobrať</b> na <b>prvky a materiály</b> a tie použiť na výstavbu inej budovy, ide o tzv. <b>cirkulárnu alebo modulárnu stavbu.</b> <br/> <br/> Na Slovensku už tiež existujú výrobcovia modulárnych stavieb a starostovia obcí začínajú využívať modulárnu výstavbu pri riešení aktuálnych problémov v obci. Napríklad aj na spomínané zvýšenie počtu miest v <b>materských školách</b> alebo aj na riešenie <b>dočasného ubytovania</b> pre utečencov.
                          </>
                      ),
                      en: (
                          <>
                              If the buildings are constructed in such a way that they can very easily be <b>dismantled into elements and materials</b> and used to construct another building, this is called <b>circular or modular construction.</b> <br/><br/>
                              There are also already builders of modular buildings in Slovakia, and mayors of municipalities are beginning to use modular construction to solve current problems in the municipality. For example, for the above increase in the number of places in <b>kindergartens,</b> or even for the solution of <b>temporary accommodation</b> for refugees.
                          </>
                      ),
                  },
              },
          ],
      },
    },
  ],
}

export default chapter12
