import SliderWrapper from '../../../components/Slider/SliderWrapper'
import React, { memo, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import useSound from 'use-sound'
import ButtonMenu from "../Buttons/ButtonMenu";
import ButtonLeft from "../Buttons/ButtonLeft";
import ButtonRight from "../Buttons/ButtonRight";
import Header from "../Header/Header";
import Logo from "../Logo/Logo";
import Typography from "@mui/material/Typography";
import fonts from "../../../styles/fonts";
import ButtonPlay from "../Buttons/ButtonPlay";
import ButtonPause from "../Buttons/ButtonPause";
import ReactPlayer from "react-player";
import AdditionalStudy from "../AdditionalStudy/AdditionalStudy";

const TextSlide = ({
    placement,
    animation,
    image,
  voiceover,
  isActive,
  language,
  text,
  title,
  goToPrevSlide,
  goToNextSlide,
  volume,
    loop = true,
                      additionalStudy,
}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isLoadedBackground, setIsLoadedBackground] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [playVoiceover, { stop, pause }] = useSound(voiceover?.[language], {
    onload: () => {
      setIsLoaded(true)
    },
    onend: () => {
      setIsPlaying(false)
    },
  })

  const onPlay = () => {
    setIsPlaying(true)
    playVoiceover()
  }

  const onPause = () => {
    setIsPlaying(false)
    pause()
  }

  const onStop = () => {
    setIsPlaying(false)
    stop()
  }

  useEffect(() => {
    if (isLoaded) {
      if (isActive) {
        onPlay()
      } else {
        onStop()
      }
    }

    return () => onStop()
  }, [isLoaded, isActive])

  useEffect(() => {
    setIsLoaded(false)
    onStop()
  }, [language])

  return (
    <SliderWrapper flexCenter sx={{ background: '#3B5A31' }}>
        <ButtonMenu
            variant={1}
            sx={{
                zIndex: '20',
            }}
        />

        <Logo
            color={'#112232'}
            sx={{
                zIndex: '18',
            }}
        />

      {voiceover && (
        <>
          <ButtonPlay
            sx={{
              zIndex: '20',
              opacity: isPlaying ? '1' : '0',
              pointerEvents: !isPlaying ? 'none' : 'all',
              transition: 'opacity 500ms ease 0s',
            }}
            onClick={onPause}
          />

          <ButtonPause
            sx={{
              zIndex: '20',
              opacity: !isPlaying ? '1' : '0',
              pointerEvents: isPlaying ? 'none' : 'all',
              transition: 'opacity 500ms ease 0s',
            }}
            onClick={onPlay}
          />
        </>
      )}

      <ButtonLeft
        isShown
        variant={1}
        onClick={goToPrevSlide}
        sx={{
          zIndex: '18',
        }}
      />

        <ButtonRight
            isShown
            variant={1}
            onClick={goToNextSlide}
            sx={{
                zIndex: '18',
            }}
        />

        <Box
            sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                translate: '-50% -50%',
                width: '58%',
            }}
        >
            <Typography
                sx={{
                    ...fonts.Rubik['42px']({
                        lineHeight: 54,
                        color: '#3AD3A4',
                    }),
                }}
            >
                {text[language]}
            </Typography>
        </Box>

        {additionalStudy && <AdditionalStudy additionalStudy={additionalStudy} language={language} />}
    </SliderWrapper>
  )
}

export default memo(TextSlide)
