import React, { useEffect, useRef, useState } from 'react'
import SliderWrapper from '../Slider/SliderWrapper'
import StripGlasses from './StripGlasses'
import StripSwitch from './StripSwitch'
import Box from '@mui/material/Box'
import StripImages from './StripImages'
import StripTriggers from './StripTriggers'
import Modal from '../Modal/Modal'
import ModalWrapper from '../Modal/ModalWrapper'
import BackgroundChapter from '../Background/BackgroundChapter'
import useSound from 'use-sound'
import stripesSound from '../../assets/_Sfx/__Compress/Stripes.mp3'
import magicSound from '../../assets/_Sfx/__Compress/Magic.mp3'
import ButtonLeft from "../../v2/components/Buttons/ButtonLeft";
import ButtonRight from "../../v2/components/Buttons/ButtonRight";
import ButtonMenu from "../../v2/components/Buttons/ButtonMenu";
import Header from "../../v2/components/Header/Header";
import ReactPlayer from "react-player";
import Logo from "../../v2/components/Logo/Logo";

const Strip2 = ({
  isActive,
  language,
  images,
  triggers,
    animations,
  chapter,
  goToPrevSlide,
  goToNextSlide,
  scrollHandler,
  title,
}) => {
  const [isSwitched, setIsSwitched] = useState(false)
  const [isShownModal, setIsShownModal] = useState(false)
  const buttonLeftRef = useRef()
  const buttonRightRef = useRef()
  const cloudsRef = useRef()
  const parallax1 = useRef()
  const parallax2 = useRef()
  const modalRef = useRef()

  const [isLoaded, setIsLoaded] = useState(false)
  const [playVoiceover, { stop }] = useSound(stripesSound, {
    interrupt: true,
    onload: () => setIsLoaded(true),
    volume: 0.1,
  })

  const [playMagic, { stop: stopMagic }] = useSound(magicSound, {
    interrupt: true,
    volume: 0.1,
  })

  const onPlay = () => {
    playVoiceover()
  }

  const onStop = () => {
    stop()
    stopMagic()

    modalRef.current?.onStop()
  }

  useEffect(() => {
    if (isLoaded) {
      if (isActive) {
        onPlay()

        setIsShownModal(false)

        if (isSwitched) {
          playMagic()
        }
      } else {
        onStop()
        stopMagic()
      }
    }

    return () => onStop()
  }, [isLoaded, isActive])

  useEffect(() => {
    isSwitched ? playMagic() : stopMagic()
  }, [isSwitched])

  const onButtonLeftClick = () => {
      return goToPrevSlide()
  }

  const onButtonRightClick = () => {
      return goToNextSlide()
  }

  return (
    <SliderWrapper flexCenter>
        <Logo
            color={'#112232'}
            sx={{
                zIndex: '20',
            }}
        />

      <ButtonMenu
          variant={1}
        sx={{
          zIndex: '20',
        }}
      />

      <ButtonLeft
          variant={1}
        buttonRef={buttonLeftRef}
        isShown
        sx={{
          zIndex: '20',
        }}
        onClick={onButtonLeftClick}
      />

      <ButtonRight
          variant={1}
        buttonRef={buttonRightRef}
        isShown
        sx={{
          zIndex: '20',
        }}
        onClick={onButtonRightClick}
      />

      <Header
          text={title[language]}
          sx={{
              minWidth: 'unset',
              opacity: !Boolean(isShownModal) ? '1' : '0',
              pointerEvents: Boolean(isShownModal) ? 'none' : 'all',
              transition: 'opacity 500ms ease 0s',
          }}
      />

      <StripSwitch
        chapter={chapter}
        isSwitched={isSwitched}
        setIsSwitched={setIsSwitched}
      />

      <ModalWrapper isShownModal={isShownModal}>
          <Box
              sx={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  width: '100%',
                  height: '100%',
                  background: '#112232',
                  opacity: '0.9',
              }}
          />

        {Boolean(isShownModal) && (
          <Modal
            key={isShownModal.id}
            modalRef={modalRef}
            language={language}
            chapter={chapter}
            isShownModal={isShownModal}
            setIsShownModal={setIsShownModal}
            text={title[language]}
          />
        )}
      </ModalWrapper>

      <Box
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'auto',
          scrollbarWidth: 'none',
          position: 'relative',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
          <StripImages
              isSwitched={isSwitched}
              images={images}
              cloudsRef={cloudsRef}
              parallax1={parallax1}
              parallax2={parallax2}
          />

          <StripTriggers
              chapter={chapter}
              triggers={triggers}
              setIsShownModal={setIsShownModal}
          />
      </Box>
    </SliderWrapper>
  )
}

export default Strip2
