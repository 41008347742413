import React from 'react'
import Video from "../components/Video/Video";
import video1SK from "../v2/assets/PROTOTYP/K10/K10_01_1.mp4";
import video1EN from "../v2/assets/PROTOTYP/K10/K10_ ENG gg.mp4";
import ChapterTitle from "../v2/components/ChapterTitle/ChapterTitle";
import backgroundk02 from "../v2/assets/PROTOTYP/K10/K10-0.png";

const chapter10 = {
  url: '/chapter-10',
  name: 'chapter10',
  slides: [
    {
      Component: ChapterTitle,
      props: {
        title: {
          sk: <>ZELENÉ STRECHY <br/> A ZELENÉ FASÁDY</>,
          en: <>Green roofs <br/> and green facades</>,
        },
        background: backgroundk02,
      },
    },
    {
      Component: Video,
      props: {
        video: {
          sk: video1SK,
          en: video1EN,
        },
      },
    },
  ],
}

export default chapter10
