import k05_1 from './VOICEOVER/VOICEOVER_EN/K05_triger 1.mp3'
import k05_2 from './VOICEOVER/VOICEOVER_EN/K05_triger 2.mp3'
import k05_3 from './VOICEOVER/VOICEOVER_EN/K05_triger 3.mp3'
import k05_4 from './VOICEOVER/VOICEOVER_EN/K05_triger 4.mp3'
import k05_5 from './VOICEOVER/VOICEOVER_SK/K05_triger 1.mp3'
import k05_6 from './VOICEOVER/VOICEOVER_SK/K05_triger 2.mp3'
import k05_7 from './VOICEOVER/VOICEOVER_SK/K05_triger 3.mp3'
import k05_8 from './VOICEOVER/VOICEOVER_SK/K05_triger 4.mp3'
import k05_9 from './PROTOTYP/K05/K05-TRIGGER-1.jpg'
import k05_10 from './PROTOTYP/K05/K05-TRIGGER-2.jpg'
import k05_11 from './PROTOTYP/K05/K05-TRIGGER-3-1.jpg'
import k05_12 from './PROTOTYP/K05/K05-TRIGGER-3-2.jpg'
import k05_13 from './PROTOTYP/K05/K05-TRIGGER-3-3.jpg'
import k05_14 from './PROTOTYP/K05/K05-TRIGGER-4-1.jpg'
import k05_15 from './PROTOTYP/K05/K05-TRIGGER-4-2.jpg'
import k05_16 from './PROTOTYP/K05/K05-TRIGGER-4-3.jpg'

import k08_1 from './VOICEOVER/VOICEOVER_EN/K08_triger 1.mp3'
import k08_2 from './VOICEOVER/VOICEOVER_EN/K08_triger 2.mp3'
import k08_3 from './VOICEOVER/VOICEOVER_EN/K08_triger 3.mp3'
import k08_4 from './VOICEOVER/VOICEOVER_EN/K08_triger 4.mp3'
import k08_5 from './VOICEOVER/VOICEOVER_EN/K08_triger 5.mp3'
import k08_6 from './VOICEOVER/VOICEOVER_EN/K08_triger 6.mp3'
import k08_7 from './VOICEOVER/VOICEOVER_SK/K08_triger 1.mp3'
import k08_8 from './VOICEOVER/VOICEOVER_SK/K08_triger 2.mp3'
import k08_9 from './VOICEOVER/VOICEOVER_SK/K08_triger 3.mp3'
import k08_10 from './VOICEOVER/VOICEOVER_SK/K08_Triger 4.mp3'
import k08_11 from './VOICEOVER/VOICEOVER_SK/K08_Triger 5.mp3'
import k08_12 from './VOICEOVER/VOICEOVER_SK/K08_Triger 6.mp3'
import k08_13 from './PROTOTYP/K08/K08-TRIGGER-1-1.jpg'
import k08_14 from './PROTOTYP/K08/K08-TRIGGER-1-2.jpg'
import k08_15 from './PROTOTYP/K08/K08-TRIGGER-1-3.jpg'
import k08_16 from './PROTOTYP/K08/K08-TRIGGER-2-1.jpg'
import k08_17 from './PROTOTYP/K08/K08-TRIGGER-2-2.jpg'
import k08_18 from './PROTOTYP/K08/K08-TRIGGER-2-3.jpg'
import k08_19 from './PROTOTYP/K08/K08-TRIGGER-3-1.jpg'
import k08_20 from './PROTOTYP/K08/K08-TRIGGER-3-2.jpg'
import k08_21 from './PROTOTYP/K08/K08-TRIGGER-3-3.jpg'
import k08_22 from './PROTOTYP/K08/K08-TRIGGER-4-1.jpg'
import k08_23 from './PROTOTYP/K08/K08-TRIGGER-5-1.jpg'
import k08_24 from './PROTOTYP/K08/K08-TRIGGER-5-2.jpg'
import k08_25 from './PROTOTYP/K08/K08-TRIGGER-6-1.jpg'
import k08_26 from './PROTOTYP/K08/K08-TRIGGER-6-2.jpg'
import k08_27 from './PROTOTYP/K08/K08-TRIGGER-6-3.jpg'

import k11_1 from './VOICEOVER/VOICEOVER_EN/K11_triger 1.mp3'
import k11_2 from './VOICEOVER/VOICEOVER_EN/K11_triger 1.mp3'
import k11_3 from './VOICEOVER/VOICEOVER_EN/K11_triger 1.mp3'
import k11_4 from './VOICEOVER/VOICEOVER_EN/K11_triger 1.mp3'
import k11_5 from './VOICEOVER/VOICEOVER_SK/K11_Triger 1 Fotovoltika.mp3'
import k11_6 from './VOICEOVER/VOICEOVER_SK/K11_Triger 2 Technológie.mp3'
import k11_7 from './VOICEOVER/VOICEOVER_SK/K11_Triger 3.mp3'
import k11_8 from './VOICEOVER/VOICEOVER_SK/K11_Triger 4.mp3'
import k11_9 from './PROTOTYP/K11/K11-TRIGGER-1-1.jpg'
import k11_10 from './PROTOTYP/K11/K11-TRIGGER-1-2.jpg'
import k11_11 from './PROTOTYP/K11/K11-TRIGGER-1-3.jpg'
import k11_12 from './PROTOTYP/K11/K11-TRIGGER-2-1.jpg'
import k11_13 from './PROTOTYP/K11/K11-TRIGGER-2-2.jpg'
import k11_14 from './PROTOTYP/K11/K11-TRIGGER-2-3.jpg'
import k11_15 from './PROTOTYP/K11/K11-TRIGGER-3-1.jpg'
import k11_16 from './PROTOTYP/K11/K11-TRIGGER-3-2.jpg'
import k11_17 from './PROTOTYP/K11/K11-TRIGGER-3-3.jpg'
import k11_18 from './PROTOTYP/K11/K11-TRIGGER-4-1.jpg'
import k11_19 from './PROTOTYP/K11/K11-TRIGGER-4-1.jpg'
import k11_20 from './PROTOTYP/K11/K11-TRIGGER-4-1.jpg'

import k14_1 from './VOICEOVER/VOICEOVER_EN/K14_2_triger_1.mp3'
import k14_2 from './VOICEOVER/VOICEOVER_EN/K14_2_triger_2.mp3'
import k14_3 from './VOICEOVER/VOICEOVER_EN/K14_2_triger_3.mp3'
import k14_4 from './VOICEOVER/VOICEOVER_EN/K14_3A_triger_1.mp3'
import k14_5 from './VOICEOVER/VOICEOVER_EN/K14_3A_triger_2.mp3'
import k14_6 from './VOICEOVER/VOICEOVER_EN/K14_3A_triger_3.mp3'
import k14_7 from './VOICEOVER/VOICEOVER_EN/K14_3B_triger_1.mp3'
import k14_8 from './VOICEOVER/VOICEOVER_EN/K14_3B_triger_2.mp3'
import k14_9 from './VOICEOVER/VOICEOVER_EN/K14_3B_triger_3.mp3'
import k14_10 from './VOICEOVER/VOICEOVER_EN/K14_4_triger_1.mp3'
import k14_11 from './VOICEOVER/VOICEOVER_EN/K14_4_triger_2.mp3'
import k14_12 from './VOICEOVER/VOICEOVER_EN/K14_4_triger_3.mp3'
import k14_13 from './VOICEOVER/VOICEOVER_EN/K14_5_triger_1.mp3'
import k14_14 from './VOICEOVER/VOICEOVER_EN/K14_5_triger_2.mp3'
import k14_15 from './VOICEOVER/VOICEOVER_EN/K14_5_triger_3.mp3'
import k14_16 from './VOICEOVER/VOICEOVER_EN/K14_6_triger_1.mp3'
import k14_17 from './VOICEOVER/VOICEOVER_EN/K14_6_triger_2.mp3'
import k14_18 from './VOICEOVER/VOICEOVER_EN/K14_6_triger_3.mp3'
import k14_19 from './VOICEOVER/VOICEOVER_EN/K14_7_triger_1.mp3'
import k14_20 from './VOICEOVER/VOICEOVER_EN/K14_7_triger_2.mp3'
import k14_21 from './VOICEOVER/VOICEOVER_EN/K14_7_triger_3.mp3'
import k14_22 from './VOICEOVER/VOICEOVER_EN/K14_7_triger_4.mp3'

import k14_23 from './VOICEOVER/VOICEOVER_SK/K14_2_triger 1.mp3'
import k14_24 from './VOICEOVER/VOICEOVER_SK/K14_2_triger 2.mp3'
import k14_25 from './VOICEOVER/VOICEOVER_SK/K14_2_triger 3.mp3'
import k14_26 from './VOICEOVER/VOICEOVER_SK/K14_3A_triger 1.mp3'
import k14_27 from './VOICEOVER/VOICEOVER_SK/K14_3A_triger 2.mp3'
import k14_28 from './VOICEOVER/VOICEOVER_SK/K14_3A_trigger 3.mp3'
import k14_29 from './VOICEOVER/VOICEOVER_SK/K14_3B_trigger 1.mp3'
import k14_30 from './VOICEOVER/VOICEOVER_SK/K14_3B_trigger 2.mp3'
import k14_31 from './VOICEOVER/VOICEOVER_SK/K14_3B_trigger 3.mp3'
import k14_32 from './VOICEOVER/VOICEOVER_SK/K14_4_triger1.mp3'
import k14_33 from './VOICEOVER/VOICEOVER_SK/K14_4_triger2.mp3'
import k14_34 from './VOICEOVER/VOICEOVER_SK/K14_4_triger3.mp3'
import k14_35 from './VOICEOVER/VOICEOVER_SK/K14_5_triger 1.mp3'
import k14_36 from './VOICEOVER/VOICEOVER_SK/K14_5_triger 2.mp3'
import k14_37 from './VOICEOVER/VOICEOVER_SK/K14_5_triger 3.mp3'
import k14_38 from './VOICEOVER/VOICEOVER_SK/K14_6_triger 1.mp3'
import k14_39 from './VOICEOVER/VOICEOVER_SK/K14_6_triger 2.mp3'
import k14_40 from './VOICEOVER/VOICEOVER_SK/K14_6_triger 3.mp3'
import k14_41 from './VOICEOVER/VOICEOVER_SK/K14_7_triger 1.mp3'
import k14_42 from './VOICEOVER/VOICEOVER_SK/K14_7_triger 2.mp3'
import k14_43 from './VOICEOVER/VOICEOVER_SK/K14_7_triger 3.mp3'
import k14_44 from './VOICEOVER/VOICEOVER_SK/K14_7_triger 4.mp3'

import k14_45 from './PROTOTYP/K14/K14-2/K14-2-TRIGGER 1.jpg'
import k14_46 from './PROTOTYP/K14/K14-2/K14-2-TRIGGER 2.jpg'
import k14_47 from './PROTOTYP/K14/K14-2/K14-2-TRIGGER 3.jpg'

import k14_48 from './PROTOTYP/K14/K14-3A/K14-3A-TRIGGER 1.jpg'
import k14_49 from './PROTOTYP/K14/K14-3A/K14-3A-TRIGGER 2.jpg'
import k14_50 from './PROTOTYP/K14/K14-3A/K14-3A-TRIGGER 3.jpg'

import k14_51 from './PROTOTYP/K14/K14-3B/K14-3B-TRIGGER 1.jpg'
import k14_52 from './PROTOTYP/K14/K14-3B/K14-3B-TRIGGER 2.jpg'
import k14_53 from './PROTOTYP/K14/K14-3B/K14-3B-TRIGGER 3.jpg'

import k14_54 from './PROTOTYP/K14/K14-4/K14-4-TRIGGER 1.jpg'
import k14_55 from './PROTOTYP/K14/K14-4/K14-4-TRIGGER 2.jpg'
import k14_56 from './PROTOTYP/K14/K14-4/K14-4-TRIGGER 3.jpg'

import k14_57 from './PROTOTYP/K14/K14-5/K14-5-TRIGGER 1.jpg'
import k14_58 from './PROTOTYP/K14/K14-5/K14-5-TRIGGER 2.jpg'
import k14_59 from './PROTOTYP/K14/K14-5/K14-5-TRIGGER 3.jpg'

import k14_60 from './PROTOTYP/K14/K14-6/K14-6-TRIGGER 1.jpg'
import k14_61 from './PROTOTYP/K14/K14-6/K14-6-TRIGGER 2.jpg'
import k14_62 from './PROTOTYP/K14/K14-6/K14-6-TRIGGER 3.jpg'

import k14_63 from './PROTOTYP/K14/K14-7/K14-7-TRIGGER 1.jpg'
import k14_64 from './PROTOTYP/K14/K14-7/K14-7-TRIGGER 2.jpg'
import k14_65 from './PROTOTYP/K14/K14-7/K14-7-TRIGGER 3.jpg'
import k14_66 from './PROTOTYP/K14/K14-7/K14-7-TRIGGER 4.jpg'

const chapter5 = [k05_1,k05_2,k05_3,k05_4,k05_5,k05_6,k05_7,k05_8,k05_9,k05_10,k05_11,k05_12,k05_13,k05_14,k05_15,k05_16,]
const chapter8 = [k08_1,k08_2,k08_3,k08_4,k08_5,k08_6,k08_7,k08_8,k08_9,k08_10,k08_11,k08_12,k08_13,k08_14,k08_15,k08_16,k08_17,k08_18,k08_19,k08_20,k08_21,k08_22,k08_23,k08_24,k08_25,k08_26,k08_27,]
const chapter11 = [k11_1,k11_2,k11_3,k11_4,k11_5,k11_6,k11_7,k11_8,k11_9,k11_10,k11_11,k11_12,k11_13,k11_14,k11_15,k11_16,k11_17,k11_18,k11_19,k11_20,]
const chapter14 = [
    k14_1,k14_2,k14_3,k14_4,k14_5,k14_6,k14_7,k14_8,k14_9,k14_10,
    k14_11,k14_12,k14_13,k14_14,k14_15,k14_16,k14_17,k14_18,k14_19,k14_20,
    k14_21,k14_22,k14_23,k14_24,k14_25,k14_26,k14_27,k14_28,k14_29,k14_30,
    k14_31,k14_32,k14_33,k14_34,k14_35,k14_36,k14_37,k14_38,k14_39,k14_40,
    k14_41,k14_42,k14_43,k14_44,k14_45,k14_46,k14_47,k14_48,k14_49,k14_50,
    k14_51,k14_52,k14_53,k14_54,k14_55,k14_56,k14_57,k14_58,k14_59,k14_60,
    k14_61,k14_62,k14_63,k14_64,k14_65,k14_66,
]

export default {
  chapter5,
  chapter8,
  chapter11,
  chapter14,
}
