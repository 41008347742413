import React from 'react'
import Instructions from '../components/Instructions/Instructions'
import ChapterStart from "../v2/components/ChapterStart/ChapterStart";
import ChapterTitle from "../v2/components/ChapterTitle/ChapterTitle";
import backgroundk02 from "../v2/assets/PROTOTYP/K05/K05-0.png";
import Video from "../components/Video/Video";
import video1SK from "../v2/assets/PROTOTYP/K05/K05_01_1 oprava.mp4";
import video1EN from "../v2/assets/PROTOTYP/K05/K05_ENG gg.mp4";
import {strip1Images, strip1Triggers} from "../strips/strip1";
import Strip from "../components/Strip/Strip";
import animation1 from '../v2/assets/PROTOTYP/K05/K05-PAS-anim1.webm'
import animation2 from '../v2/assets/PROTOTYP/K05/K05-PAS-anim2.webm'
import animation3 from '../v2/assets/PROTOTYP/K05/K05-PAS-anim3.webm'
import animation1Safari from '../v2/assets/PROTOTYP/K05/K05-PAS-anim1.mov'
import animation2Safari from '../v2/assets/PROTOTYP/K05/K05-PAS-anim2.mov'
import animation3Safari from '../v2/assets/PROTOTYP/K05/K05-PAS-anim3.mov'
import bg from '../v2/assets/PROTOTYP/K05/K05-PAS_RESIZED.png'
import { isSafari } from 'react-device-detect'

const title = {
  sk: (
    <>
      ČO JE ZELENÁ ARCHITEKTÚRA <br/> A ZELENÉ STAVEBNÍCTVO
    </>
  ),
  en: (
    <>
      What is green architecture <br/> and green building sector
    </>
  ),
}

const chapter5 = {
  url: '/chapter-5',
  name: 'chapter5',
  slides: [
    {
      Component: ChapterStart,
      props: {
        title
      },
    },
    {
      Component: ChapterTitle,
      props: {
        title: {
          sk: <>ZELENé BUDOVY</>,
          en: <>Green buildings</>,
        },
        background: backgroundk02,
      },
    },
    {
      Component: Video,
      props: {
        video: {
          sk: video1SK,
          en: video1EN,
        },
      },
    },
    {
      Component: Instructions,
      props: {
        background: bg,
      },
    },
    {
      Component: Strip,
      props: {
        images: strip1Images,
        triggers: strip1Triggers,
        animations: [
          {
            animation: isSafari ? animation1Safari : animation1,
            sx: {},
          },
          {
            animation: isSafari ? animation2Safari : animation2,
            sx: {
              left: '26.224694%',
            },
          },
          {
            animation: isSafari ? animation3Safari : animation3,
            sx: {
              left: '52.449389584%',
            },
          },
        ],
        scrollHandler: (percentage, language) => {
          return {
            sk: 'ZELENÉ BUDOVY',
            en: 'Green buildings',
          }[language]
        },
      },
    },
  ],
}

export default chapter5
