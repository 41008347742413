import React from 'react'
import ChapterTitle from "../v2/components/ChapterTitle/ChapterTitle";
import Animated2 from "../v2/components/Animated/Animated2";
import backgroundk02 from "../v2/assets/PROTOTYP/K04/K04-0.png";
import animation1 from "../v2/assets/PROTOTYP/K04/K04-1/K04-1.mp4";
import animation2 from "../v2/assets/PROTOTYP/K04/K04-2/K04-2.mp4";
import animation3 from "../v2/assets/PROTOTYP/K04/K04-3/K04-3.mp4";
import voiceoverK02_1SK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K04_1.mp3";
import voiceoverK02_1EN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K04_1.mp3";
import voiceoverK02_2SK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K04_2.mp3";
import voiceoverK02_2EN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K04_2.mp3";
import voiceoverK02_3SK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K04_3.mp3";
import voiceoverK02_3EN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K04_3.mp3";
import Typography from "@mui/material/Typography";
import fonts from "../styles/fonts";
import bg from "../v2/assets/PROTOTYP/K04/K04-DOPLNKOVESTUDIUM.jpg";

const title1 = {
  sk: 'AKO ZMIERNIŤ TEMPO KLIMATICKEJ ZMENY?',
  en: 'How to mitigate the pace of climate change?',
}

const textImage1 = {
  sk: (
      <>
        Najdôležitejšie je znížiť podiel tvorby <b>skleníkových plynov,</b> unikajúcich do atmosféry.
      </>
  ),
  en: (
      <>
        The most important thing is to reduce the proportion of <b>greenhouse gases</b> released into the atmosphere.
      </>
  )
}

const textImage2 = {
  sk: (
      <>
        Výstavba a prevádzka budov v súčasnosti dosahuje až <b>39 percentný podiel z celkovej produkcie emisií CO2.</b>
        <br/><br/>
        V záujme boja proti klimatickým zmenám je teda nevyhnutné v stavebníctve i v súvisiacich odvetviach <b>zefektívniť prevádzku budov</b> a <b>využívať</b> bezemisné čiže <b>obnoviteľné zdroje energií.</b>
      </>
  ),
  en: (
      <>
        The construction and operation of buildings currently accounts for up to <b>39 per cent of total CO2 emissions.</b>
        <br/><br/>
        In order to combat climate change, it is therefore necessary to make the <b>operation of buildings more efficient</b> in the construction and related sectors and to <b>use emission-free or renewable energy sources.</b>
      </>
  )
}

const textImage3 = {
  sk: (
      <>
        A práve <b>zavádzanie bezemisných zdrojov energií</b> vo výstavbe a prevádzke budov, ale aj v doprave, v poľnohospodárstve, atď, sa nazýva <b>dekorbonizácia.</b>
      </>
  ),
  en: (
      <>
        It is the <b>introduction of emission-free energy sources</b> in the construction and operation of buildings, but also in transport, agriculture, etc., that is called <b>decarbonisation.</b>
      </>
  )
}

const chapter4 = {
  url: '/chapter-4',
  name: 'chapter4',
  slides: [
    {
      Component: ChapterTitle,
      props: {
        title: {
          sk: <>AKO ZMIERNIŤ TEMPO <br/> KLIMATICKEJ ZMENY?</>,
          en: <>How to mitigate the pace <br/> of climate change?</>,
        },
        background: backgroundk02,
      },
    },
    {
      Component: Animated2,
      props: {
        animation: animation1,
        title: title1,
        voiceover: {
          sk: voiceoverK02_1SK,
          en: voiceoverK02_1EN,
        },
        text: textImage1,
          additionalStudy: [
              {
                  img: bg,
                  text: {
                      sk: (
                          <>
                              <b>Najväčší podiel na tvorbe skleníkových plynov má síce priemysel, priemyselné poľnohospodárstvo, doprava a tvorba odpadu, ale významne sa na tvorbe skleníkových plynov podielajú aj budovy – ich výstavba aj prevádzka.</b>
                              <br/><br/>
                              Preto sa už niekoľko rokov <b>sprísňujú aj podmienky a požiadavky na výstavbu a prevádzku budov</b> a to s cieľom, aby sa do ovzdušia vypustilo len toľko emisií skleníkových plynov, koľko bude schopná príroda spotrebovať, aby sa dosiahla <b>klimatická neutralita.</b> Na tomto sa dohodli mnohé krajiny sveta - vrátane Slovenska - v tzv. <b>Parížskej dohode,</b> ktorej vznik iniciovala OSN – Organizácia spojených národov.
                          </>
                      ),
                      en: (
                          <>
                              <b>Industry, industrial agriculture, transport and waste generation have the largest share in the production of greenhouse gases, but buildings also contribute significantly to the production of greenhouse gases - their construction and operation.</b>
                              <br/><br/>
                              Therefore, the conditions and requirements for the construction and operation of buildings have been tightened for several years, with the aim of releasing only as many greenhouse gas emissions into the air as nature will be able to consume in order to achieve climate neutrality. Many countries of the world - including Slovakia - agreed on this in the so-called The Paris Agreement, the creation of which was initiated by the United Nations.
                          </>
                      ),
                  },
              },
          ],
      },
    },
    {
      Component: Animated2,
      props: {
        animation: animation2,
        title: title1,
        voiceover: {
          sk: voiceoverK02_2SK,
          en: voiceoverK02_2EN,
        },
        extra: {
          sk: (
              <>
                <Typography
                    sx={{
                      position: 'absolute',
                      textAlign: 'center',
                      top: '50%',
                      left: '50%',
                      translate: '-203% -90%',
                      ...fonts.Rubik['26px']({
                        fontWeight: '700',
                      }),
                    }}
                >
                  39 percentný podiel <br/>
                  z celkovej produkcie <br/>
                  emisií CO2
                </Typography>

                <Typography
                    sx={{
                      position: 'absolute',
                      textAlign: 'center',
                      top: '50%',
                      left: '50%',
                      translate: '118% -90%',
                      ...fonts.Rubik['26px']({
                        fontWeight: '700',
                      }),
                    }}
                >
                  Efektívna prevádzka <br/>
                  a obnoviteľné zdroje <br/>
                  energií
                </Typography>
              </>
          ),
          en: (
              <>
                  <Typography
                      sx={{
                          position: 'absolute',
                          textAlign: 'center',
                          top: '50%',
                          left: '50%',
                          translate: '-260% -90%',
                          ...fonts.Rubik['26px']({
                              fontWeight: '700',
                          }),
                      }}
                  >
                      39 percent <br/> of total <br/> CO2 emissions
                  </Typography>

                  <Typography
                      sx={{
                          position: 'absolute',
                          textAlign: 'center',
                          top: '50%',
                          left: '50%',
                          translate: '130% -90%',
                          ...fonts.Rubik['26px']({
                              fontWeight: '700',
                          }),
                      }}
                  >
                      Efficient operation <br/> and renewable <br/> energy sources
                  </Typography>
              </>
          ),
        },
        text: textImage2,
      },
    },
    {
      Component: Animated2,
      props: {
        animation: animation3,
        title: title1,
        voiceover: {
          sk: voiceoverK02_3SK,
          en: voiceoverK02_3EN,
        },
        text: textImage3,
      },
    },
  ],
}

export default chapter4
