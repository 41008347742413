import React, { useEffect, useRef } from 'react'
import Box from '@mui/material/Box'
import useSound from 'use-sound'
import hoverSound from '../../../assets/sounds/1_Button_Hover_Sound.wav'
import clickSound from '../../../assets/sounds/2_Ikon_Click.wav'

const ButtonPlay = ({ variant = 0, sx, onClick }) => {
  const [playHover] = useSound(hoverSound, {
    interrupt: true,
  })
  const [playClick] = useSound(clickSound, {
    interrupt: true,
  })

  const isSoundActive = useRef(false)

  useEffect(() => {
    isSoundActive.current = false

    setTimeout(() => {
      isSoundActive.current = true
    }, 200)
  })

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '3%',
        right: '1.5%',
        cursor: 'pointer',
        zIndex: '1',
          height: '7.4vh',
          aspectRatio: '1/1',
        ...sx,
      }}
      onMouseEnter={() => {
        isSoundActive.current && playHover()
      }}
      onClick={(e) => {
        playClick()
        onClick(e)
      }}
    >
        {variant === 0 ? (
            <Box
                component={'svg'}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 70 70"
                fill="none"
                sx={{
                    '& circle': {
                        transition: '0.3s ease-out',
                    },
                    '& path': {
                        transition: '0.3s ease-out',
                    },
                    '&:hover': {
                        '& circle': {
                            fill: '#3AD3A4',
                        },
                        '& path': {
                            fill: '#112232',
                        },
                    },
                }}
            >
                <circle cx="35" cy="35" r="35" fill="#112232"/>

                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M35.8 21.8041C35.8 21.2571 35.4809 20.7603 34.9834 20.5329C34.4859 20.3054 33.9015 20.389 33.4877 20.7469L24.2899 28.7023H15.7328C14.9608 28.7023 14.335 29.3282 14.335 30.1002V40.776C14.335 41.548 14.9608 42.1739 15.7328 42.1739H24.1523L33.4877 50.2489C33.9014 50.6068 34.4859 50.6905 34.9834 50.463C35.4809 50.2355 35.8 49.7388 35.8 49.1917V21.8041ZM25.725 31.1575L33.0043 24.8614V46.1343L25.5875 39.7188C25.3335 39.499 25.0089 39.3781 24.673 39.3781H17.1307V31.4981H24.8106C25.1464 31.4981 25.471 31.3772 25.725 31.1575ZM47.9111 21.0576C47.4276 20.4557 46.5477 20.3598 45.9459 20.8433C45.344 21.3268 45.2481 22.2067 45.7316 22.8085C48.627 26.4127 50.2052 30.8972 50.2052 35.5203C50.2052 40.1434 48.627 44.628 45.7316 48.2321C45.2481 48.834 45.344 49.7139 45.9459 50.1974C46.5477 50.6809 47.4276 50.5849 47.9111 49.9831C51.2053 45.8825 53.0009 40.7802 53.0009 35.5203C53.0009 30.2604 51.2053 25.1582 47.9111 21.0576ZM44.3121 24.7381C43.854 24.1167 42.9789 23.9842 42.3575 24.4423C41.736 24.9004 41.6036 25.7755 42.0617 26.3969C44.0098 29.0399 45.0607 32.237 45.0607 35.5203C45.0607 38.8037 44.0098 42.0008 42.0617 44.6437C41.6036 45.2652 41.736 46.1403 42.3575 46.5984C42.9789 47.0564 43.854 46.924 44.3121 46.3025C46.6144 43.1791 47.8565 39.4006 47.8565 35.5203C47.8565 31.64 46.6144 27.8616 44.3121 24.7381ZM40.3856 28.7704C39.9619 28.125 39.0953 27.9452 38.4499 28.3688C37.8045 28.7925 37.6247 29.6591 38.0483 30.3045C39.0653 31.854 39.6071 33.6669 39.6071 35.5203C39.6071 37.3737 39.0653 39.1867 38.0483 40.7362C37.6247 41.3816 37.8045 42.2482 38.4499 42.6718C39.0953 43.0955 39.9619 42.9157 40.3856 42.2702C41.7017 40.2651 42.4029 37.9189 42.4029 35.5203C42.4029 33.1218 41.7017 30.7756 40.3856 28.7704Z"
                    fill="#3AD3A4"
                />
            </Box>
        ) : (
            <Box
                component={'svg'}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 70 70"
                fill="none"
                sx={{
                    '& circle': {
                        transition: '0.3s ease-out',
                    },
                    '&:hover': {
                        '& circle': {
                            fill: '#fff',
                        },
                    },
                }}
            >
                <circle cx="35" cy="35" r="35" fill="#3AD3A4"/>
                <path d="M26 21.5981L50 35.4545L26 49.3109L26 21.5981Z" stroke="#112232" strokeWidth="3"/>
            </Box>
        )}
    </Box>
  )
}

export default ButtonPlay
