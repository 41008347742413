import React from 'react'
import Box from '@mui/material/Box'
import partnersSK from '../../v2/assets/PROTOTYP/K00/K00-1.jpg'
import partnersEN from '../../v2/assets/PROTOTYP/K00/K00-1-EN.jpg'
import { useNavigate } from 'react-router-dom'
import ButtonLeft from "../../v2/components/Buttons/ButtonLeft";
import ButtonRight from "../../v2/components/Buttons/ButtonRight";
import SliderWrapper from "../../components/Slider/SliderWrapper";
import img1 from '../assets/sutay pic small.jpg'
import Typography from "@mui/material/Typography";
import fonts from "../../styles/fonts";

const Competition = ({ isEnding, language, goToPrevSlide, goToNextSlide }) => {
    const navigate = useNavigate()

    const partners = {
        sk: partnersSK,
        en: partnersEN,
    }[language]

    return (
        <SliderWrapper
            flexCenter
            sx={{
                background: '#E8AEBA',
            }}
        >
            <ButtonLeft
                isShown
                variant={1}
                onClick={goToPrevSlide}
            />

            <ButtonRight
                isShown
                variant={1}
                onClick={() => navigate('/')}
            />

            <Box
                sx={{
                    display: 'flex',
                    maxWidth: '60%',
                }}
            >
                <Box
                    sx={{
                        flexBasis: '50%',
                    }}
                >
                    <Box
                        component={'img'}
                        src={img1}
                        sx={{
                            borderRadius: '50px 0 0 50px',
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                    />
                </Box>

                <Box
                    sx={{
                        flexBasis: '50%',
                        padding: '40px',
                        background: '#142232',
                        borderRadius: '0 50px 50px 0',
                    }}
                >
                    <Typography
                        sx={{
                            ...fonts.Rubik['20px']({
                                color: '#3AD3A4'
                            }),
                            '& b': {
                                color: '#E8AEBA'
                            },
                        }}
                    >
                        Zapojte sa do výtvarnej súťaže na tému <br/> <b>„Zelená mestská štvrť“</b>
                        <br/><br/>
                        Milí žiaci, budeme radi, ak znalosti, ktoré ste v e-kurze získali, využijete a namaľujete výtvarnú prácu na tému <b>„Zelená mestská štvrť“.</b>
                        <br/><br/>
                        Využite svoju kreativitu a namaľujte svoju predstavu zelenej mestskej štvrte na výkres <b>formátu A2.</b>
                        <br/><br/>
                        Zo zadnej strany výkresu napíšte <b>svoje meno, názov školy, váš vek a kontaktnú osobu</b> (meno, e-mail, telefón), ktorej máme dať vedieť v prípade, že Vašu prácu vyberieme na ocenenie a pozveme Vás do Bratislavy.
                        <br/><br/>
                        <b>Už teraz sa tešíme na Vaše práce, ktoré treba doručiť do 15. januára 2024 na adresu: OZ EUROARCH, Na varte 4/C, 83101 Bratislava</b>
                    </Typography>
                </Box>
            </Box>
        </SliderWrapper>
    )
}

export default Competition
