import React from 'react'
import Typography from '@mui/material/Typography'
import fonts from '../../styles/fonts'

const InfoText = () => {
  return (
    <Typography
      sx={{
        textAlign: 'center',
        ...fonts.Rubik['32px']({
            fontWeight: '700',
            color: '#E8AEBA',
        }),
      }}
    >
        Zvoľte si jazyk kurzu.
        <br/>
        Choose the language of the course.
    </Typography>
  )
}

export default InfoText
