import Info from '../components/Info/Info'
import Partners from '../components/Partners/Partners'
import Intro from '../v2/components/Intro/Intro'
import Intro2 from "../v2/components/Intro2/Intro2";
import Info2 from "../components/Info/Info2";

const chapter0 = {
  url: '/chapter-0',
  name: 'chapter0',
  slides: [
    Info,
    Info2,
    Partners,
    Intro,
    Intro2,
  ],
}

export default chapter0
