import React from "react";
import BackgroundWave from "../BackgroundWave/BackgroundWave";
import Logo from "../Logo/Logo";
import PersonAnimation from "../PersonAnimation/PersonAnimation";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import fonts from "../../../styles/fonts";
import Button from "../Buttons/Button";
import SliderWrapper from "../../../components/Slider/SliderWrapper";
import ButtonMenu from "../Buttons/ButtonMenu";

const ChapterTitle = ({ isActive, language, goToPrevSlide, goToNextSlide, title, background }) => {
    return (
        <SliderWrapper flexCenter>
            <BackgroundWave
                disabled
                background={background}
            />

            <PersonAnimation
                isActive={isActive}
                variant={1}
            />

            <ButtonMenu />

            <Logo />

            <Box
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: '1',
                    top: '1%',
                }}
            >
                <Typography
                    sx={{
                        ...fonts.Rubik['70px']({
                            color: '#3AD3A4',
                            fontWeight: '700',
                        }),
                        textAlign: 'center',
                        textTransform: 'uppercase',
                    }}
                >
                    {title[language]}
                </Typography>
            </Box>

            <Button
                sx={{
                    position: 'absolute',
                    bottom: '12%',
                }}
                onClick={goToNextSlide}
            >
                {language === 'sk' ? 'POKRAČUJ' : 'Continue'}
            </Button>
        </SliderWrapper>
    )
}

export default ChapterTitle
