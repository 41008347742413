import React from 'react'
import Video from '../components/Video/Video'
import video1SK from "../v2/assets/PROTOTYP/K03/K03_01.mp4";
import video1EN from "../v2/assets/PROTOTYP/K03/K03_01.mp4";
import ChapterTitle from "../v2/components/ChapterTitle/ChapterTitle";
import backgroundk02 from "../v2/assets/PROTOTYP/K03/K03-0.png";

const chapter3 = {
  url: '/chapter-3',
  name: 'chapter3',
  slides: [
    {
      Component: ChapterTitle,
      props: {
        title: {
          sk: <>Čo je KLIMATICKá kríza a <br/> prečo vznikla</>,
          en: <>What is the climate crisis <br/> and how did it occur?</>,
        },
        background: backgroundk02,
      },
    },
    {
      Component: Video,
      props: {
        video: {
          sk: video1SK,
          en: video1EN,
        },
      },
    },
  ],
}

export default chapter3
