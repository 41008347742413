import React, { useEffect, useState } from 'react'
import SliderWrapper from '../Slider/SliderWrapper'
import base from '../../assets/_Quiz/DaA_Q_ResultBase.png'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import fonts from '../../styles/fonts'
import ButtonSplash from '../Buttons/ButtonSplash'
import result from '../../assets/_Quiz/DaA_Q_ResultBubble-01.png'
import starFull from '../../assets/_Quiz/DaA_Q_Star-01.png'
import starEmpty from '../../assets/_Quiz/DaA_Q_Star-02.png'
import stars3 from '../../assets/_K0/DaA_K0-Splash_Stars-03.png'
import stars1 from '../../assets/_K0/DaA_K0-Splash_Stars-01.png'
import stars2 from '../../assets/_K0/DaA_K0-Splash_Stars-02.png'
import { useNavigate } from 'react-router-dom'
import { useQuizContext } from '../../contexts/QuizContext'
import { wave2 } from '../../styles/animations'
import useSound from 'use-sound'
import resultSound from '../../assets/_Sfx/__Compress/Q_Result.mp3'
import background from "../../v2/assets/PROTOTYP/K00/K00-0.png";
import BackgroundWave from "../../v2/components/BackgroundWave/BackgroundWave";
import ButtonMenu from "../../v2/components/Buttons/ButtonMenu";
import Button from "../../v2/components/Buttons/Button";
import Header from "../../v2/components/Header/Header";

const QuizResult = ({ isActive, language, goToNextSlide }) => {
  const { correctAnswers, setCorrectAnswers } = useQuizContext()

  const [isLoaded, setIsLoaded] = useState(false)
  const [playVoiceover, { stop }] = useSound(resultSound, {
    interrupt: true,
    onload: () => setIsLoaded(true),
  })

  const onPlay = () => {
    playVoiceover()
  }

  const onStop = () => {
    stop()
  }

  useEffect(() => {
    if (isLoaded) {
      if (isActive) {
        onPlay()
      } else {
        onStop()
      }
    }

    return () => onStop()
  }, [isLoaded, isActive])

  const navigate = useNavigate()

  const getText = () => {
    if (correctAnswers === 15) {
      return {
        sk: (
          <>
            Správne odpovede: 15/15 <br /> <b>“Bravó, gratulujeme, máš to na 100%”</b>
          </>
        ),
        en: (
          <>
            Correct answers: 15/15 <br /> <b>“Congratulation! You smashed it.”</b>
          </>
        ),
      }
    }

    if (correctAnswers >= 10) {
      return {
        sk: (
          <>
            Správne odpovede: {correctAnswers}/15 <br />
              <b>“Super, sem tam malá chybička. Skús si zahrať kvíz znovu.”</b>
          </>
        ),
        en: (
          <>
            Correct answers: {correctAnswers}/15 <br />
              <b>“Good effort. A few mistakes, see if you can get full marks…”</b>
          </>
        ),
      }
    }

    return {
      sk: (
        <>
          Správne odpovede: {correctAnswers}/15 <br />
            <b>“Nevadííí, môžeš sa opraviť. Skús si zahrať kvíz znovu.”</b>
        </>
      ),
      en: (
        <>
          Correct answers: {correctAnswers}/15 <br /> <b>“Unlucky. Give it another GO.”</b>
        </>
      ),
    }
  }

  // useEffect(() => {
  //   if (!Number.isInteger(correctAnswers)) {
  //     navigate('/quiz?slide=0')
  //   }
  // }, [isActive])

    const getImg = () => {
      if (correctAnswers === 15) {
          return (
              <svg xmlns="http://www.w3.org/2000/svg" width="144" height="55" viewBox="0 0 144 55" fill="none">
                  <g opacity="0.8">
                      <rect width="143.71" height="55" rx="27.5" fill="#3AD3A4"/>
                      <path d="M35.8928 13.3086L39.1711 23.3982H49.7799L41.1972 29.6339L44.4755 39.7234L35.8928 33.4877L27.3101 39.7234L30.5884 29.6339L22.0057 23.3982H32.6145L35.8928 13.3086Z" fill="#112232"/>
                      <path d="M71.7092 13.3086L74.9875 23.3982H85.5963L77.0136 29.6339L80.2919 39.7234L71.7092 33.4877L63.1265 39.7234L66.4048 29.6339L57.8221 23.3982H68.4309L71.7092 13.3086Z" fill="#112232"/>
                      <path d="M107.25 13.3086L110.529 23.3982H121.137L112.555 29.6339L115.833 39.7234L107.25 33.4877L98.6675 39.7234L101.946 29.6339L93.3631 23.3982H103.972L107.25 13.3086Z" fill="#112232"/>
                  </g>
              </svg>
          )
      }

      if (correctAnswers >= 10) {
          return (
              <svg xmlns="http://www.w3.org/2000/svg" width="144" height="55" viewBox="0 0 144 55" fill="none">
                  <g opacity="0.8">
                      <rect width="143.71" height="55" rx="27.5" fill="#3AD3A4"/>
                      <path d="M35.8928 13.3086L39.1711 23.3982H49.7799L41.1972 29.6339L44.4755 39.7234L35.8928 33.4877L27.3101 39.7234L30.5884 29.6339L22.0057 23.3982H32.6145L35.8928 13.3086Z" fill="#112232"/>
                      <path d="M71.7092 13.3086L74.9875 23.3982H85.5963L77.0136 29.6339L80.2919 39.7234L71.7092 33.4877L63.1265 39.7234L66.4048 29.6339L57.8221 23.3982H68.4309L71.7092 13.3086Z" fill="#112232"/>
                      <path opacity="0.5" d="M107.248 13.3086L110.527 23.3982H121.135L112.553 29.6339L115.831 39.7234L107.248 33.4877L98.6655 39.7234L101.944 29.6339L93.3611 23.3982H103.97L107.248 13.3086Z" fill="#112232"/>
                  </g>
              </svg>
          )
      }

      return (
          <svg xmlns="http://www.w3.org/2000/svg" width="144" height="55" viewBox="0 0 144 55" fill="none">
              <g opacity="0.8">
                  <rect width="143.71" height="55" rx="27.5" fill="#3AD3A4"/>
                  <path d="M35.8928 13.3086L39.1711 23.3982H49.7799L41.1972 29.6339L44.4755 39.7234L35.8928 33.4877L27.3101 39.7234L30.5884 29.6339L22.0057 23.3982H32.6145L35.8928 13.3086Z" fill="#112232"/>
                  <path opacity="0.5" d="M71.7092 13.3086L74.9875 23.3982H85.5963L77.0136 29.6339L80.2919 39.7234L71.7092 33.4877L63.1265 39.7234L66.4048 29.6339L57.8221 23.3982H68.4309L71.7092 13.3086Z" fill="#112232"/>
                  <path opacity="0.5" d="M107.248 13.3086L110.527 23.3982H121.135L112.553 29.6339L115.831 39.7234L107.248 33.4877L98.6655 39.7234L101.944 29.6339L93.3611 23.3982H103.97L107.248 13.3086Z" fill="#112232"/>
              </g>
          </svg>
      )
    }

  return (
    <SliderWrapper>
        <BackgroundWave
            disabled
            background={background}
        />

      <ButtonMenu chapter={'chapter0'} />

        <Box
            sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                translate: '50% -400%',
                zIndex: '1',
            }}
        >
            {getImg()}
        </Box>

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                top: '50%',
                left: '50%',
                translate: '-50% -10%',
            }}
        >
            <Header
                text={language === 'sk' ? 'Výsledok kvízu' : 'Quiz result'}
                sx={{
                    position: 'relative',
                    minWidth: '400px',
                    border: '10px solid #3AD3A4',
                    display: 'inline-block',
                    padding: '19px 60px',
                    top: 'unset',
                    left: 'unset',
                    transform: 'unset',
                    marginBottom: '34px',
                }}
                textSx={{
                    color: '#E8AEBA',
                }}
            />

            <Typography
                sx={{
                    ...fonts.Rubik['32px']({
                        color: '#3AD3A4',
                    }),
                    textAlign: 'center',
                    marginBottom: '4vh',
                }}
            >
                {getText()[language]}
            </Typography>

            <Box
                sx={{
                    gap: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    whiteSpace: 'nowrap',
                }}
            >
                <Button
                    onClick={() => {
                        setCorrectAnswers(undefined)
                        navigate('/quiz?slide=0')
                    }}
                >
                    {language === 'sk' ? 'SKÚSIŤ ZNOVU' : 'TRY AGAIN'}
                </Button>

                <Button
                    onClick={goToNextSlide}
                >
                    {language === 'sk' ? 'POKRAČOVAŤ' : 'CONTINUE'}
                </Button>
            </Box>
        </Box>
    </SliderWrapper>
  )
}

export default QuizResult
