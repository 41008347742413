import React from 'react'
import Box from '@mui/material/Box'
import ButtonVideoPlay from '../Buttons/ButtonVideoPlay'

const VideoOverlay = ({
  videoPreview,
  isPLaying,
  onPlayClick,
  isShownPreview,
  chapter,
}) => {
  return (
    <Box
      sx={{
        transition: 'opacity 500ms ease 0s',
        position: 'absolute',
        top: '0',
        left: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        zIndex: '1',
        cursor: isPLaying ? 'pointer' : 'default',
      }}
      onClick={isPLaying ? onPlayClick : undefined}
    >
        <Box
            sx={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                background: 'linear-gradient(0deg, rgba(58, 211, 164, 0.60) 0%, rgba(17, 34, 50, 0.60) 100%)',
                opacity: !isPLaying ? '0.6' : '0',
                transition: 'opacity 500ms ease 0s',
            }}
        />

        <Box
            sx={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                background: '#112232',
                opacity: !isPLaying ? '0.7' : '0',
                transition: 'opacity 500ms ease 0s',
            }}
        />

      <ButtonVideoPlay
        isShown={!isPLaying}
        onClick={onPlayClick}
        chapter={chapter}
      />
    </Box>
  )
}

export default VideoOverlay
