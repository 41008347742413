import React from 'react'
import { Routes, Route } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import Menu from './components/Menu/Menu'
import Wrapper from './components/Wrapper/Wrapper'
import Chapter from './components/Chapter/Chapter'
import chapter1 from './chapters/chapter1'
import chapter0 from './chapters/chapter0'
import chapter2 from './chapters/chapter2'
import chapter3 from './chapters/chapter3'
import chapter4 from './chapters/chapter4'
import chapter5 from './chapters/chapter5'
import jtre from './chapters/jtre'
import quiz from './chapters/quiz'
import chapter6 from "./chapters/chapter6";
import chapter8 from "./chapters/chapter8";
import chapter10 from "./chapters/chapter10";
import chapter9 from "./chapters/chapter9";
import chapter11 from "./chapters/chapter11";
import chapter12 from "./chapters/chapter12";
import chapter14 from "./chapters/chapter14";
import chapter13 from "./chapters/chapter13";
import chapter7 from "./chapters/chapter7";

const App = () => {
  const chapters = [
    chapter0,
    chapter1,
    chapter2,
    chapter3,
    chapter4,
    chapter5,
      chapter6,
    chapter7,
      chapter8,
      chapter9,
      chapter10,
      chapter11,
      chapter12,
    chapter13,
      chapter14,
    quiz,
  ]

  return (
    <>
      <CssBaseline />

      <Wrapper>
        <Menu />

        <Routes>
          <Route
            key={'/'}
            path={'/'}
            element={
              <Chapter
                key={'/'}
                chapters={chapters}
                index={0}
                {...chapters[0]}
              />
            }
          />

          {chapters.map((chapter, index) => (
            <Route
              key={chapter.url}
              path={chapter.url}
              element={
                <Chapter
                  key={chapter.url}
                  chapters={chapters}
                  index={index}
                  {...chapter}
                />
              }
            />
          ))}

          <Route
            key={'*'}
            path={'*'}
            element={
              <Chapter
                key={'*'}
                chapters={chapters}
                index={0}
                {...chapters[0]}
              />
            }
          />
        </Routes>
      </Wrapper>
    </>
  )
}

export default App
