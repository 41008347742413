import React, { useEffect, useRef } from 'react'
import Box from '@mui/material/Box'
import useSound from 'use-sound'
import hoverSound from '../../../assets/sounds/1_Button_Hover_Sound.wav'
import clickSound from '../../../assets/sounds/2_Ikon_Click.wav'
import {useAppContext} from "../../../contexts/AppContext";

const ButtonMenu = ({ type, variant = 0, sx }) => {
  const { isShownMenu, setIsShownMenu } = useAppContext()

  const [playHover] = useSound(hoverSound, {
    interrupt: true,
  })
  const [playClick] = useSound(clickSound, {
    interrupt: true,
  })

  const buttonOpen = (
      <Box
          component={'svg'}
          viewBox="0 0 70 70"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          sx={{
              '& circle': {
                  transition: '0.3s ease-out',
              },
              '&:hover': {
                  '& circle': {
                      fill: '#fff',
                  },
              },
          }}
      >
          <circle cx="35" cy="35" r="35" fill="#3AD3A4"/>
          <rect x="16.2241" y="24.1094" width="36.776" height="3.11111" fill="#112232"/>
          <rect x="16.2241" y="33.4453" width="36.776" height="3.11111" fill="#112232"/>
          <rect x="16.2241" y="42.7734" width="36.776" height="3.11111" fill="#112232"/>
      </Box>

  )

    const buttonOpen1 = (
        <Box
            component={'svg'}
            viewBox="0 0 70 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            sx={{
                '& circle': {
                    transition: '0.3s ease-out',
                },
                '& rect': {
                    transition: '0.3s ease-out',
                },
                '&:hover': {
                    '& circle': {
                        fill: '#3AD3A4',
                    },
                    '& rect': {
                        fill: '#112232',
                    },
                },
            }}
        >
            <circle cx="35" cy="35" r="35" fill="#112232"/>
            <rect x="16.2241" y="24.1094" width="36.776" height="3.11111" fill="#3AD3A4"/>
            <rect x="16.2241" y="33.4453" width="36.776" height="3.11111" fill="#3AD3A4"/>
            <rect x="16.2241" y="42.7734" width="36.776" height="3.11111" fill="#3AD3A4"/>
        </Box>

    )

  const buttonClose = (
      <Box
          component={'svg'}
          viewBox="0 0 70 70"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          sx={{
              '& circle': {
                  transition: '0.3s ease-out',
              },
              '&:hover': {
                  '& circle': {
                      fill: '#fff',
                  },
              },
          }}
      >
          <circle cx="35" cy="35" r="35" fill="#3AD3A4"/>
          <path d="M33.1482 35.8979L18.8485 50.1976L21.0484 52.3975L35.3481 38.0978L49.6474 52.3971L51.8473 50.1972L37.548 35.8979L52.3968 21.0491L50.1969 18.8492L35.3481 33.698L20.4989 18.8488L18.299 21.0487L33.1482 35.8979Z" fill="#112232"/>
      </Box>
  )

    const buttonClose1 = (
        <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70" fill="none">
            <circle cx="35" cy="35" r="35" fill="#112232"/>
            <path d="M33.1482 35.8979L18.8485 50.1976L21.0484 52.3975L35.3481 38.0978L49.6474 52.3971L51.8473 50.1972L37.548 35.8979L52.3968 21.0491L50.1969 18.8492L35.3481 33.698L20.4989 18.8488L18.299 21.0487L33.1482 35.8979Z" fill="#3AD3A4"/>
        </svg>
    )

  const onClick = () => setIsShownMenu(!isShownMenu)

  const isSoundActive = useRef(false)

  useEffect(() => {
    isSoundActive.current = false

    setTimeout(() => {
      isSoundActive.current = true
    }, 200)
  })

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '3%',
          left: '1.5%',
        cursor: 'pointer',
        zIndex: '1',
          height: '7.4vh',
          aspectRatio: '1/1',
        ...sx,
      }}
      onMouseEnter={() => {
        isSoundActive.current && playHover()
      }}
      onClick={(e) => {
        playClick()
        onClick(e)
      }}
    >
        {type === 'close' ? variant === 0 ? buttonClose : buttonClose1 : variant === 0 ? buttonOpen : buttonOpen1}
    </Box>
  )
}

export default ButtonMenu
