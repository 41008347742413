import React from 'react'
import Box from '@mui/material/Box'
import SliderWrapper from '../../../components/Slider/SliderWrapper'
import Typography from '@mui/material/Typography'
import fonts from '../../../styles/fonts'
import background from "../../assets/PROTOTYP/UI/MENU_bg_paralax.png";
import detiAArchitektura from '../../assets/deti_a_architektura.png'
import detiAArchitekturaEn from '../../assets/detiAArchitekturaEn.png'
import BackgroundWave from "../BackgroundWave/BackgroundWave";
import Button from "../Buttons/Button";
import Logo from "../Logo/Logo";
import PersonAnimation from "../PersonAnimation/PersonAnimation";

const Intro2 = ({ language, goToPrevSlide, goToNextSlide }) => {
    const title = {
        sk: (
            <>
                ZELENá <br/> architektúra, <br/>
                zelené <br/> stavebníctvo
            </>
        ),
        en: (
            <>
                Green  <br/> architecture, <br/>
                green  <br/> construction <br/>
                sector
            </>
        ),
    }[language]

  return (
    <SliderWrapper flexCenter>
        <BackgroundWave
            background={background}
        />

        <PersonAnimation />

        <Logo />

        <Box
            sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                flex: '1',
                gap: '40px',
            }}
        >
             <Box
                 component={'img'}
                 src={language === 'sk' ? detiAArchitektura : detiAArchitekturaEn}
                 sx={{
                     width: '17%',
                 }}
             />

            <Typography
                sx={{
                    ...fonts.Rubik['108px']({
                        color: '#3AD3A4',
                        fontWeight: '700',
                    }),
                    textAlign: 'center',
                    textTransform: 'uppercase',
                }}
            >
                {title}
            </Typography>

            <Button
                onClick={goToNextSlide}
            >
                {language === 'sk' ? 'Spusti kurz' : 'Continue'}
            </Button>
        </Box>
    </SliderWrapper>
  )
}

export default Intro2
