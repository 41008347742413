import React from 'react'
import Box from '@mui/material/Box'
import { pulseAnimation } from '../../styles/animations'
import useSound from 'use-sound'
import hoverSound from '../../assets/sounds/1_Button_Hover_Sound.wav'
import clickSound from '../../assets/sounds/2_Ikon_Click.wav'

const ButtonVideoPlay = ({ isShown, onClick, chapter }) => {
  const [playHover] = useSound(hoverSound, {
    interrupt: true,
  })
  const [playClick] = useSound(clickSound, {
    interrupt: true,
  })

  return (
    <Box
      sx={{
        position: 'absolute',
        cursor: 'pointer',
        borderRadius: '50%',
        opacity: isShown ? '1' : '0',
        pointerEvents: isShown ? 'all' : 'none',
        transition: 'opacity 500ms ease 0s',
        width: '139px',
          height: '139px',
        ...pulseAnimation,
      }}
      onMouseEnter={playHover}
      onClick={(e) => {
        playClick()
        onClick(e)
      }}
    >
        <Box
            component={'svg'}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 139 139"
            fill="none"
            sx={{
                '& circle': {
                    transition: '0.3s ease-out',
                },
                '& path': {
                    transition: '0.3s ease-out',
                },
                '&:hover': {
                    '& circle': {
                        opacity: '1 !important',
                    },
                    '& path': {
                        fill: '#112232',
                    },
                },
            }}
        >
            <circle cx="69.2308" cy="69.2308" r="64.7308" fill="#112232" stroke="#3AD3A4" strokeWidth="9"/>
            <circle cx="69.2308" cy="69.2308" r="64.7308" fill="url(#paint0_radial_987_71338)" stroke="#3AD3A4" strokeWidth="9" style={{zIndex: '1', opacity: '0'}}/>
            <path d="M99.2305 68.5L50.4805 96.6458L50.4805 40.3542L99.2305 68.5Z" fill="#3AD3A4"/>
            <defs>
                <radialGradient id="paint0_radial_987_71338" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(69.2308 69.2308) rotate(90) scale(69.2308)">
                    <stop stop-color="#3AD3A4"/>
                    <stop offset="1" stop-color="#2B927A"/>
                </radialGradient>
            </defs>
        </Box>
    </Box>
  )
}

export default ButtonVideoPlay
