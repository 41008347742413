import React, { useEffect, useRef } from 'react'
import Box from '@mui/material/Box'
import useSound from 'use-sound'
import hoverSound from '../../assets/sounds/1_Button_Hover_Sound.wav'
import clickSound from '../../assets/sounds/2_Ikon_Click.wav'

const ButtonRead = ({ variant = 0, sx, onClick }) => {
  const [playHover] = useSound(hoverSound, {
    interrupt: true,
  })
  const [playClick] = useSound(clickSound, {
    interrupt: true,
  })

  const isSoundActive = useRef(false)

  useEffect(() => {
    isSoundActive.current = false

    setTimeout(() => {
      isSoundActive.current = true
    }, 200)
  })

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '3%',
        right: '6%',
        cursor: 'pointer',
        zIndex: '1',
          height: '7.4vh',
          aspectRatio: '1/1',
        ...sx,
      }}
      onMouseEnter={() => {
        isSoundActive.current && playHover()
      }}
      onClick={(e) => {
        playClick()
        onClick(e)
      }}
    >
        {variant === 0 ? (
            <Box
                component={'svg'}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 70 70"
                fill="none"
                sx={{
                    '& circle': {
                        transition: '0.3s ease-out',
                    },
                    '& path': {
                        transition: '0.3s ease-out',
                    },
                    '&:hover': {
                        '& circle': {
                            fill: '#3AD3A4',
                        },
                        '& path': {
                            fill: '#112232',
                        },
                    },
                }}
            >
                <circle cx="35" cy="35" r="35" fill="#112232"/>
                <path d="M26 25V23H33V25H26Z" fill="#3AD3A4"/>
                <path d="M26 28V30H45V28H26Z" fill="#3AD3A4"/>
                <path d="M26 35V33H45V35H26Z" fill="#3AD3A4"/>
                <path d="M26 38V40H45V38H26Z" fill="#3AD3A4"/>
                <path d="M26 45V43H45V45H26Z" fill="#3AD3A4"/>
                <path d="M26 48V50H45V48H26Z" fill="#3AD3A4"/>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd" d="M43.4142 14H20V57H52V22.5858L43.4142 14ZM22 55V16H41V25H50V55H22ZM49.5858 23L43 16.4142V23H49.5858Z"
                    fill="#3AD3A4"
                />
            </Box>
        ) : (
            <Box
                component={'svg'}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 70 70"
                fill="none"
                sx={{
                    '& circle': {
                        transition: '0.3s ease-out',
                    },
                    '&:hover': {
                        '& circle': {
                            fill: '#fff',
                        },
                    },
                }}
            >
                <circle cx="35" cy="35" r="35" fill="#3AD3A4"/>
                <path d="M26 25V23H33V25H26Z" fill="#112232"/>
                <path d="M26 28V30H45V28H26Z" fill="#112232"/>
                <path d="M26 35V33H45V35H26Z" fill="#112232"/>
                <path d="M26 38V40H45V38H26Z" fill="#112232"/>
                <path d="M26 45V43H45V45H26Z" fill="#112232"/>
                <path d="M26 48V50H45V48H26Z" fill="#112232"/>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd" d="M43.4142 14H20V57H52V22.5858L43.4142 14ZM22 55V16H41V25H50V55H22ZM49.5858 23L43 16.4142V23H49.5858Z"
                    fill="#112232"
                />
            </Box>
        )}
    </Box>
  )
}

export default ButtonRead
