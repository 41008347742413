import SliderWrapper from '../../../components/Slider/SliderWrapper'
import React, { memo, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import useSound from 'use-sound'
import ButtonMenu from "../Buttons/ButtonMenu";
import ButtonLeft from "../Buttons/ButtonLeft";
import ButtonRight from "../Buttons/ButtonRight";
import Header from "../Header/Header";
import Logo from "../Logo/Logo";
import Typography from "@mui/material/Typography";
import fonts from "../../../styles/fonts";
import ButtonPlay from "../Buttons/ButtonPlay";
import ButtonPause from "../Buttons/ButtonPause";
import ReactPlayer from "react-player";
import AdditionalStudy from "../AdditionalStudy/AdditionalStudy";

const Animated = ({
    placement,
    animation,
    image,
  voiceover,
  isActive,
  language,
  text,
  title,
  goToPrevSlide,
  goToNextSlide,
  volume,
    loop = true,
                      additionalStudy,
}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isLoadedBackground, setIsLoadedBackground] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [playVoiceover, { stop, pause }] = useSound(voiceover?.[language], {
    onload: () => {
      setIsLoaded(true)
    },
    onend: () => {
      setIsPlaying(false)
    },
  })

  const onPlay = () => {
    setIsPlaying(true)
    playVoiceover()
  }

  const onPause = () => {
    setIsPlaying(false)
    pause()
  }

  const onStop = () => {
    setIsPlaying(false)
    stop()
  }

  useEffect(() => {
    if (isLoaded) {
      if (isActive) {
        onPlay()
      } else {
        onStop()
      }
    }

    return () => onStop()
  }, [isLoaded, isActive])

  useEffect(() => {
    setIsLoaded(false)
    onStop()
  }, [language])

  return (
    <SliderWrapper flexCenter>
        <ButtonMenu
            variant={1}
            sx={{
                zIndex: '20',
            }}
        />

        <Logo
            color={'#112232'}
            sx={{
                zIndex: '18',
            }}
        />

      {voiceover && (
        <>
          <ButtonPlay
            sx={{
              zIndex: '20',
              opacity: isPlaying ? '1' : '0',
              pointerEvents: !isPlaying ? 'none' : 'all',
              transition: 'opacity 500ms ease 0s',
            }}
            onClick={onPause}
          />

          <ButtonPause
            sx={{
              zIndex: '20',
              opacity: !isPlaying ? '1' : '0',
              pointerEvents: isPlaying ? 'none' : 'all',
              transition: 'opacity 500ms ease 0s',
            }}
            onClick={onPlay}
          />
        </>
      )}

      <ButtonLeft
        isShown
        variant={1}
        onClick={goToPrevSlide}
        sx={{
          zIndex: '18',
        }}
      />

        <ButtonRight
            isShown
            variant={1}
            onClick={goToNextSlide}
            sx={{
                zIndex: '18',
            }}
        />

      <Header text={title[language]} />

        {animation && (
            <Box
                sx={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        top: '0',
                        left: '0',
                        position: 'absolute',
                        '& video': {
                            objectFit: 'cover',
                        },
                    }}
                >
                    <ReactPlayer
                        url={animation?.[language] || animation}
                        playing={isActive}
                        loop={loop}
                        muted
                        width={'100%'}
                        height={'100%'}
                    />
                </Box>
            </Box>
        )}

        {image && (
            <Box
                sx={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                }}
            >
                <Box
                    component={'img'}
                    src={image}
                    sx={{
                        width: '100%',
                        height: '100%',
                        top: '0',
                        left: '0',
                        position: 'absolute',
                        objectFit: 'cover',
                    }}
                />
            </Box>
        )}

        <Box
            sx={{
                position: 'absolute',
                top: '50%',
                translate: '0 -50%',
                left: placement === 'left' ? '6.7%' : undefined,
                right: placement === 'right' ? '6.7%' : undefined,
                width: '33%',
                borderRadius: '50px',
                padding: '32px',
                background: 'rgba(255, 255, 255, 0.50)',
            }}
        >
            <Typography
                sx={{
                    ...fonts.Rubik['32px']({
                        lineHeight: 41
                    }),
                }}
            >
                {text[language]}
            </Typography>
        </Box>

        {additionalStudy && <AdditionalStudy additionalStudy={additionalStudy} language={language} />}
    </SliderWrapper>
  )
}

export default memo(Animated)
