import React from "react";
import BackgroundWave from "../BackgroundWave/BackgroundWave";
import background from "../../assets/PROTOTYP/UI/CAST_bg.png";
import Logo from "../Logo/Logo";
import PersonAnimation from "../PersonAnimation/PersonAnimation";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import fonts from "../../../styles/fonts";
import Button from "../Buttons/Button";
import SliderWrapper from "../../../components/Slider/SliderWrapper";
import ButtonMenu from "../Buttons/ButtonMenu";

const ChapterStart = ({ language, goToPrevSlide, goToNextSlide, title, isActive }) => {
    return (
        <SliderWrapper flexCenter>
            <BackgroundWave
                background={background}
            />

            <PersonAnimation
                isActive={isActive}
                variant={1}
            />

            <ButtonMenu />

            <Logo />

            <Box
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: '1',
                    gap: '18.5vh',
                }}
            >
                <Typography
                    sx={{
                        ...fonts.Rubik['90px']({
                            color: '#3AD3A4',
                            fontWeight: '700',
                        }),
                        textAlign: 'center',
                        textTransform: 'uppercase',
                    }}
                >
                    {title[language]}
                </Typography>

                <Button
                    onClick={goToNextSlide}
                >
                    {language === 'sk' ? 'POKRAČUJ' : 'Continue'}
                </Button>
            </Box>
        </SliderWrapper>
    )
}

export default ChapterStart
