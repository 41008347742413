import React from 'react'
import Box from '@mui/material/Box'
import SliderWrapper from '../Slider/SliderWrapper'
import partnersSK from '../../v2/assets/PROTOTYP/K00/K00-1.jpg'
import partnersEN from '../../v2/assets/PROTOTYP/K00/K00-1-EN.jpg'
import ButtonNextChapter from '../Buttons/ButtonNextChapter'
import { useNavigate } from 'react-router-dom'
import ButtonLeft from "../../v2/components/Buttons/ButtonLeft";
import ButtonRight from "../../v2/components/Buttons/ButtonRight";

const Partners = ({ isEnding, language, goToPrevSlide, goToNextSlide }) => {
  const navigate = useNavigate()

  const partners = {
    sk: partnersSK,
    en: partnersEN,
  }[language]

  return (
    <SliderWrapper
        flexCenter
        sx={{
            background: '#fff',
        }}
    >
      <Box
        component={'img'}
        src={partners}
        sx={{
          maxWidth: '100%',
          maxHeight: '100vh',
          objectFit: 'contain',
        }}
      />

      <ButtonLeft
        isShown
        onClick={goToPrevSlide}
      />

        <ButtonRight
            isShown
            onClick={goToNextSlide}
        />
    </SliderWrapper>
  )
}

export default Partners
