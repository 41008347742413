import React, { useEffect, useRef } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import fonts from '../../styles/fonts'
import useSound from 'use-sound'
import hoverSound from '../../assets/sounds/1_Button_Hover_Sound.wav'
import clickSound from '../../assets/sounds/2_Ikon_Click.wav'
import { hoverTransition, moveUp } from '../../styles/hover'
import Header from "../../v2/components/Header/Header";

const QuizQuestion = ({ index, question, onClick, language }) => {
  const [playHover] = useSound(hoverSound, {
    interrupt: true,
  })
  const [playClick] = useSound(clickSound, {
    interrupt: true,
  })

  const onAnswer = (answer) => onClick(index, answer)

  const isSoundActive = useRef(false)

  useEffect(() => {
    isSoundActive.current = false

    setTimeout(() => {
      isSoundActive.current = true
    }, 200)
  })

  return (
    <Box
        sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}
    >
      <Header
          text={question.question[language]}
          sx={{
              top: '8%',
              border: '10px solid #3AD3A4',
              minWidth: 'unset',
          }}
          textSx={{
              ...fonts.Rubik['36px']({
                  color: '#E8AEBA',
                  fontWeight: '700',
                  lineHeight: 40,
                  letterSpacing: 1.08,
              }),
          }}
      />

        <Box
            sx={{
                position: 'relative',
                top: '3%',
                display: 'flex',
                background: 'rgba(32, 97, 91, 0.8)',
                borderRadius: '50px',
                width: question.image ? '74%' : '60%',
                maxHeight: '57%',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '3vh',
                    padding: '5vh',
                    flexBasis: question.image ? '50%' : '100%',
                    justifyContent: 'space-evenly',
                }}
            >
                {question.options[language].map((option, index) => (
                    <Box
                        key={option}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginRight: '3.7%',
                                minWidth: '70px',
                                aspectRatio: '1/1',
                                cursor: 'pointer',
                                background: '#112232',
                                borderRadius: '50%',
                                ...hoverTransition,
                                '&:hover': {
                                    ...moveUp,
                                    background: '#3AD3A4',
                                    '& p': {
                                        ...hoverTransition,
                                        color: '#112232',
                                    },
                                },
                            }}
                            onMouseEnter={() => {
                                isSoundActive.current && playHover()
                            }}
                            onClick={() => {
                                playClick()
                                onAnswer(index)
                            }}
                        >
                            <Typography
                                sx={{
                                    ...fonts.Rubik['32px']({
                                        color: '#3AD3A4',
                                        fontWeight: '600',
                                        lineHeight: 32,
                                    }),
                                }}
                            >
                                {index === 0 ? 'A' : index === 1 ? 'B' : 'C'}
                            </Typography>
                        </Box>

                        <Typography
                            sx={{
                                ...fonts.Rubik['32px']({
                                    color: '#3AD3A4',
                                }),
                            }}
                        >
                            {option}
                        </Typography>
                    </Box>
                ))}
            </Box>

            {question.image && (
                <Box
                    sx={{
                        flexBasis: '50%',
                    }}
                >
                    <Box
                        component={'img'}
                        src={question.image}
                        sx={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '0 50px 50px 0',
                            objectFit: 'cover',
                        }}
                    />
                </Box>
            )}
        </Box>
    </Box>
  )
}

export default QuizQuestion
