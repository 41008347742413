import React from 'react'
import Video from "../components/Video/Video";
import video1SK from "../v2/assets/PROTOTYP/K07/K07_01_1.mp4";
import video1EN from "../v2/assets/PROTOTYP/K07/K07_ eng gg.mp4";
import ChapterTitle from "../v2/components/ChapterTitle/ChapterTitle";
import backgroundk02 from "../v2/assets/PROTOTYP/K07/K07-0.png";
import Animated2 from "../v2/components/Animated/Animated2";
import animation1 from "../v2/assets/PROTOTYP/K07/K07-2/K07-2.mp4";
import animation2 from "../v2/assets/PROTOTYP/K07/K07-3/K07-3.mp4";
import voiceoverK02_1SK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K07_2.mp3";
import voiceoverK02_1EN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K07_2.mp3";
import voiceoverK02_2SK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K07_3.mp3";
import voiceoverK02_2EN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K07_3.mp3";
import Animated from "../v2/components/Animated/Animated";
import bg from "../v2/assets/PROTOTYP/K07/K07-DOPLNKOVESTUDIUM-1.jpg";
import bg2 from "../v2/assets/PROTOTYP/K07/K07-DOPLNKOVESTUDIUM-2.jpg";
import bg3 from "../v2/assets/PROTOTYP/K07/K07-DOPLNKOVESTUDIUM-3.jpg";

const title = {
  sk: 'MERANIE A CERTIFIKÁCIA “ZELENOSTI” BUDOV',
  en: 'Measuring and certifying the "greenness" of buildings',
}

const textImage1 = {
  sk: (
      <>
        Túto otázku si položili aj odborníci a vymysleli systém tzv. merania a certifikácie „zelenosti“ budov. <b>Systém merania a certifikácie zelených budov</b> vymysleli preto, aby sa naozaj dalo overiť, že budova je navrhnutá ako zelená a že aj jej prevádzka je udržateľná.
      </>
  ),
  en: (
      <>
        Experts have also asked themselves this question and have devised a system to measure and certify the 'greenness' of buildings. They devised <b>the green building measurement and certification system</b> so that it can be verified that a building is indeed designed to be green and that its operation is also sustainable.
      </>
  )
}

const textImage2 = {
  sk: (
      <>
        Medzi najznámejšie certifikačné systémy, ktoré dávajú budovám pečať udržateľnej výstavby a merajú ich „zelenosť“ sú napríklad systém certifikácie <b>LEED,</b> ktorý vymysleli v USA, alebo <b>BREEAM,</b> ktorý je používaný najmä v Británií alebo <b>DGNB</b> v Nemecku a Rakúsku. U nás sú najpoužívanejšími systémy LEED a BREEAM.
      </>
  ),
  en: (
      <>
        The most well-known certification systems that give buildings a sustainable seal of approval and measure their “greenness” are, for example, the <b>LEED</b> certification system invented in the USA, or <b>BREEAM,</b> mainly used in the UK, or <b>DGNB</b> in Germany and Austria. Here, LEED and BREEAM are the most used systems.
      </>
  )
}

const chapter7 = {
  url: '/chapter-7',
  name: 'chapter7',
  slides: [
    {
      Component: ChapterTitle,
      props: {
        title: {
          sk: <>MERANIE A CERTIFIKÁCIA <br/> “ZELENOSTI” BUDOV</>,
          en: <>Measuring and certifying the <br/> "greenness" of buildings</>,
        },
        background: backgroundk02,
      },
    },
    {
      Component: Video,
      props: {
        video: {
          sk: video1SK,
          en: video1EN,
        },
      },
    },
    {
      Component: Animated2,
      props: {
        animation: animation1,
        title: title,
        voiceover: {
          sk: voiceoverK02_1SK,
          en: voiceoverK02_1EN,
        },
        text: textImage1,
        loop: false,
        additionalStudy: [
          {
            img: bg,
            text: {
              sk: (
                  <>
                    <b>Na Slovensku práve preto vznikli viaceré firmy, ktoré sa zaoberajú meraním a certifikáciou „zelenosti“ budov.</b>
                    <br/><br/>
                    Tieto firmy na základe prísnych kritérií hodnotia, či a v akej miere boli pri výstavbe použité lokálne ekologické stavebné materiály, či je v budove navrhnutý <b>energeticky efektívny systém vykurovania a chladenia budovy,</b> či sa <b>zachytáva dažďová voda</b> a využíva sa na <b>zavlažovanie zelene alebo splachovanie WC,</b> či je v budove kvalitné vnútorné prostredie, kde sa ľudia cítia dobre alebo či sú tam nabíjacie stanice pre elektromobily, parkoviská pre bicykle, atď. </>
              ),
              en: (
                  <>
                    <b>That is why in Slovakia, a number of companies have been set up to measure and certify the "greenness" of buildings.</b>
                    <br/><br/>
                    Based on strict criteria, these companies evaluate whether and to what extent local ecological building materials were used in the construction, whether the building has an <b>energy-efficient heating and cooling system,</b> whether <b>rainwater is captured</b> and used for <b>irrigating the greenery or flushing toilets,</b> whether the building has a high-quality indoor environment where people feel comfortable or whether there are charging stations for electric cars, parking lots for bicycles, etc.</>
              ),
            },
          },
          {
            img: bg2,
            text: {
              sk: (
                  <>
                    Je to celý <b>súbor prísnych kritérií,</b> pomocou ktorých sa meria „zelenosť“ budov a to nielen ich prevádzka, ale aj samotná výstavba.
                    Každým rokom pribúdajú nové a nové budovy, ktoré sa môžu pochváliť niektorým z takýchto certifikátov. Väčšina systémov na meranie „zelenosti“ budov <b>má určitú stupnicu,</b> ktorou vyjadrujú to, do akej mieri spĺňa budova <b>kritéria zelenosti a udržateľnosti.</b></>
              ),
              en: (
                  <>
                    It's a <b>whole set of strict criteria</b> by which the “greenness” of buildings is measured, not only their operation but also the construction itself.
                    Every year there are more and more buildings that can boast one of these certificates. Most systems for measuring the “greenness” of buildings <b>have a scale</b> to indicate the extent to which a building meets <b>the greenness and sustainability criteria.</b></>
              ),
            },
          },
          {
            img: bg3,
            text: {
              sk: (
                  <>
                    Vo svete je trendom, že najmä verejné budovy musia <b>spĺňať vysoké štandardy</b> udržateľnosti, aby boli príkladom pre súkromný sektor a dôkazom efektívneho využívania verejných financií. Rovnako aj mnohé nadnárodné korporácie sa snažia, aby ich sídla spĺňali najvyššie ekologické a energetické štandardy. Na Slovensku sú však hnacím motorom vo výstavbe zelených a udržateľných budov najmä súkromní investori.</>
              ),
              en: (
                  <>
                    The trend worldwide is that public buildings in particular <b>need to meet high standards</b> of sustainability in order to set an example to the private sector and to demonstrate the efficient use of public funds. In the same way, many multinational corporations strive to ensure that their headquarters meet the highest environmental and energy standards. In Slovakia, however, private investors are the main drivers in the construction of green and sustainable buildings.
                    In part 2 of the e-course we will present several buildings of private investors from Slovakia that have one of these certificates and are therefore green and sustainable buildings.
                  </>
              ),
            },
          },
        ],
      },
    },
    {
      Component: Animated,
      props: {
        placement: 'right',
        animation: animation2,
        title: title,
        voiceover: {
          sk: voiceoverK02_2SK,
          en: voiceoverK02_2EN,
        },
        text: textImage2,
        loop: false,
      },
    },
  ],
}

export default chapter7
