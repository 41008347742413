import React, {useEffect, useRef} from 'react'
import useSound from "use-sound";
import hoverSound from "../../../assets/sounds/1_Button_Hover_Sound.wav";
import clickSound from "../../../assets/sounds/2_Ikon_Click.wav";
import Box from "@mui/material/Box";
import fonts from "../../../styles/fonts";
import {hoverTransition, moveUp} from "../../../styles/hover";

const Button = ({ children, sx, onClick }) => {
    const isSoundActive = useRef(false)

    useEffect(() => {
        isSoundActive.current = false

        setTimeout(() => {
            isSoundActive.current = true
        }, 200)
    })

    const [playHover] = useSound(hoverSound, {
        interrupt: true,
    })
    const [playClick] = useSound(clickSound, {
        interrupt: true,
    })

    return (
        <Box
            sx={{
                borderRadius: '500px',
                background: 'linear-gradient(180deg, #3AD3A4 47.92%, #247467 100%)',
                ...fonts.Rubik['32px']({
                    fontWeight: '600',
                    letterSpacing: 2.24,
                }),
                textTransform: 'uppercase',
                padding: '1.6vh 1.6vw',
                textAlign: 'center',
                cursor: 'pointer',
                position: 'relative',
                boxShadow: '0px 4px 10px 0px #11223240',
                '&:before': {
                    transition: '0.3s ease-out',
                    position: 'absolute',
                    bottom: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    content: '" "',
                    borderRadius: '500px',
                    background: 'linear-gradient(180deg, #FFFFFF 50.52%, #999999 100%)',
                    opacity: '0',
                },
                ...hoverTransition,
                '&:hover': {
                    ...moveUp,
                    '&:before': {
                       opacity: '1',
                    }
                },
                ...sx,
            }}
            onMouseEnter={() => {
                isSoundActive.current && playHover()
            }}
            onClick={(e) => {
                playClick()
                onClick(e)
            }}
        >
            <span
                style={{
                    position: 'relative',
                }}
            >
                {children}
            </span>
        </Box>
    )
}

export default Button
