const defaultWidth = 1920
const defaultHeight = 1080

const pxToVw = (px) => (px / defaultWidth) * 100
const pxToVh = (px) => (px / defaultHeight) * 100

const generateFont = ({ fontSize, fontWeight = '400', lineHeight, letterSpacing, color = '#112232' }) => {
  return {
    fontSize: `${fontSize}px`,
    lineHeight: `${lineHeight}px`,
    letterSpacing: `${letterSpacing}px`,
    color,
    '&': {
      fontWeight,
      fontFamily: '"Rubik", sans-serif',
      fontSize: `clamp(16px, calc((${pxToVw(fontSize)}vw + ${pxToVh(
          fontSize
      )}vh) / 2), ${fontSize}px)`,
      lineHeight: `clamp(16px, calc((${pxToVw(lineHeight)}vw + ${pxToVh(
          lineHeight
      )}vh) / 2), ${lineHeight}px)`,
      letterSpacing: `clamp(0px, calc((${pxToVw(letterSpacing)}vw + ${pxToVh(
          letterSpacing
      )}vh) / 2), ${letterSpacing}px)`,
    },
  }
}

const fonts = {
  Rubik: {
    '108px': (props = {}) => generateFont({
      fontSize: 108,
      lineHeight: 119,
      letterSpacing: 5.4,
      ...props,
    }),
    '90px': (props = {}) => generateFont({
      fontSize: 90,
      lineHeight: 117,
      letterSpacing: 4.5,
      ...props,
    }),
    '70px': (props = {}) => generateFont({
      fontSize: 70,
      lineHeight: 91,
      letterSpacing: 4.9,
      ...props,
    }),
    '64px': (props = {}) => generateFont({
      fontSize: 64,
      lineHeight: 64,
      letterSpacing: 6.4,
      ...props,
    }),
    '42px': (props = {}) => generateFont({
      fontSize: 42,
      lineHeight: 54,
      letterSpacing: 0,
      ...props,
    }),
    '40px': (props = {}) => generateFont({
      fontSize: 40,
      lineHeight: 50,
      letterSpacing: 0.4,
      ...props,
    }),
    '36px': (props = {}) => generateFont({
      fontSize: 36,
      lineHeight: 46,
      letterSpacing: 0.72,
      ...props,
    }),
    '32px': (props = {}) => generateFont({
      fontSize: 32,
      lineHeight: 40,
      letterSpacing: 0.64,
      ...props,
    }),
    '30px': (props = {}) => generateFont({
      fontSize: 30,
      lineHeight: 40,
      letterSpacing: 0.6,
      ...props,
    }),
    '26px': (props = {}) => generateFont({
      fontSize: 26,
      lineHeight: 36,
      letterSpacing: 0.72,
      ...props,
    }),
    '24px': (props = {}) => generateFont({
      fontSize: 24,
      lineHeight: 24,
      letterSpacing: 1.68,
      ...props,
    }),
    '20px': (props = {}) => generateFont({
      fontSize: 20,
      lineHeight: 26,
      letterSpacing: 0.4,
      ...props,
    }),
    '18px': (props = {}) => generateFont({
      fontSize: 18,
      lineHeight: 20,
      letterSpacing: 0.54,
      ...props,
    }),
  },
  SourceSerif: {
    '70px': {
      fontSize: `calc((${pxToVw(70)}vw + ${pxToVh(70)}vh) / 2)`,
      lineHeight: `calc((${pxToVw(85)}vw + ${pxToVh(85)}vh) / 2)`,
      letterSpacing: `calc((${pxToVw(5.5)}vw + ${pxToVh(5.5)}vh) / 2)`,
      color: '#062F38',
      '&': {
        fontFamily: '"Rubik", sans-serif',
        fontWeight: '700',
        fontSize: `clamp(16px, calc((${pxToVw(70)}vw + ${pxToVh(
          70
        )}vh) / 2), 70px)`,
        lineHeight: `clamp(16px, calc((${pxToVw(85)}vw + ${pxToVh(
          85
        )}vh) / 2), 85px)`,
        letterSpacing: `clamp(2px, calc((${pxToVw(5.5)}vw + ${pxToVh(
          5.5
        )}vh) / 2), 5.5px)`,
      },
    },
    '50px': {
      fontSize: `calc((${pxToVw(50)}vw + ${pxToVh(50)}vh) / 2)`,
      lineHeight: `calc((${pxToVw(63)}vw + ${pxToVh(63)}vh) / 2)`,
      letterSpacing: `calc((${pxToVw(4)}vw + ${pxToVh(4)}vh) / 2)`,
      color: '#062F38',
      '&': {
        fontFamily: '"Rubik", sans-serif',
        fontWeight: '700',
        fontSize: `clamp(16px, calc((${pxToVw(50)}vw + ${pxToVh(
          50
        )}vh) / 2), 50px)`,
        lineHeight: `clamp(16px, calc((${pxToVw(63)}vw + ${pxToVh(
          63
        )}vh) / 2), 63px)`,
        letterSpacing: `clamp(2px, calc((${pxToVw(4)}vw + ${pxToVh(
          4
        )}vh) / 2), 4px)`,
      },
    },
    '46px': {
      fontSize: `calc((${pxToVw(46)}vw + ${pxToVh(46)}vh) / 2)`,
      lineHeight: `calc((${pxToVw(57)}vw + ${pxToVh(57)}vh) / 2)`,
      letterSpacing: `calc((${pxToVw(3.68)}vw + ${pxToVh(3.68)}vh) / 2)`,
      color: '#062F38',
      '&': {
        fontFamily: '"Rubik", sans-serif',
        fontWeight: '700',
        fontSize: `clamp(16px, calc((${pxToVw(46)}vw + ${pxToVh(
          46
        )}vh) / 2), 46px)`,
        lineHeight: `clamp(16px, calc((${pxToVw(57)}vw + ${pxToVh(
          57
        )}vh) / 2), 57px)`,
        letterSpacing: `clamp(2px, calc((${pxToVw(3.68)}vw + ${pxToVh(
          3.68
        )}vh) / 2), 3.68px)`,
      },
    },
    '40px': {
      fontSize: `calc((${pxToVw(40)}vw + ${pxToVh(40)}vh) / 2)`,
      lineHeight: `calc((${pxToVw(50)}vw + ${pxToVh(50)}vh) / 2)`,
      letterSpacing: `calc((${pxToVw(3)}vw + ${pxToVh(3)}vh) / 2)`,
      color: '#062F38',
      '&': {
        fontFamily: '"Rubik", sans-serif',
        fontWeight: '700',
        fontSize: `clamp(16px, calc((${pxToVw(40)}vw + ${pxToVh(
          40
        )}vh) / 2), 40px)`,
        lineHeight: `clamp(16px, calc((${pxToVw(50)}vw + ${pxToVh(
          50
        )}vh) / 2), 50px)`,
        letterSpacing: `clamp(2px, calc((${pxToVw(3)}vw + ${pxToVh(
          3
        )}vh) / 2), 3px)`,
      },
    },
    '38px': {
      fontSize: `calc((${pxToVw(38)}vw + ${pxToVh(38)}vh) / 2)`,
      lineHeight: `calc((${pxToVw(48)}vw + ${pxToVh(48)}vh) / 2)`,
      letterSpacing: `calc((${pxToVw(3)}vw + ${pxToVh(3)}vh) / 2)`,
      color: '#062F38',
      '&': {
        fontFamily: '"Rubik", sans-serif',
        fontWeight: '700',
        fontSize: `clamp(16px, calc((${pxToVw(38)}vw + ${pxToVh(
          38
        )}vh) / 2), 38px)`,
        lineHeight: `clamp(16px, calc((${pxToVw(48)}vw + ${pxToVh(
          48
        )}vh) / 2), 48px)`,
        letterSpacing: `clamp(2px, calc((${pxToVw(3)}vw + ${pxToVh(
          3
        )}vh) / 2), 3px)`,
      },
    },
    '36px': {
      fontSize: `calc((${pxToVw(36)}vw + ${pxToVh(36)}vh) / 2)`,
      lineHeight: `calc((${pxToVw(45)}vw + ${pxToVh(45)}vh) / 2)`,
      letterSpacing: `calc((${pxToVw(1.3)}vw + ${pxToVh(1.3)}vh) / 2)`,
      color: '#062F38',
      '&': {
        fontFamily: '"Rubik", sans-serif',
        fontWeight: '700',
        fontSize: `clamp(16px, calc((${pxToVw(36)}vw + ${pxToVh(
          36
        )}vh) / 2), 36px)`,
        lineHeight: `clamp(16px, calc((${pxToVw(45)}vw + ${pxToVh(
          45
        )}vh) / 2), 45px)`,
        letterSpacing: `clamp(1px, calc((${pxToVw(1.3)}vw + ${pxToVh(
          1.3
        )}vh) / 2), 1.3px)`,
      },
    },
    '34px': {
      fontSize: `calc((${pxToVw(34)}vw + ${pxToVh(34)}vh) / 2)`,
      lineHeight: `calc((${pxToVw(45)}vw + ${pxToVh(45)}vh) / 2)`,
      letterSpacing: `calc((${pxToVw(2.5)}vw + ${pxToVh(2.5)}vh) / 2)`,
      color: '#062F38',
      '&': {
        fontFamily: '"Rubik", sans-serif',
        fontWeight: '700',
        fontSize: `clamp(16px, calc((${pxToVw(34)}vw + ${pxToVh(
          34
        )}vh) / 2), 34px)`,
        lineHeight: `clamp(16px, calc((${pxToVw(45)}vw + ${pxToVh(
          45
        )}vh) / 2), 45px)`,
        letterSpacing: `clamp(1px, calc((${pxToVw(2.5)}vw + ${pxToVh(
          2.5
        )}vh) / 2), 2.5px)`,
      },
    },
    '30px': {
      fontSize: `calc((${pxToVw(30)}vw + ${pxToVh(30)}vh) / 2)`,
      lineHeight: `calc((${pxToVw(30)}vw + ${pxToVh(30)}vh) / 2)`,
      letterSpacing: `calc((${pxToVw(2.4)}vw + ${pxToVh(2.4)}vh) / 2)`,
      color: '#062F38',
      '&': {
        fontFamily: '"Rubik", sans-serif',
        fontWeight: '700',
        fontSize: `clamp(16px, calc((${pxToVw(30)}vw + ${pxToVh(
          30
        )}vh) / 2), 30px)`,
        lineHeight: `clamp(16px, calc((${pxToVw(30)}vw + ${pxToVh(
          30
        )}vh) / 2), 30px)`,
        letterSpacing: `clamp(1px, calc((${pxToVw(2.4)}vw + ${pxToVh(
          2.4
        )}vh) / 2), 2.4px)`,
      },
    },
    '26px': {
      fontSize: `calc((${pxToVw(26)}vw + ${pxToVh(26)}vh) / 2)`,
      lineHeight: `calc((${pxToVw(26)}vw + ${pxToVh(26)}vh) / 2)`,
      letterSpacing: `calc((${pxToVw(2.4)}vw + ${pxToVh(2.4)}vh) / 2)`,
      color: '#062F38',
      '&': {
        fontFamily: '"Rubik", sans-serif',
        fontWeight: '700',
        fontSize: `clamp(16px, calc((${pxToVw(26)}vw + ${pxToVh(
          26
        )}vh) / 2), 26px)`,
        lineHeight: `clamp(16px, calc((${pxToVw(26)}vw + ${pxToVh(
          26
        )}vh) / 2), 26px)`,
        letterSpacing: `clamp(1px, calc((${pxToVw(2.4)}vw + ${pxToVh(
          2.4
        )}vh) / 2), 2.4px)`,
      },
    },
    '24px': {
      fontSize: `calc((${pxToVw(24)}vw + ${pxToVh(24)}vh) / 2)`,
      lineHeight: `calc((${pxToVw(24)}vw + ${pxToVh(24)}vh) / 2)`,
      letterSpacing: `calc((${pxToVw(2)}vw + ${pxToVh(2)}vh) / 2)`,
      color: '#062F38',
      '&': {
        fontFamily: '"Rubik", sans-serif',
        fontWeight: '700',
        fontSize: `clamp(16px, calc((${pxToVw(24)}vw + ${pxToVh(
          24
        )}vh) / 2), 24px)`,
        lineHeight: `clamp(16px, calc((${pxToVw(24)}vw + ${pxToVh(
          24
        )}vh) / 2), 24px)`,
        letterSpacing: `clamp(1px, calc((${pxToVw(2)}vw + ${pxToVh(
          2
        )}vh) / 2), 2px)`,
      },
    },
    '22px': {
      fontSize: `calc((${pxToVw(22)}vw + ${pxToVh(22)}vh) / 2)`,
      lineHeight: `calc((${pxToVw(23)}vw + ${pxToVh(23)}vh) / 2)`,
      letterSpacing: `calc((${pxToVw(2)}vw + ${pxToVh(2)}vh) / 2)`,
      color: '#062F38',
      '&': {
        fontFamily: '"Rubik", sans-serif',
        fontWeight: '700',
        fontSize: `clamp(16px, calc((${pxToVw(22)}vw + ${pxToVh(
          22
        )}vh) / 2), 22px)`,
        lineHeight: `clamp(16px, calc((${pxToVw(23)}vw + ${pxToVh(
          23
        )}vh) / 2), 23px)`,
        letterSpacing: `clamp(1px, calc((${pxToVw(2)}vw + ${pxToVh(
          2
        )}vh) / 2), 2px)`,
      },
    },
  },
  Inter: {
    '38px': {
      fontSize: `calc((${pxToVw(37)}vw + ${pxToVh(37)}vh) / 2)`,
      lineHeight: `calc((${pxToVw(46)}vw + ${pxToVh(46)}vh) / 2)`,
      letterSpacing: `calc((${pxToVw(3)}vw + ${pxToVh(3)}vh) / 2)`,
      color: '#f4e7cb',
      '&': {
        fontFamily: '"Rubik", sans-serif',
        fontWeight: '600',
        fontSize: `clamp(16px, calc((${pxToVw(37)}vw + ${pxToVh(
          37
        )}vh) / 2), 36px)`,
        lineHeight: `clamp(16px, calc((${pxToVw(46)}vw + ${pxToVh(
          46
        )}vh) / 2), 46px)`,
        letterSpacing: `clamp(1px, calc((${pxToVw(3)}vw + ${pxToVh(
          3
        )}vh) / 2), 3px)`,
      },
    },
    '34px': {
      fontSize: `calc((${pxToVw(34)}vw + ${pxToVh(34)}vh) / 2)`,
      lineHeight: `calc((${pxToVw(41)}vw + ${pxToVh(41)}vh) / 2)`,
      color: '#f4e7cb',
      '&': {
        fontFamily: '"Rubik", sans-serif',
        fontWeight: '400',
        fontSize: `clamp(16px, calc((${pxToVw(34)}vw + ${pxToVh(
          34
        )}vh) / 2), 34px)`,
        lineHeight: `clamp(16px, calc((${pxToVw(41)}vw + ${pxToVh(
          41
        )}vh) / 2), 41px)`,
        letterSpacing: '0',
      },
    },
    '32px': {
      fontSize: `calc((${pxToVw(32)}vw + ${pxToVh(32)}vh) / 2)`,
      lineHeight: `calc((${pxToVw(40)}vw + ${pxToVh(40)}vh) / 2)`,
      color: '#f4e7cb',
      '&': {
        fontFamily: '"Rubik", sans-serif',
        fontWeight: '400',
        fontSize: `clamp(16px, calc((${pxToVw(32)}vw + ${pxToVh(
            32
        )}vh) / 2), 32px)`,
        lineHeight: `clamp(16px, calc((${pxToVw(40)}vw + ${pxToVh(
            40
        )}vh) / 2), 40px)`,
        letterSpacing: '0.64px',
      },
    },
    '28px': {
      fontSize: `calc((${pxToVw(30)}vw + ${pxToVh(30)}vh) / 2)`,
      lineHeight: `calc((${pxToVw(38)}vw + ${pxToVh(38)}vh) / 2)`,
      color: '#f4e7cb',
      '&': {
        fontFamily: '"Rubik", sans-serif',
        fontWeight: '400',
        fontSize: `clamp(16px, calc((${pxToVw(30)}vw + ${pxToVh(
          30
        )}vh) / 2), 30px)`,
        lineHeight: `clamp(16px, calc((${pxToVw(38)}vw + ${pxToVh(
          38
        )}vh) / 2), 38px)`,
        letterSpacing: '0',
      },
    },
    '27px': {
      fontSize: `calc((${pxToVw(28)}vw + ${pxToVh(28)}vh) / 2)`,
      lineHeight: `calc((${pxToVw(36)}vw + ${pxToVh(36)}vh) / 2)`,
      color: '#f4e7cb',
      '&': {
        fontFamily: '"Rubik", sans-serif',
        fontWeight: '400',
        fontSize: `clamp(16px, calc((${pxToVw(28)}vw + ${pxToVh(
          28
        )}vh) / 2), 28px)`,
        lineHeight: `clamp(16px, calc((${pxToVw(36)}vw + ${pxToVh(
          36
        )}vh) / 2), 36px)`,
        letterSpacing: '0',
      },
    },
    '26px': {
      fontSize: `calc((${pxToVw(26)}vw + ${pxToVh(26)}vh) / 2)`,
      lineHeight: `calc((${pxToVw(30)}vw + ${pxToVh(30)}vh) / 2)`,
      color: '#f4e7cb',
      '&': {
        fontFamily: '"Rubik", sans-serif',
        fontWeight: '400',
        fontSize: `clamp(16px, calc((${pxToVw(26)}vw + ${pxToVh(
          26
        )}vh) / 2), 26px)`,
        lineHeight: `clamp(16px, calc((${pxToVw(30)}vw + ${pxToVh(
          30
        )}vh) / 2), 30px)`,
        letterSpacing: '0',
      },
    },
    '24px': {
      fontSize: `calc((${pxToVw(24)}vw + ${pxToVh(24)}vh) / 2)`,
      lineHeight: `calc((${pxToVw(32)}vw + ${pxToVh(32)}vh) / 2)`,
      color: '#f4e7cb',
      '&': {
        fontFamily: '"Rubik", sans-serif',
        fontWeight: '400',
        fontSize: `clamp(16px, calc((${pxToVw(24)}vw + ${pxToVh(
          24
        )}vh) / 2), 24px)`,
        lineHeight: `clamp(16px, calc((${pxToVw(32)}vw + ${pxToVh(
          32
        )}vh) / 2), 32px)`,
        letterSpacing: '0',
      },
    },
    '22px': {
      fontSize: `calc((${pxToVw(22)}vw + ${pxToVh(22)}vh) / 2)`,
      lineHeight: `calc((${pxToVw(30)}vw + ${pxToVh(30)}vh) / 2)`,
      color: '#f4e7cb',
      '&': {
        fontFamily: '"Rubik", sans-serif',
        fontWeight: '400',
        fontSize: `clamp(16px, calc((${pxToVw(22)}vw + ${pxToVh(
          22
        )}vh) / 2), 22px)`,
        lineHeight: `clamp(16px, calc((${pxToVw(30)}vw + ${pxToVh(
          30
        )}vh) / 2), 30px)`,
        letterSpacing: '0',
      },
    },
    '20px': {
      fontSize: `calc((${pxToVw(20)}vw + ${pxToVh(20)}vh) / 2)`,
      lineHeight: `calc((${pxToVw(24)}vw + ${pxToVh(24)}vh) / 2)`,
      color: '#f4e7cb',
      '&': {
        fontFamily: '"Rubik", sans-serif',
        fontWeight: '400',
        fontSize: `clamp(16px, calc((${pxToVw(20)}vw + ${pxToVh(
          20
        )}vh) / 2), 20px)`,
        lineHeight: `clamp(16px, calc((${pxToVw(24)}vw + ${pxToVh(
          24
        )}vh) / 2), 24px)`,
        letterSpacing: '0',
      },
    },
  },
}

export default fonts
