import React from 'react'
import Box from '@mui/material/Box'
import SlickSlider from 'react-slick'
import Typography from '@mui/material/Typography'
import fonts from '../../styles/fonts'
import { chapterColor } from '../../styles/colors'
import modalImg from "../../v2/assets/PROTOTYP/UI/modal.png";

const ModalContent = ({
  sliderRef,
  isShownModal,
  isShownText,
  setActiveSlide,
  chapter,
  language,
}) => {
  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    accessibility: false,
    draggable: false,
    touchMove: false,
    initialSlide: 0,
    afterChange: setActiveSlide,
      pauseOnHover: false,
      pauseOnFocus: false,
      pauseOnDotsHover: false,
      autoplaySpeed: 10000,

  }

  return (
    <Box
      sx={{
        width: 'calc(100% - 180px)',
        height: 'calc(100% - 176px)',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
        <Box
            component={'img'}
            src={modalImg}
            sx={{
                position: 'absolute',
                top: '50%',
                right: '-90px',
                width: '90px',
                height: '100%',
                translate: '0 -50%',
            }}
        />

        <Box
            component={'img'}
            src={modalImg}
            sx={{
                position: 'absolute',
                top: '50%',
                left: '-90px',
                width: '90px',
                height: '100%',
                translate: '0 -50%',
            }}
        />

      <Box
        sx={{
          opacity: isShownText ? '1' : '0',
          pointerEvents: !isShownText ? 'none' : 'all',
          transition: 'opacity 500ms ease 0s',
          position: 'absolute',
          width: '100%',
          height: '100%',
          background: 'linear-gradient(180deg, #16363F 39.58%, #112232 100%)',
            boxShadow: '3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset',
          zIndex: '1',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          sx={{
            ...fonts.Rubik['42px']({
                color: '#3AD3A4',
            }),
            width: '100%',
            maxWidth: '83%',
              '& b': {
                color: '#E8AEBA',
              },
            ...isShownModal.sx,
          }}
        >
          {isShownModal.text[language]}
        </Typography>
      </Box>

      <SlickSlider ref={sliderRef} {...settings}>
        {isShownModal.photos?.map((photo) => (
          <Box
            key={photo}
            component={'img'}
            src={photo}
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        ))}
      </SlickSlider>
    </Box>
  )
}

export default ModalContent
