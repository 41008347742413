import strip1Background from '../v2/assets/PROTOTYP/K05/K05_PAS.jpg'

import trigger1img1 from '../v2/assets/PROTOTYP/K05/K05-TRIGGER-1.jpg'
import trigger2img1 from '../v2/assets/PROTOTYP/K05/K05-TRIGGER-2.jpg'
import trigger3img1 from '../v2/assets/PROTOTYP/K05/K05-TRIGGER-3-1.jpg'
import trigger3img2 from '../v2/assets/PROTOTYP/K05/K05-TRIGGER-3-2.jpg'
import trigger3img3 from '../v2/assets/PROTOTYP/K05/K05-TRIGGER-3-3.jpg'
import trigger4img1 from '../v2/assets/PROTOTYP/K05/K05-TRIGGER-4-1.jpg'
import trigger4img2 from '../v2/assets/PROTOTYP/K05/K05-TRIGGER-4-2.jpg'
import trigger4img3 from '../v2/assets/PROTOTYP/K05/K05-TRIGGER-4-3.jpg'

import trigger1voiceoverSK from '../v2/assets/VOICEOVER/VOICEOVER_SK/K05_triger 1.mp3'
import trigger1voiceoverEN from '../v2/assets/VOICEOVER/VOICEOVER_EN/K05_triger 1.mp3'
import trigger2voiceoverSK from '../v2/assets/VOICEOVER/VOICEOVER_SK/K05_triger 2.mp3'
import trigger2voiceoverEN from '../v2/assets/VOICEOVER/VOICEOVER_EN/K05_triger 2.mp3'
import trigger3voiceoverSK from '../v2/assets/VOICEOVER/VOICEOVER_SK/K05_triger 3.mp3'
import trigger3voiceoverEN from '../v2/assets/VOICEOVER/VOICEOVER_EN/K05_triger 3.mp3'
import trigger4voiceoverSK from '../v2/assets/VOICEOVER/VOICEOVER_SK/K05_triger 4.mp3'
import trigger4voiceoverEN from '../v2/assets/VOICEOVER/VOICEOVER_EN/K05_triger 4.mp3'

const strip1Images = [
  {
    image: strip1Background,
    sx: {
      zIndex: '3',
      position: 'relative',
    },
  },
]

const strip1Triggers = [
  {
    id: 'strip1Triggers1',
    top: '15.6%',
    left: '15.4%',
    photos: [trigger1img1],
    source: '',
    voiceover: {
      sk: trigger1voiceoverSK,
      en: trigger1voiceoverEN,
    },
    title: {
      sk: 'ZELENÉ BUDOVY',
      en: 'Green buildings',
    },
    text: {
      sk: <>Pri zelených budovách sa kladie veľký dôraz aj na využitie zelene v interiéroch aj exteriéroch budov. <b>Triedenie odpadu, kompostovanie, šetrenie s vodou,</b> ako aj umožnenie <b>parkovania bicyklov,</b> či umiestnenie <b>nabíjacích staníc pre elektromobily,</b> by malo byť nielen v tomto type budov už samozrejmosťou.</>,
      en: <>Green buildings also place great emphasis on the use of greenery in both the interior and exterior of buildings. <b>Waste sorting, composting, water conservation, as well as allowing bicycle parking and electric vehicle charging stations</b> should be commonplace in this type of building.</>,
    },
  },
  {
    id: 'strip1Triggers2',
    top: '25.18%',
    left: '36.47%',
    photos: [trigger2img1],
    source: '',
    voiceover: {
      sk: trigger2voiceoverSK,
      en: trigger2voiceoverEN,
    },
    title: {
      sk: 'ZELENÉ BUDOVY',
      en: 'Green buildings',
    },
    text: {
      sk: <>Cieľom stavania zelených budov je, aby budovy boli <b>ohľaduplnejšie k našej prírode,</b> ale aj <b>k samotným ľuďom.</b> To znamená, že zelené budovy musia byť navrhnuté tak, aby <b>nemíňali toľko energie</b> na vykurovanie a chladenie, na svietenie, aby sa zachytávala dažďová voda, ktorá by inak skončila v kanalizácií a využívali ju na splachovanie WC alebo aspoň na zavlažovanie zelene a záhrady.</>,
      en: <>The goal of building green is to make buildings <b>more respectful to our nature, but also to people themselves.</b> This means that green buildings need to be designed so that they <b>don't spend so much energy</b> on heating and cooling, on lighting, that they capture rainwater that would otherwise end up in the sewers and using it to flush toilets or at least to irrigate green spaces and gardens.</>,
    },
  },
  {
    id: 'strip1Triggers3',
    top: '37.31%',
    left: '52.45%',
    photos: [trigger3img1, trigger3img2, trigger3img3],
    source: '',
    voiceover: {
      sk: trigger3voiceoverSK,
      en: trigger3voiceoverEN,
    },
    title: {
      sk: 'ZELENÉ BUDOVY',
      en: 'Green buildings',
    },
    text: {
      sk: <>Architekti môžu navrhnúť <b>zakomponovanie fotovoltických článkov na fasádu budovy alebo na strechy</b> s cieľom, aby elektrinu vyrobenú vo fotovoltických článkoch (premenou tepelnej na elektrickú energiu) mohol dom využívať <b>v lete na chladenie</b> a <b>v zime zase na kúrenie</b> a prebytočnú vyrobenú energiu by dokonca mohol dodávať do verejnej siete.</>,
      en: <>Architects can propose <b>incorporating photovoltaic cells on the facade of the building or on the roofs,</b> with the aim that the electricity produced by the photovoltaic cells (by converting heat into electricity) could be used by the house for <b>cooling in summer and heating in winter,</b> and the excess energy produced could even be fed into the public grid.</>,
    },
  },
  {
    id: 'strip1Triggers4',
    top: '28.05%',
    left: '83%',
    photos: [trigger4img1, trigger4img2, trigger4img3],
    source: '',
    voiceover: {
      sk: trigger4voiceoverSK,
      en: trigger4voiceoverEN,
    },
    title: {
      sk: 'ZELENÉ BUDOVY',
      en: 'Green buildings',
    },
    text: {
      sk: <>Okrem názvu zelená architektúra alebo zelené budovy sa používa aj pojem <b>udržateľná architektúra.</b> Zelené alebo udržateľné budovy pri svojej výstavbe a prevádzke a v celom životnom cykle budovy používajú <b>materiály a technológie, ktoré nezaťažujú životné prostredie,</b> ktoré sú vyrobené z <b>lokálnych,</b> ľahko <b>dostupných materiálov,</b> pri výrobe ktorých sa používajú prevažne <b>prírodné a recyklovateľné materiály.</b> Zároveň platí, že pri výstavbe a prevádzke zelených budov sa vo väčšej miere používajú <b>obnoviteľné zdroje energie.</b></>,
      en: <>In addition to the term green architecture or green buildings, the term <b>sustainable architecture</b> is also used. Green or sustainable buildings use <b>materials and technologies</b> that <b>do not pollute the environment</b> in their construction and operation, and throughout the life cycle of the building, which are made from <b>locally available, readily available materials,</b> using predominantly natural and recyclable materials in their production. At the same time, the construction and operation of green buildings shall make greater use of <b>renewable energy sources.</b></>,
    },
  },
]

export { strip1Images, strip1Triggers }
