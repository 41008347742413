import React from 'react'
import Box from '@mui/material/Box'
import { pulseAnimation } from '../../styles/animations'
import useSound from 'use-sound'
import hoverSound from '../../assets/sounds/1_Button_Hover_Sound.wav'
import clickSound from '../../assets/sounds/2_Ikon_Click.wav'

const StripTrigger = ({ top, left, onClick }) => {
  const [playHover] = useSound(hoverSound, {
    interrupt: true,
  })
  const [playClick] = useSound(clickSound, {
    interrupt: true,
  })

  return (
    <Box
      sx={{
        top,
        left,
        position: 'absolute',
        zIndex: '18',
        cursor: 'pointer',
        borderRadius: '50%',
          aspectRatio: '1/1',
          height: '7.4vh',
        ...pulseAnimation,
      }}
      onMouseEnter={playHover}
      onClick={(e) => {
        playClick()
        onClick(e)
      }}
    >
        <Box
            component={'svg'}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 139 139"
            fill="none"
            sx={{
                '& circle': {
                    transition: '0.3s ease-out',
                },
                '& path': {
                    transition: '0.3s ease-out',
                },
                '&:hover': {
                    '& circle': {
                        opacity: '1 !important',
                    },
                    '& path': {
                        fill: '#112232',
                    },
                },
            }}
        >
            <circle cx="69.2308" cy="69.2308" r="64.7308" fill="#112232" stroke="#3AD3A4" strokeWidth="9"/>
            <circle cx="69.2308" cy="69.2308" r="64.7308" fill="url(#paint0_radial_987_71338)" stroke="#3AD3A4" strokeWidth="9" style={{zIndex: '1', opacity: '0'}}/>
            <path d="M74.8602 81.5119H61.5058V79.6435C61.5058 77.3599 61.8422 75.4137 62.5151 73.8048C63.188 72.144 64.0421 70.7167 65.0773 69.523C66.1125 68.3293 67.2254 67.2913 68.4159 66.409C69.6582 65.4748 70.7969 64.5666 71.8321 63.6843C72.8674 62.7501 73.7214 61.764 74.3943 60.726C75.0672 59.688 75.4037 58.4684 75.4037 57.0671C75.4037 55.9772 75.1449 55.043 74.6273 54.2645C74.1614 53.4341 73.4885 52.7853 72.6086 52.3182C71.7804 51.7992 70.7969 51.5397 69.6582 51.5397C67.7948 51.5397 66.2419 52.1885 64.9997 53.486C63.8091 54.7316 62.8774 56.574 62.2045 59.0133H48.4619C48.9278 52.5258 51.0241 47.4915 54.7509 43.9104C58.4777 40.2774 63.4986 38.4609 69.8135 38.4609C73.9026 38.4609 77.4483 39.2394 80.4504 40.7964C83.5043 42.3015 85.8595 44.4554 87.5158 47.258C89.1722 50.0087 90.0004 53.2524 90.0004 56.9892C90.0004 59.4804 89.638 61.6342 88.9134 63.4507C88.1887 65.2672 87.257 66.8502 86.1183 68.1996C84.9795 69.4971 83.7373 70.6908 82.3915 71.7807C81.0974 72.8187 79.881 73.8048 78.7423 74.739C77.6035 75.6732 76.6718 76.6593 75.9472 77.6973C75.2225 78.6834 74.8602 79.7992 74.8602 81.0448V81.5119ZM68.5712 103.076C65.4137 103.076 63.0068 102.324 61.3505 100.819C59.6941 99.3136 58.8659 97.1338 58.8659 94.2793C58.8659 91.4248 59.6941 89.245 61.3505 87.7399C63.0068 86.1829 65.4137 85.4044 68.5712 85.4044C71.7286 85.4044 74.1355 86.1829 75.7919 87.7399C77.5 89.245 78.3541 91.4248 78.3541 94.2793C78.3541 97.1338 77.5 99.3136 75.7919 100.819C74.1355 102.324 71.7286 103.076 68.5712 103.076Z" fill="#3AD3A4"/>
            <defs>
                <radialGradient id="paint0_radial_987_71338" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(69.2308 69.2308) rotate(90) scale(69.2308)">
                    <stop stop-color="#3AD3A4"/>
                    <stop offset="1" stop-color="#2B927A"/>
                </radialGradient>
            </defs>
        </Box>
    </Box>
  )
}

export default StripTrigger
