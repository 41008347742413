import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import SliderWrapper from '../Slider/SliderWrapper'
import base from '../../assets/_Quiz/DaA_Q_NavigationBase.png'
import wrong from '../../assets/_Quiz/DaA_Q_Icon-Wrong.png'
import correct from '../../assets/_Quiz/DaA_Q_Icon-Correct.png'
import current from '../../assets/_Quiz/DaA_Q_Icon-CurrentQuestion.png'
import QuizQuestion from './QuizQuestion'
import SlickSlider from 'react-slick'
import imageShadow from '../../styles/shadows'
import img1 from '../../v2/assets/PROTOTYP/KVIZ/KVIZ-0TAZKA-8.jpg'
import img2 from '../../v2/assets/PROTOTYP/KVIZ/KVIZ-0TAZKA-11.jpg'
import img3 from '../../v2/assets/PROTOTYP/KVIZ/KVIZ-0TAZKA-14.jpg'
import { useQuizContext } from '../../contexts/QuizContext'
import correctVideo from '../../v2/assets/PROTOTYP/KVIZ/quiz-correct.webm'
import incorrectVideo from '../../v2/assets/PROTOTYP/KVIZ/quiz-wrong.webm'
import correctVideoSafari from '../../v2/assets/PROTOTYP/KVIZ/quiz-correct.mov'
import incorrectVideoSafari from '../../v2/assets/PROTOTYP/KVIZ/quiz-wrong.mov'
import ReactPlayer from 'react-player'
import useSound from 'use-sound'
import correctSound from '../../assets/_Sfx/__Compress/Q_CorrectAnswer.mp3'
import incorrectSound from '../../assets/_Sfx/__Compress/Q_IncorrectAnswer.mp3'
import bg from '../../v2/assets/PROTOTYP/KVIZ/BG.png'
import BackgroundWave from "../../v2/components/BackgroundWave/BackgroundWave";
import ButtonMenu from "../../v2/components/Buttons/ButtonMenu";
import Typography from "@mui/material/Typography";
import fonts from "../../styles/fonts";
import { isSafari } from 'react-device-detect'

const _correctVideo = isSafari ? correctVideoSafari : correctVideo
const _incorrectVideo = isSafari ? incorrectVideoSafari : incorrectVideo

const Quiz = ({ language, goToNextSlide }) => {
  const [playCorrect] = useSound(correctSound, {
    interrupt: true,
  })
  const [playIncorrect] = useSound(incorrectSound, {
    interrupt: true,
  })

  const { setCorrectAnswers } = useQuizContext()
  const sliderRef = useRef()
  const [activeSlide, setActiveSlide] = useState(0)
  const [answers, setAnswers] = useState({})
  const questions = [
    {
      question: {
        sk: '1. Čo sú to fosilné palivá?',
        en: '1. What are fossil fuels?',
      },
      options: {
        sk: [
          'Fosílne palivá sú chemické látky, ktoré sa pridávajú do liekov a používajú sa vo farmaceutickom priemysle',
          'Fosílne palivá sú tekutiny na mazanie a údržbu motorov a strojov',
          'Fosílne palivá ako ropa, uhlie, zemný plyn vznikajú zo starých odumretých organizmov. Ich spaľovaním sa vyrába energia a oxid uhličitý',
        ],
        en: [
          'Fossil fuels are chemicals that are added to medicines and used in the pharmaceutical industry',
          'Fossil fuels are fluids for lubrication and maintenance of engines and machinery',
          'Fossil fuels such as oil, coal, natural gas are formed from old dead organisms. Their combustion produces energy and carbon dioxide',
        ],
      },
      rightAnswer: [2],
    },
    {
      question: {
        sk: '2. Čo považujeme za obnoviteľné zdroje energie?',
        en: '2. What do we consider renewable energy sources?',
      },
      options: {
        sk: [
          'piesok, hlina, štrk',
          'slnko, vietor, voda',
          'dym, para, morská soľ',
        ],
        en: [
          'Sand, clay, gravel',
          'Sun, wind, water',
          'Smoke, steam, sea salt',
        ],
      },
      rightAnswer: [1],
    },
    {
      question: {
        sk: '3. Čo je cieľom stavania zelených budov?',
        en: '3. What is the purpose of building green buildings?',
      },
      options: {
        sk: [
          'Aby zelené budovy boli ohľaduplnejšie k životnému prostrediu, nemíňali toľko energie na vykurovanie, chladenie, na svietenie a aby zachytávali a ďalej zužitkovali dažďovú vodu',
          'Postaviť budovy s predzáhradkami, v blízkosti lesov a parkov',
          'Stavať budovy zo zelených materiálov ako listy, mach, prírodné bahno, malachit',
        ],
        en: [
          'To make green buildings more environmentally friendly, to not spend so much energy on heating, cooling, lighting and to capture and reuse rainwater',
          'Build buildings with front gardens, close to forests and parks',
          'Build buildings with green materials such as leaves, moss, natural mud, malachite.',
        ],
      },
      rightAnswer: [0],
    },
    {
      question: {
        sk: '4. Na čo kladie dôraz cirkulárna ekonomika?',
        en: '4. What does the circular economy emphasise?',
      },
      options: {
        sk: [
          'Na rotáciu zamestnancov do rôznych pracovísk',
          'Na zrecyklovanie a znova využitie určitých surovín',
          'Na zníženie cien výrobkov',
        ],
        en: [
          'On the rotation of employees to different workplaces',
          'To recycle and reuse certain raw materials',
          'To reduce product prices',
        ],
      },
      rightAnswer: [1],
    },
    {
      question: {
        sk: <>5. Ako sa volajú najznámejšie certifikačné systémy, <br/> ktoré dávajú budovám pečať udržateľnej výstavby <br/> a merajú ich „zelenosť“?</>,
        en: <>5. What are the names of the most well-known certification <br/> systems that give buildings the sustainable building <br/> seal of approval and measure their "greenness"?</>,
      },
      options: {
        sk: [
          'systém merania a certifikácie BREEAM, LEED, DGNB',
          'systém merania a certifikácie BRAAD, LIOR,FMSG',
          'systém merania a certifikácie JTRE',
        ],
        en: [
          'BREEAM, LEED, DGNB measurement and certification system',
          'BRAAD, LIOR, FMSG measurement and certification system',
          'JTRE measurement and certification system',
        ],
      },
      rightAnswer: [0],
    },
    {
      question: {
        sk: '6. Aké sú ekologické stavebné materiály?',
        en: '6. What are green building materials?',
      },
      options: {
        sk: [
          'Materiály, ktoré sa nikdy nezničia',
          'Pri výrobe ktorých sa nespotrebováva veľa energií a využívajú́ lokálne suroviny, napr. piesok, štrk, voda, drevo, atď.',
          'Materiály, ktoré sú finančne nenáročné',
        ],
        en: [
          'Materials that will never be destroyed',
          'Which do not consume a lot of energy and use local raw materials, e.g., sand, gravel, water, wood, etc.',
          'Materials that are inexpensive',
        ],
      },
      rightAnswer: [1],
    },
    {
      question: {
        sk: '7. Čo sú to cirkulárne a modulárne stavby?',
        en: '7. What are circular and modular buildings?',
      },
      options: {
        sk: [
          'Sú to také stavby, ktoré sa dajú veľmi rýchlo rozobrať na prvky a materiály, ktoré sa dajú opätovne použiť na výstavbu inej stavby',
          'Stavby postavené v tvare kruhu',
          'Stavby, ktoré zabezpečujú lepšiu a úspornejšiu cirkuláciu energie',
        ],
        en: [
          'They are structures that can be disassembled very quickly into elements and materials that can be reused to build another structure',
          'Structures built in the shape of a circle',
          'Buildings that provide better and more efficient circulation of energy',
        ],
      },
      rightAnswer: [0],
    },
    {
      question: {
        sk: '8. Ako sa volá budova na obrázku?',
        en: '8. What is the name of the building in the picture?',
      },
      options: {
        sk: [
          'Cube - Nemecko',
          'Budova Kocka - Bratislava',
          'Kancelárska budova Bloomberg',
        ],
        en: [
          'Cube - Germany',
          'Building Kocka - Bratislava',
          'Office building Bloomberg - London',
        ],
      },
      image: img1,
      rightAnswer: [0],
    },
    {
      question: {
        sk: <>9. Aké progresívne riešenia využíva administratívno-obchodný <br/> komplex Bastion Office v Košiciach?</>,
        en: <>9. What progressive solutions are used by the Bastion Office <br/> administrative and commercial complex in Košice?</>,
      },
      options: {
        sk: [
          'Vzduch, ktorý prúdi do budovy sa filtruje a intenzita vetrania sa nastavuje podľa priebežne nameranej koncentrácie CO2',
          'Progresívne riešenia na ovládanie rozvodových mechanizmov',
          'Najnovší zavlažovací systém na polievanie zelenej strechy',
        ],
        en: [
          'The air flowing into the building is filtered and the ventilation intensity is adjusted according to the continuously measured CO2 concentration',
          'Progressive solutions for controlling the distribution mechanism',
          'The latest irrigation system for watering the green roof',
        ],
      },
      rightAnswer: [0],
    },
    {
      question: {
        sk: '10. Čím je výnimočná budova IKEA vo Viedni?',
        en: '10. What makes the IKEA building in Vienna special?',
      },
      options: {
        sk: [
          'Svojim výnimočným švédskym dizajnom',
          'Je navrhnutá celá z dreva',
          'Je prístupná len pre chodcov a bicyklistov. Ak si vyberiete tovar, ktorý si neviete odviezť na bicykli, dovezie vám ho dopravca na elektromobile',
        ],
        en: [
          'Its exceptional Swedish design',
          'It is designed entirely of wood',
          'It is only accessible to pedestrians and cyclists. If you choose goods that you can\'t take by bicycle, a carrier will bring them to you in an electric car',
        ],
      },
      rightAnswer: [2],
    },
    {
      question: {
        sk: '11. Ako sa volajú budovy na obrázku?',
        en: '11. What are the buildings in the picture called?',
      },
      options: {
        sk: [
          'Panorama Towers, Vivo, Sky park',
          'River Park, Eurovea City 2, Westend',
          'Rozadol, River Park, NBS',
        ],
        en: [
          'Panorama Towers, Vivo, Sky park',
          'River Park, Eurovea City 2, Westend',
          'Rozadol, River Park, NBS',
        ],
      },
      image: img2,
      rightAnswer: [1],
    },
    {
      question: {
        sk: '12. Čo je súčasťou areálu Deloitte univerzity v Paríži?',
        en: '12. What is part of the Deloitte University campus in Paris?',
      },
      options: {
        sk: [
          'Záhrada, kde sa pestujú rôzne druhy zeleniny, ktoré potom univerzita využíva vo vlastnej kuchyni na prípravu jedál',
          'Veľký záhradný labyrint',
          'Záhradný altánok, kde sa organizujú koncerty a predstavenia pre študentov',
        ],
        en: [
          'A garden where different types of vegetables are grown, which the university then uses in its own kitchen to prepare meals',
          'A large garden labyrinth',
          'A garden gazebo where concerts and performances are organised for students',
        ],
      },
      rightAnswer: [0],
    },
    {
      question: {
        sk: <>13. Aké hodnotenie získala budova Pradiarne 1900 <br/> v Bratislave po rekonštrukcii?</>,
        en: <>13. What rating was given to the Pradiaren 1900 <br/> building in Bratislava after renovation?</>,
      },
      options: {
        sk: [
          'Prvú cenu v architektonickej súťaži cena ARCH',
          'Najlepšie zrekonštruovaná budova na Slovensku',
          'Najvyšší možný certifikát zelenosti BREEAM Outstanding',
        ],
        en: [
          'First prize in the ARCH prize architectural competition',
          'The best renovated building in Slovakia',
          'The highest possible green certificate BREEAM Outstanding',
        ],
      },
      rightAnswer: [2],
    },
    {
      question: {
        sk: '14. Ako sa volá budova na obrázku?',
        en: '14. What is the name of the building in the picture?',
      },
      options: {
        sk: [
          'Tepláreň v Bratislave',
          'Cvernovka v Bratislave',
          'Pradiareň v Bratislave',
        ],
        en: [
          'Tepláreň in Bratislava',
          'Cvernovka in Bratislava',
          'Pradiareň in Bratislava',
        ],
      },
      image: img3,
      rightAnswer: [2],
    },
    {
      question: {
        sk: <>15. Prečo patrí administratívne centrum v Šarjahu <br/> medzi najikonickejšie, digitálne podporované <br/> a udržateľné budovy na svete?</>,
        en: <>15. Why is the administrative centre in Sharjah <br/> one of the most iconic, digitally supported <br/> and sustainable buildings in the world?</>,
      },
      options: {
        sk: [
          'Pretože ho navrhla vychytená architektka Zaha Hadid',
          'Pretože je postavené v púšti',
          'Pretože je poháňané solárnou energiou, využíva najnovšie technológie zamerané na dosiahnutie nulovej bilancie emisií skleníkových plynov a spĺňa štandardy LEED Platinum',
        ],
        en: [
          'Because it was designed by the renowned architect Zaha Hadid',
          'Because it is built in the desert',
          'Because it is powered by solar energy, uses the latest technologies aimed at achieving zero greenhouse gas emissions and meets LEED Platinum standards',
        ],
      },
      rightAnswer: [2],
    },
  ]

  const settings = {
    dots: false,
    fade: true,
    infinite: false,
    speed: 500,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    accessibility: false,
    draggable: false,
    touchMove: false,
    initialSlide: 0,
    afterChange: setActiveSlide,
  }

  const onAnswer = (index, answer) => {
    setAnswers((prevState) => ({
      ...prevState,
      [index]: answer,
    }))

    const isCorrect = questions[index].rightAnswer.includes(answer)

    setUrl(isCorrect ? _correctVideo : _incorrectVideo)

    setIsPlaying(true)
    // isCorrect ? playCorrect() : playIncorrect()
  }

  useEffect(() => {
    if (Number.isInteger(answers[questions.length - 1])) {
      setIsLast(true)
    }
  }, [answers])

  const [url, setUrl] = useState(_correctVideo)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isLast, setIsLast] = useState(false)

  return (
    <SliderWrapper>
      <BackgroundWave
          background={bg}
          sx={{
            background: '#112232',
          }}
      />

      <ButtonMenu/>

      <Box
        sx={{
          width: '100%',
          height: '100%',
          '& > div': {
            width: '100% !important',
            height: '100% !important',
          },
          '& video': {
            objectFit: 'cover',
          },
          zIndex: '20',
          position: 'absolute',
          top: '0',
          left: '0',
          pointerEvents: 'none',
        }}
      >
        <ReactPlayer
          url={url}
          playing={isPlaying}
          onEnded={() => {
            if (isLast) {
              const correctAnswers = questions.filter((question, index) => {
                return question.rightAnswer.includes(answers[index])
              })

              setCorrectAnswers(correctAnswers.length)
              setAnswers({})
              setActiveSlide(0)
              setIsLast(false)
              setIsPlaying(false)
              sliderRef.current?.slickGoTo(0)
              goToNextSlide()
              return
            } else {
              sliderRef.current?.slickNext()
              setIsPlaying(false)
            }
          }}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      </Box>

      <Box
          sx={{
            height: '100%',
          }}
      >
        <SlickSlider ref={sliderRef} {...settings}>
          {questions.map((question, index) => (
            <QuizQuestion
              key={question.question[language]}
              index={index}
              question={question}
              onClick={onAnswer}
              language={language}
            />
          ))}
        </SlickSlider>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '0.625%',
          position: 'absolute',
          bottom: '5.5%',
          left: '0',
          width: '100%',
          flexWrap: 'wrap',
        }}
      >
        {questions.map((question, index) => (
          <Box
            key={question.question[language]}
            sx={{
              width: '2.6%',
              aspectRatio: '1/1',
              position: 'relative',
            }}
          >
            {index === activeSlide
                ? (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="none">
                      <circle cx="25" cy="25" r="25" fill="rgba(32, 97, 91, 0.8)"/>
                    </svg>
                )
                : Number.isInteger(answers[index])
                    ? question.rightAnswer.includes(answers[index])
                        ? (
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="none">
                              <circle cx="25" cy="25" r="25" fill="rgba(32, 97, 91, 0.8)"/>
                              <circle cx="25" cy="25" r="12.5" fill="#3AD3A4"/>
                            </svg>
                        )
                        : (
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="none">
                              <circle cx="25" cy="25" r="25" fill="rgba(32, 97, 91, 0.8)"/>
                              <path d="M34.9998 18.3331L31.6665 15L24.9994 21.6667L18.3335 15.0012L15.0002 18.3343L21.6661 24.9999L15 31.6657L18.3333 34.9988L24.9994 28.333L31.6667 35L35 31.6669L28.3327 24.9999L34.9998 18.3331Z" fill="#3AD3A4"/>
                            </svg>
                        )
                    : (
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="none">
                          <circle cx="25" cy="25" r="25" fill="rgba(32, 97, 91, 0.8)"/>
                        </svg>
                    )}
          </Box>
        ))}
      </Box>

      <Typography
          sx={{
            position: 'absolute',
            bottom: '7.5%',
            right: '30px',
            ...fonts.Rubik['24px']({
              color: '#3AD3A4',
              fontWeight: '600',
            }),
          }}
      >
        {activeSlide + 1} / {questions.length}
      </Typography>
    </SliderWrapper>
  )
}

export default Quiz
