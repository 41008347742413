import React from 'react'
import Box from '@mui/material/Box'
import InfoText from './InfoText'
import SliderWrapper from '../Slider/SliderWrapper'
import {useLanguageContext} from "../../contexts/LanguageContext";
import Button from "../../v2/components/Buttons/Button";
import BackgroundWave from "../../v2/components/BackgroundWave/BackgroundWave";
import background from "../../v2/assets/PROTOTYP/K00/K00-0.png";

const Info = ({ goToNextSlide }) => {
    const { setLanguage } = useLanguageContext()

  return (
    <SliderWrapper>
      <BackgroundWave
          disabled
          background={background}
      />

      <Box
        sx={{
          width: '100%',
          height: '100%',
          paddingTop: '20vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        }}
      >
        <InfoText />

          <Box
              sx={{
                  display: 'flex',
                  gap: '34px',
                  marginTop: '26px',
              }}
          >
              <Button
                  sx={{
                      minWidth: '250px',
                  }}
                  onClick={() => {
                      setLanguage('sk')
                      goToNextSlide()
                  }}
              >
                  Slovensky
              </Button>

              <Button
                  sx={{
                      minWidth: '250px',
                  }}
                  onClick={() => {
                      setLanguage('en')
                      goToNextSlide()
                  }}
              >
                  English
              </Button>
          </Box>
      </Box>
    </SliderWrapper>
  )
}

export default Info
