import React from 'react'
import Box from '@mui/material/Box'
import switchNavigation1 from '../../assets/_K1/DaA_K1-Strip_NavigationBase.png'
import switchEye1 from '../../assets/_K1/DaA_K1-Strip_Switcher-Eye.png'
import switchBase1 from '../../assets/_K1/DaA_K1-Strip_SwitcherBase.png'
import switchGlasses1 from '../../assets/_K1/DaA_K1-Strip_Switcher-Glasses.png'
import switchDot1 from '../../assets/_K1/DaA_K1-Strip_Switcher-Dot.png'
import switchNavigation4 from '../../assets/_K4/DaA_K4-Strip_NavigationBase.png'
import switchEye4 from '../../assets/_K4/DaA_K4-Strip_Switcher-Eye.png'
import switchBase4 from '../../assets/_K4/DaA_K4-Strip_SwitcherBase.png'
import switchGlasses4 from '../../assets/_K4/DaA_K4-Strip_Switcher-Glasses.png'
import switchDot4 from '../../assets/_K4/DaA_K4-Strip_Switcher-Dot.png'
import imageShadow from '../../styles/shadows'
import useSound from 'use-sound'
import hoverSound from '../../assets/sounds/1_Button_Hover_Sound.wav'
import clickSound from '../../assets/_Sfx/__Compress/Switcher.mp3'

const StripSwitch = ({ chapter, isSwitched, setIsSwitched }) => {
  const [playHover] = useSound(hoverSound, {
    interrupt: true,
  })
  const [playClick] = useSound(clickSound, {
    interrupt: true,
  })

  const switchNavigation = {
    chapter1: switchNavigation1,
    chapter4: switchNavigation4,
  }[chapter]

  const switchEye = {
    chapter1: switchEye1,
    chapter4: switchEye4,
  }[chapter]

  const switchBase = {
    chapter1: switchBase1,
    chapter4: switchBase4,
  }[chapter]

  const switchGlasses = {
    chapter1: switchGlasses1,
    chapter4: switchGlasses4,
  }[chapter]

  const switchDot = {
    chapter1: switchDot1,
    chapter4: switchDot4,
  }[chapter]

  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: '19',
        bottom: '40px',
        left: '50%',
        transform: 'translate(-50%, 0)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '10.46875%',
        cursor: 'pointer',
      }}
      onMouseEnter={playHover}
      onClick={() => {
        playClick()
        setIsSwitched(!isSwitched)
      }}
    >
      <Box
        component={'img'}
        src={switchNavigation}
        sx={{
          width: '100%',
          // maxHeight: '5.64814814814815vh',
          objectFit: 'contain',
          ...imageShadow,
        }}
      />

      <Box
        component={'img'}
        src={switchEye}
        sx={{
          position: 'absolute',
          left: '6%',
          width: '25.37313432835821%',
          // maxHeight: '2.12962962962963vh',
          objectFit: 'contain',
        }}
      />

      <Box
        component={'img'}
        src={switchBase}
        sx={{
          position: 'absolute',
          left: '31%',
          width: '35.32338308457711%',
          // maxHeight: '3.24074074074074vh',
          objectFit: 'contain',
        }}
      />

      <Box
        component={'img'}
        src={switchGlasses}
        sx={{
          position: 'absolute',
          left: '67%',
          width: '25.37313432835821%',
          // maxHeight: '2.12962962962963vh',
          objectFit: 'contain',
        }}
      />

      <Box
        component={'img'}
        src={switchDot}
        sx={{
          position: 'absolute',
          left: isSwitched ? '52%' : '34%',
          transition: 'left 500ms ease 0s',
          width: '11.44278606965174%',
          // maxHeight: '2.12962962962963vh',
          objectFit: 'contain',
        }}
      />
    </Box>
  )
}

export default StripSwitch
