import React from 'react'
import Animated from "../v2/components/Animated/Animated";
import ChapterTitle from "../v2/components/ChapterTitle/ChapterTitle";
import backgroundk02 from "../v2/assets/PROTOTYP/K02/K02-0.png";
import animation1 from '../v2/assets/PROTOTYP/K02/K02-1/k02-1.mp4'
import animation2 from '../v2/assets/PROTOTYP/K02/K02-2/k02-2.mp4'
import animation3 from '../v2/assets/PROTOTYP/K02/K02-3/K02-3.mp4'
import animation4 from '../v2/assets/PROTOTYP/K02/K02-4/K02-4.mp4'
import voiceoverK02_1SK from '../v2/assets/VOICEOVER/VOICEOVER_SK/K02_1 .mp3'
import voiceoverK02_1EN from '../v2/assets/VOICEOVER/VOICEOVER_EN/K02_1.mp3'
import voiceoverK02_2SK from '../v2/assets/VOICEOVER/VOICEOVER_SK/K02_2..mp3'
import voiceoverK02_2EN from '../v2/assets/VOICEOVER/VOICEOVER_EN/K02_2.mp3'
import voiceoverK02_3SK from '../v2/assets/VOICEOVER/VOICEOVER_SK/K02_3..mp3'
import voiceoverK02_3EN from '../v2/assets/VOICEOVER/VOICEOVER_EN/K02_3.mp3'
import voiceoverK02_4SK from '../v2/assets/VOICEOVER/VOICEOVER_SK/K02_4.mp3'
import voiceoverK02_4EN from '../v2/assets/VOICEOVER/VOICEOVER_EN/K02_4.mp3'

const title1 = {
  sk: 'čo je energetická kríza a prečo vznikla',
  en: 'What is the energy crisis and why did it occur',
}

const textImage1 = {
  sk: (
      <>
        Energetická kríza v Európe vr. Slovenska znamená, že krajiny EÚ <b>nemajú dostatok vlastných zdrojov energie</b> na
        pokrytie svojich potrieb a zároveň faktom je aj to, že európsky trh čelí <b>veľkému nárastu cien plynu</b> z Ruska,
        ako dôsledok vojny na Ukrajine.
        <br/><br/>
        Je preto potrebné nielen nájsť nových dodávateľov a nebyť závislí len na dodávkach energií z Ruska,
        ale najmä <b>znižovať energetickú náročnosť budov.</b>
      </>
  ),
  en: (
      <>
          The energy crisis in Europe incl. Slovakia means that EU countries do not <b>have sufficient own energy resources</b> to cover their needs, and it is also a fact that the European market is facing a large <b>increase in gas prices</b> from Russia as a result of the war in Ukraine.
          <br/><br/>
          It is therefore necessary not only to find new suppliers and not to depend solely on energy supplies from Russia, but, above all, to <b>reduce the energy intensity of buildings.</b>
      </>
  )
}

const textImage2 = {
  sk: (
      <>
        Budovy sa totiž podieľajú vyše 40 % na celkovej spotrebe energií na Slovensku. V
        budovách sa <b>kúri, svieti, chladí</b> a využívajú sa v nich aj ďalšie zariadenia, ktoré potrebujú
        elektrickú energiu <b>/počítače, chladničky, práčky, TV, atď./.</b>
      </>
  ),
  en: (
      <>
          Namely, buildings constitute more than 40% of total energy consumption in Slovakia. This energy is used for <b>heating, lighting, cooling</b> and powering other appliances requiring electricity <b>(computers, fridges, washing machines, TVs, etc.).</b>
      </>
  )
}

const textImage3 = {
  sk: (
      <>
        A mnohé domácnosti využívajú na kúrenie fosilné palivá,
        teda <b>neobnoviteľné zdroje energie akými sú napr. uhlie a ropa</b>, namiesto
        využívania <b>obnoviteľných zdrojov energie, akými sú slnko, voda a vietor.</b>
        <br/><br/>
        Zároveň <b>budovy sú</b> popri doprave a
        priemysle <b>najväčším zdrojom uvoľňovania emisií skleníkových plynov</b> do ovzdušia, v dôsledku čoho sa
        otepľuje planéta a mení sa klíma.
      </>
  ),
  en: (
      <>
          And many households use fossil fuels for heating, i.e., <b>non-renewable energy sources such as coal and oil,</b> instead of using <b>renewable energy sources such as sun, water and wind.</b>
          <br/><br/>
          At the same time, alongside transport and industry, <b>buildings are the largest source of greenhouse gas emissions</b> into the air, which are warming the planet and changing the climate.
      </>
  )
}

const textImage4 = {
  sk: (
      <>
        Emisie skleníkových plynov sa dostávajú do ovzdušia pri výstavbe a prevádzke budov, najmä z vykurovania,
        ochladzovania a svietenia budov z neobnoviteľných zdrojov energie.
        Pritom by sa dali v oveľa väčšej miere pri výstavbe a prevádzke budov
        využívať obnoviteľné zdroje energie ako sú <b>slnko, voda alebo vietor.</b>
      </>
  ),
  en: (
      <>
          Greenhouse gas emissions are released into the air during the construction and operation of buildings, mainly from heating, cooling and lighting of buildings from non-renewable energy sources. Renewables such as <b>sun, water or wind</b> could be used to a much greater extent in the construction and operation of buildings.
      </>
  )
}

const chapter2 = {
  url: '/chapter-2',
  name: 'chapter2',
  slides: [
    {
      Component: ChapterTitle,
      props: {
        title: {
            sk: <>Čo je energetická kríza <br/> a prečo vznikla</>,
            en: <>What is the energy crisis <br/> and why did it occur</>,
        },
        background: backgroundk02,
      },
    },
    {
      Component: Animated,
      props: {
        placement: 'right',
        animation: animation1,
        title: title1,
        voiceover: {
          sk: voiceoverK02_1SK,
          en: voiceoverK02_1EN,
        },
        text: textImage1,
      },
    },
    {
      Component: Animated,
      props: {
        placement: 'right',
        animation: animation2,
        title: title1,
        voiceover: {
            sk: voiceoverK02_2SK,
            en: voiceoverK02_2EN,
        },
        text: textImage2,
      },
    },
    {
      Component: Animated,
      props: {
        placement: 'left',
        animation: animation3,
        title: title1,
        voiceover: {
            sk: voiceoverK02_3SK,
            en: voiceoverK02_3EN,
        },
        text: textImage3,
      },
    },
    {
      Component: Animated,
      props: {
        placement: 'right',
        animation: animation4,
        title: title1,
        voiceover: {
            sk: voiceoverK02_4SK,
            en: voiceoverK02_4EN,
        },
        text: textImage4,
      },
    },
  ],
}

export default chapter2
