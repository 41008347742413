import React, { useEffect, useRef } from 'react'
import Box from '@mui/material/Box'
import useSound from 'use-sound'
import hoverSound from '../../../assets/sounds/1_Button_Hover_Sound.wav'
import clickSound from '../../../assets/sounds/2_Ikon_Click.wav'

const ButtonPause = ({ variant = 0, sx, onClick }) => {
  const [playHover] = useSound(hoverSound, {
    interrupt: true,
  })
  const [playClick] = useSound(clickSound, {
    interrupt: true,
  })

  const isSoundActive = useRef(false)

  useEffect(() => {
    isSoundActive.current = false

    setTimeout(() => {
      isSoundActive.current = true
    }, 200)
  })

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '3%',
        right: '1.5%',
        cursor: 'pointer',
        zIndex: '1',
          height: '7.4vh',
          aspectRatio: '1/1',
        ...sx,
      }}
      onMouseEnter={() => {
        isSoundActive.current && playHover()
      }}
      onClick={(e) => {
        playClick()
        onClick(e)
      }}
    >
        {variant === 0 ? (
            <Box
                component={'svg'}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 70 70"
                fill="none"
                sx={{
                    '& circle': {
                        transition: '0.3s ease-out',
                    },
                    '& path': {
                        transition: '0.3s ease-out',
                    },
                    '&:hover': {
                        '& circle': {
                            fill: '#3AD3A4',
                        },
                        '& path': {
                            fill: '#112232',
                        },
                    },
                }}
            >
                <circle cx="35" cy="35" r="35" fill="#112232"/>

                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M34.9834 20.5329C35.4809 20.7603 35.8 21.2571 35.8 21.8041V49.1917C35.8 49.7388 35.4809 50.2355 34.9834 50.463C34.4859 50.6905 33.9014 50.6068 33.4877 50.2489L24.1523 42.1739H15.7328C14.9608 42.1739 14.335 41.548 14.335 40.776V30.1002C14.335 29.3282 14.9608 28.7023 15.7328 28.7023H24.2899L33.4877 20.7469C33.9015 20.389 34.4859 20.3054 34.9834 20.5329ZM33.0043 24.8614L25.725 31.1575C25.471 31.3772 25.1464 31.4981 24.8106 31.4981H17.1307V39.3781H24.673C25.0089 39.3781 25.3335 39.499 25.5875 39.7188L33.0043 46.1343V24.8614Z"
                    fill="#3AD3A4"
                />
            </Box>
        ) : (
            <Box
                component={'svg'}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 70 70"
                fill="none"
                sx={{
                    '& circle': {
                        transition: '0.3s ease-out',
                    },
                    '&:hover': {
                        '& circle': {
                            fill: '#fff',
                        },
                    },
                }}
            >
                <circle cx="35" cy="35" r="35" fill="#3AD3A4"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M31 20H27V51H31V20ZM44 20H40V51H44V20Z" fill="#112232"/>
            </Box>
        )}
    </Box>
  )
}

export default ButtonPause
