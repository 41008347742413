import React from 'react'
import ChapterTitle from "../v2/components/ChapterTitle/ChapterTitle";
import backgroundk02 from "../v2/assets/PROTOTYP/K06/K06-0.png";
import Video from "../components/Video/Video";
import video1SK from "../v2/assets/PROTOTYP/K06/K06-1.mp4";
import video1EN from "../v2/assets/PROTOTYP/K06/ENG K06 circular economy gg.mp4";

const chapter6 = {
  url: '/chapter-6',
  name: 'chapter6',
  slides: [
    {
      Component: ChapterTitle,
      props: {
        title: {
          sk: <>CIRKULárna ekonomika</>,
          en: <>Circular economy</>,
        },
        background: backgroundk02,
      },
    },
    {
      Component: Video,
      props: {
        video: {
          sk: video1SK,
          en: video1EN,
        },
      },
    },
  ],
}

export default chapter6
