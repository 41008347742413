import React from 'react'
import Box from '@mui/material/Box'
import SliderWrapper from '../../../components/Slider/SliderWrapper'
import Typography from '@mui/material/Typography'
import fonts from '../../../styles/fonts'
import background from "../../assets/PROTOTYP/K00/K00-3.png";
import BackgroundWave from "../BackgroundWave/BackgroundWave";
import ButtonLeft from "../Buttons/ButtonLeft";
import ButtonRight from "../Buttons/ButtonRight";

const textSK = (
    <>
        Milí žiaci, milí učitelia a rodičia,

        <br/><br/>

        aj tento rok prichádzame k Vám s novým – už <b>10. jubilejným ročníkom</b> projektu DETI A ARCHITEKTÚRA, ktorého neoddeliteľnou súčasťou je vždy aj kreatívny
        e-kurz. Tento rok je na tému <b>Zelená architektúra, zelené stavebníctvo</b>. Zelenej architektúre sme sa venovali už v e-kurze v roku 2018, ktorý si stále môžete pozrieť tu: <b><a
        href="http://kurz2018.detiaarchitektura.sk." target={'_blank'}>http://kurz2018.detiaarchitektura.sk.</a></b>

        <br/><br/>

        My v tohoročnom e-kurze nadviažeme na informácie z tohto e-kurzu a posunieme tému ďalej, smerom ktorým sa téma zelených budov a zeleného stavebníctva posunula za uplynulých 5 rokov. Tohoročný e-kurz má 4 časti a nechýba ani vedomostný kvíz a výtvarná súťaž na tému „Zelená mestská štvrť“. Podrobnejšie info k výtvarnej súťaži sú uvedené na záver e-kurzu.

        <br/><br/>

        Prajeme Vám veľa poučenia, ale aj zábavy a veríme, že po absolvovaní e-kurzu sa stanete aj vy fanúšikmi zelenej architektúry a zeleného stavebníctva a že sa aj <b>zapojíte do výtvarnej súťaže.</b>
    </>
)

const textEN = (
    <>
        Dear pupils, dear teachers and parents,

        <br/><br/>

        Also, this year we are coming to you with a new - already the <b>10th anniversary year of the project CHILDREN AND ARCHITECTURE,</b> which always includes a creative e-course as an integral part. This year's theme is <b>Green Architecture, Green Construction Sector.</b> We already covered green architecture in the 2018 e-course, which you can still view here: <a href="http://kurz2018.detiaarchitektura.sk" target={'_blank'}>http://kurz2018.detiaarchitektura.sk.</a>

        <br/><br/>

        In this year's e-course, we will build on the information from that e-course taking the topic further – where has the theme of green buildings and green construction moved over the past 5 years. This year's e-course has 4 parts including a knowledge quiz and an art competition on the "Green Urban Neighbourhood" topic. More details on the art competition are specified at the end of the e-course.

        <br/><br/>

        We wish for you to learn much while having fun and we hope that after completing the e-course you will also become a fan of green architecture and green building and that you will <b>participate in the art competition.</b>
    </>
)

const Intro = ({ language, goToPrevSlide, goToNextSlide }) => {
    const text = {
        sk: textSK,
        en: textEN,
    }[language]

  return (
    <SliderWrapper flexCenter>
        <BackgroundWave
            background={background}
        />

        <ButtonLeft
            isShown
            onClick={goToPrevSlide}
        />

        <ButtonRight
            isShown
            onClick={goToNextSlide}
        />

      <Box
          sx={{
              borderRadius: '50px',
              background: 'rgba(58, 211, 164, 0.2)',
              width: '73%',
              position: 'relative',
              padding: '5%',
              border: '2px solid rgba(58, 211, 164, 0.2)',
          }}
      >
          <Typography
              sx={{
                  ...fonts.Rubik['30px']({
                      color: '#3AD3A4',
                  }),
                  '& b, & a': {
                      color: '#E8AEBA',
                      fontWeight: '700',
                  },
              }}
          >
              {text}
          </Typography>
      </Box>
    </SliderWrapper>
  )
}

export default Intro
