import React from 'react'
import ReactPlayer from 'react-player'
import Box from '@mui/material/Box'

const VideoPlayer = ({ video, setIsShownPreview, isPLaying, setIsPlaying }) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        '& > div': {
          width: '100% !important',
          height: '100% !important',
        },
        '& video': {
          objectFit: 'cover',
        },
      }}
    >
      <ReactPlayer
        url={video}
        playing={isPLaying}
        onEnded={() => {
          setIsPlaying(false)
          setIsShownPreview(true)
        }}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
    </Box>
  )
}

export default VideoPlayer
