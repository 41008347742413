import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import fonts from '../../../styles/fonts'

const Header = ({ text, sx, textSx, }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: '19',
        top: '3%',
        left: '50%',
        transform: 'translate(-50%, 0)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
          padding: '2.2vh 3.125vw',
          background: '#112232',
          borderRadius: '500px',
          minWidth: '38%',
          ...sx,
      }}
    >
      <Typography
        sx={{
          ...fonts.Rubik['24px']({
              lineHeight: 32,
              color: '#3AD3A4',
              fontWeight: '700',
          }),
          textAlign: 'center',
            textTransform: 'uppercase',
            whiteSpace: 'nowrap',
            ...textSx,
        }}
      >
        {text}
      </Typography>
    </Box>
  )
}

export default Header
