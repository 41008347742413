import React, { useEffect, useRef, useState } from 'react'
import SliderWrapper from '../Slider/SliderWrapper'
import StripGlasses from './StripGlasses'
import StripSwitch from './StripSwitch'
import Box from '@mui/material/Box'
import StripImages from './StripImages'
import StripTriggers from './StripTriggers'
import Modal from '../Modal/Modal'
import ModalWrapper from '../Modal/ModalWrapper'
import BackgroundChapter from '../Background/BackgroundChapter'
import useSound from 'use-sound'
import stripesSound from '../../assets/_Sfx/__Compress/Stripes.mp3'
import magicSound from '../../assets/_Sfx/__Compress/Magic.mp3'
import ButtonLeft from "../../v2/components/Buttons/ButtonLeft";
import ButtonRight from "../../v2/components/Buttons/ButtonRight";
import ButtonMenu from "../../v2/components/Buttons/ButtonMenu";
import Header from "../../v2/components/Header/Header";
import ReactPlayer from "react-player";
import AdditionalStudy from "../../v2/components/AdditionalStudy/AdditionalStudy";

const Strip = ({
  isActive,
  language,
  images,
  triggers,
    animations,
  chapter,
  goToPrevSlide,
  goToNextSlide,
  scrollHandler,
  title,
                   additionalStudy,
}) => {
  const [isSwitched, setIsSwitched] = useState(false)
  const [isShownModal, setIsShownModal] = useState(false)
  const [text, setText] = useState()
  const parentRef = useRef()
  const buttonLeftRef = useRef()
  const buttonRightRef = useRef()
  const cloudsRef = useRef()
  const parallax1 = useRef()
  const parallax2 = useRef()
  const modalRef = useRef()

  const [isLoaded, setIsLoaded] = useState(false)
  const [playVoiceover, { stop }] = useSound(stripesSound, {
    interrupt: true,
    onload: () => setIsLoaded(true),
    volume: 0.1,
  })

  const [playMagic, { stop: stopMagic }] = useSound(magicSound, {
    interrupt: true,
    volume: 0.1,
  })

  const onPlay = () => {
    playVoiceover()
  }

  const onStop = () => {
    stop()
    stopMagic()

    modalRef.current?.onStop()
  }

  useEffect(() => {
    if (isLoaded) {
      if (isActive) {
        onPlay()

        setIsShownModal(false)

        if (isSwitched) {
          playMagic()
        }
      } else {
        onStop()
        stopMagic()
      }
    }

    return () => onStop()
  }, [isLoaded, isActive])

  useEffect(() => {
    onScroll()
  }, [title, language])

  useEffect(() => {
    isSwitched ? playMagic() : stopMagic()
  }, [isSwitched])

  const shouldScroll = useRef(false)
    const isActiveScroll = useRef(false)

  const scrollLeft = () => {
      if (!shouldScroll.current) {
          return
      }

      parentRef.current.scrollBy({
          left: -7
      })

      requestAnimationFrame(scrollLeft)
  }

    const scrollRight = () => {
      if (!shouldScroll.current) {
          return
      }

        parentRef.current.scrollBy({
            left: 7
        })

        requestAnimationFrame(scrollRight)
    }

  const addListeners = () => {
    buttonLeftRef.current.addEventListener('mouseover', () => {
        if (isActiveScroll.current) {
            return
        }

        isActiveScroll.current = true
        shouldScroll.current = true
        scrollLeft()
    })
    buttonRightRef.current.addEventListener('mouseover', () => {
        if (isActiveScroll.current) {
            return
        }

        isActiveScroll.current = true
        shouldScroll.current = true
        scrollRight()
    })

    buttonLeftRef.current.addEventListener('mouseleave', () => {
        isActiveScroll.current = false
        shouldScroll.current = false
    })
    buttonRightRef.current.addEventListener('mouseleave', () => {
        isActiveScroll.current = false
        shouldScroll.current = false
    })
  }

  useEffect(() => {
    addListeners()
    onScroll()
  }, [])

  const onScroll = () => {
    const percentage =
      (parentRef.current.scrollLeft /
        (parentRef.current.scrollWidth - parentRef.current.offsetWidth)) *
      100

    if (cloudsRef.current) {
      cloudsRef.current.style.transform = `translateX(-${percentage * 1.5}px)`
    }

    if (parallax1.current) {
      parallax1.current.style.transform = `translateX(-${percentage * 1.5}px)`
    }

    if (parallax2.current) {
      parallax2.current.style.transform = `translateX(-${percentage * 1.5}px)`
    }

    if (scrollHandler) {
      return setText(scrollHandler(percentage, language))
    }
  }

  const onButtonLeftClick = () => {
    const percentage =
      (parentRef.current.scrollLeft /
        (parentRef.current.scrollWidth - parentRef.current.offsetWidth)) *
      100

    if (percentage < 10) {
      return goToPrevSlide()
    }
  }

  const onButtonRightClick = () => {
    const percentage =
      (parentRef.current.scrollLeft /
        (parentRef.current.scrollWidth - parentRef.current.offsetWidth)) *
      100

    if (percentage > 90) {
      return goToNextSlide()
    }
  }

  return (
    <SliderWrapper flexCenter>
      <ButtonMenu
          variant={1}
        sx={{
          zIndex: '20',
        }}
      />

      <ButtonLeft
          variant={1}
        buttonRef={buttonLeftRef}
        isShown
        sx={{
          zIndex: '20',
        }}
        onClick={onButtonLeftClick}
      />

      <ButtonRight
          variant={1}
        buttonRef={buttonRightRef}
        isShown
        sx={{
          zIndex: '20',
        }}
        onClick={onButtonRightClick}
      />

      <Header
          text={text}
          sx={{
              minWidth: 'unset',
              opacity: !Boolean(isShownModal) ? '1' : '0',
              pointerEvents: Boolean(isShownModal) ? 'none' : 'all',
              transition: 'opacity 500ms ease 0s',
          }}
      />

      <StripGlasses isSwitched={isSwitched} />

      <StripSwitch
        chapter={chapter}
        isSwitched={isSwitched}
        setIsSwitched={setIsSwitched}
      />

      <ModalWrapper isShownModal={isShownModal}>
          <Box
              sx={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  width: '100%',
                  height: '100%',
                  background: '#112232',
                  opacity: '0.9',
              }}
          />

        {Boolean(isShownModal) && (
          <Modal
            key={isShownModal.id}
            modalRef={modalRef}
            language={language}
            chapter={chapter}
            isShownModal={isShownModal}
            setIsShownModal={setIsShownModal}
            text={text}
          />
        )}
      </ModalWrapper>

      <Box
        ref={parentRef}
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'auto',
          scrollbarWidth: 'none',
          position: 'relative',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
        onScroll={onScroll}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'inline-block',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <StripImages
            isSwitched={isSwitched}
            images={images}
            cloudsRef={cloudsRef}
            parallax1={parallax1}
            parallax2={parallax2}
          />

            {animations && animations.map(animation => (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        height: '100%',
                        zIndex: '4',
                        ...animation.sx,
                    }}
                >
                    <ReactPlayer
                        playing
                        loop
                        muted
                        url={animation.animation}
                        width={'auto'}
                        height={'100%'}
                    />
                </Box>
            ))}

          <StripTriggers
            chapter={chapter}
            triggers={triggers}
            setIsShownModal={setIsShownModal}
          />
        </Box>
      </Box>

        {additionalStudy && <AdditionalStudy additionalStudy={additionalStudy} language={language} />}
    </SliderWrapper>
  )
}

export default Strip
