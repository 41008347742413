import React from 'react'
import Box from '@mui/material/Box'
import useSound from 'use-sound'
import hoverSound from '../../assets/sounds/1_Button_Hover_Sound.wav'
import clickSound from '../../assets/_Sfx/__Compress/Switcher.mp3'

const StripSwitch = ({ isSwitched, setIsSwitched }) => {
  const [playHover] = useSound(hoverSound, {
    interrupt: true,
  })
  const [playClick] = useSound(clickSound, {
    interrupt: true,
  })

  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: '19',
        bottom: '3%',
        left: '1.5%',
        cursor: 'pointer',
          aspectRatio: '2/1',
          height: '5vh',
      }}
      onMouseEnter={playHover}
      onClick={() => {
        playClick()
        setIsSwitched(!isSwitched)
      }}
    >
        <Box
            component={'svg'}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 140 70"
            fill="none"
            sx={{
                '& circle': {
                    transition: '0.3s ease-out',
                },
            }}
        >
            <rect x="4.5" y="4.5" width="131" height="61" rx="30.5" fill="#112232" stroke="#3AD3A4" strokeWidth="9"/>
            <circle cx={isSwitched ? 104 : 36} cy="35" r="19" fill="#3AD3A4"/>
        </Box>
    </Box>
  )
}

export default StripSwitch
