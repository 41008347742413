import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import ModalContent from './ModalContent'
import ModalNavigation from './ModalNavigation'
import ModalArrowLeft from './ModalArrowLeft'
import ModalArrowRight from './ModalArrowRight'
import ModalButtons from './ModalButtons'
import ModalHeader from './ModalHeader'
import ModalFrame from './ModalFrame'
import { fadeIn } from '../../styles/animations'
import useSound from 'use-sound'
import Typography from '@mui/material/Typography'
import fonts from '../../styles/fonts'
import modalImg from '../../v2/assets/PROTOTYP/UI/modal.png'
import Header from "../../v2/components/Header/Header";
import ButtonClose from "../Buttons/ButtonClose";
import hoverSound from "../../assets/sounds/1_Button_Hover_Sound.wav";
import clickSound from "../../assets/sounds/2_Ikon_Click.wav";
import ButtonPlay from "../../v2/components/Buttons/ButtonPlay";
import ButtonPause from "../../v2/components/Buttons/ButtonPause";
import ButtonRead from "../Buttons/ButtonRead";
import Source from "../../v2/components/Source";

const Modal = ({
  modalRef,
  textOnly,
  language,
  isShownModal,
  setIsShownModal,
  chapter,
  customPause,
  customIsPlaying,
  customPlay,
                   text,
}) => {
  const sliderRef = useRef()
  const [isLoaded, setIsLoaded] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [playVoiceover, { stop, pause }] = useSound(
    isShownModal.voiceover?.[language],
    {
      onload: () => setIsLoaded(true),
    }
  )

  const [isShownText, setIsShownText] = useState(textOnly)
  const [activeSlide, setActiveSlide] = useState(0)

    const [playHover] = useSound(hoverSound, {
        interrupt: true,
    })
    const [playClick] = useSound(clickSound, {
        interrupt: true,
    })

  const onPlay = () => {
    setIsPlaying(true)
    playVoiceover()
  }

  const onPause = () => {
    setIsPlaying(false)
    pause()
  }

  const onStop = () => {
    setIsPlaying(false)
    stop()
  }

  useEffect(() => {
    if (isLoaded && !textOnly) {
      onPlay()
    }
  }, [isLoaded])

  if (modalRef) {
    modalRef.current = { onStop }
  }

    const close = () => {
        if (textOnly) {
            return setIsShownModal(false)
        }

        onStop()
        setIsShownModal(false)
    }

  return (
    <Box
      sx={{
        position: 'relative',
        opacity: '0',
        transition: 'opacity 500ms ease 0s',
        animation: `${fadeIn} 500ms ease forwards`,
        width: '83.3%',
        height: '83.3%',
      }}
    >
      <Box
          sx={{
            borderRadius: '40px',
            boxShadow: '0px 0px 30px 0px rgba(17, 34, 50, 0.50)',
            background: 'linear-gradient(180deg, #288673 43.23%, #173D43 84.9%)',
            position: 'relative',
            padding: '90px',
            width: '100%',
            height: '100%',
          }}
      >
          <Header
              text={isShownModal.title[language]}
              sx={{
                  top: '10px',
                  minWidth: 'unset',
                  height: '70px',
                  padding: '24px 3.75%',
                  maxWidth: 'calc(100% - 524px)',
              }}
              textSx={{
                  whiteSpace: 'unset',
              }}
          />

          <ButtonClose
              sx={{
                  top: '15px',
                  right: '15px',
                  width: '60px',
                  height: '60px',
              }}
              onClick={close}
          />

          <ButtonPlay
              variant={1}
              sx={{
                  opacity: !isPlaying ? '1' : '0',
                  pointerEvents: isPlaying ? 'none' : 'all',
                  transition: 'opacity 500ms ease 0s',
                  top: '15px',
                  right: '90px',
                  width: '60px',
                  height: '60px',
              }}
              onClick={onPlay}
          />

          <ButtonPause
              variant={1}
              sx={{
                  opacity: isPlaying ? '1' : '0',
                  pointerEvents: !isPlaying ? 'none' : 'all',
                  transition: 'opacity 500ms ease 0s',
                  top: '15px',
                  right: '90px',
                  width: '60px',
                  height: '60px',
              }}
              onClick={onPause}
          />

          <ButtonRead
              variant={1}
              sx={{
                  transition: 'opacity 500ms ease 0s',
                  top: '15px',
                  right: '165px',
                  width: '60px',
                  height: '60px',
              }}
              onClick={() => setIsShownText(!isShownText)}
          />

          <Box
              sx={{
                  position: 'absolute',
                  left: '50%',
                  translate: '-50% 0',
                  bottom: '32.5px',
                  display: 'flex',
                  gap: '15px',
              }}
          >
              {isShownModal.photos && isShownModal.photos.map((photo, index) => (
                  <Box
                      key={photo}
                      sx={{
                          width: '25px',
                          height: '25px',
                          cursor: 'pointer',
                          background: index === activeSlide ? 'radial-gradient(50% 50% at 50% 50%, #3AD3A4 0%, #2B927A 100%)' : '#112232',
                          borderRadius: '50%',
                          transition: '0.3s',
                      }}
                      onMouseEnter={playHover}
                      onClick={(e) => {
                          playClick()
                          sliderRef.current?.slickGoTo(index)
                      }}
                  />
              ))}
          </Box>

          <ModalContent
            chapter={chapter}
            sliderRef={sliderRef}
            isShownModal={isShownModal}
            isShownText={isShownText}
            setActiveSlide={setActiveSlide}
            language={language}
          />
      </Box>

      {/*<ModalFrame chapter={chapter} />*/}

      {/*<ModalHeader*/}
      {/*  chapter={chapter}*/}
      {/*  isShownModal={isShownModal}*/}
      {/*  language={language}*/}
      {/*/>*/}

      {/*<ModalButtons*/}
      {/*  chapter={chapter}*/}
      {/*  setIsShownModal={setIsShownModal}*/}
      {/*  isShownText={isShownText}*/}
      {/*  setIsShownText={setIsShownText}*/}
      {/*  isPlaying={isPlaying}*/}
      {/*  playVoiceover={onPlay}*/}
      {/*  pauseVoiceover={onPause}*/}
      {/*  stopVoiceover={onStop}*/}
      {/*  customPause={customPause}*/}
      {/*  customPlay={customPlay}*/}
      {/*  textOnly={textOnly}*/}
      {/*  customIsPlaying={customIsPlaying}*/}
      {/*/>*/}

      {/*{!textOnly && (*/}
      {/*  <>*/}
      {/*    <ModalArrowLeft chapter={chapter} sliderRef={sliderRef} />*/}

      {/*    <ModalArrowRight chapter={chapter} sliderRef={sliderRef} />*/}
      {/*  </>*/}
      {/*)}*/}

      {/*{!textOnly && (*/}
      {/*  <ModalNavigation*/}
      {/*    sliderRef={sliderRef}*/}
      {/*    chapter={chapter}*/}
      {/*    isShownModal={isShownModal}*/}
      {/*    activeSlide={activeSlide}*/}
      {/*  />*/}
      {/*)}*/}

      {/*<ModalContent*/}
      {/*  chapter={chapter}*/}
      {/*  sliderRef={sliderRef}*/}
      {/*  isShownModal={isShownModal}*/}
      {/*  isShownText={isShownText}*/}
      {/*  setActiveSlide={setActiveSlide}*/}
      {/*  language={language}*/}
      {/*/>*/}

      {isShownModal.source && (
        <Typography
          sx={{
            position: 'fixed',
            left: '20px',
            bottom: '11px',
            opacity: '0.5',
            ...fonts.Inter['20px'],
          }}
        >
          {isShownModal.source}
        </Typography>
      )}

        {isShownModal.sources && isShownModal.sources[activeSlide] && <Source sx={{ opacity: '0.5' }}>{isShownModal.sources[activeSlide]}</Source>}
    </Box>
  )
}

export default Modal
