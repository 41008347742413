import React from 'react'
import ChapterStart from "../v2/components/ChapterStart/ChapterStart";
import Video from "../components/Video/Video";
import video1SK from "../v2/assets/PROTOTYP/K14/K14_01_1.mp4";
import video1EN from "../v2/assets/PROTOTYP/K14/K14 eng gg.mp4";
import Instructions from "../components/Instructions/Instructions";
import Strip2 from "../components/Strip2/Strip2";

import img2bg from '../v2/assets/PROTOTYP/K14/K14-2/K14-2.jpg'
import img2bgSwitch from '../v2/assets/PROTOTYP/K14/K14-2/K14-2 Switch.jpg'

import img3Abg from '../v2/assets/PROTOTYP/K14/K14-3A/K14-3A.jpg'
import img3AbgSwitch from '../v2/assets/PROTOTYP/K14/K14-3A/K14-3A-Switch.jpg'

import img3Bbg from '../v2/assets/PROTOTYP/K14/K14-3B/K14-3B.jpg'
import img3BbgSwitch from '../v2/assets/PROTOTYP/K14/K14-3B/K14-3B-Switch.jpg'

import img4bg from '../v2/assets/PROTOTYP/K14/K14-4/K14-4.jpg'
import img4bgSwitch from '../v2/assets/PROTOTYP/K14/K14-4/K14-4 Switch.jpg'

import img5bg from '../v2/assets/PROTOTYP/K14/K14-5/K14-5.jpg'
import img5bgSwitch from '../v2/assets/PROTOTYP/K14/K14-5/K14-5 Switch.jpg'

import img6bg from '../v2/assets/PROTOTYP/K14/K14-6/K14-6.jpg'
import img6bgSwitch from '../v2/assets/PROTOTYP/K14/K14-6/K14-6 Switch.jpg'

import img7bg from '../v2/assets/PROTOTYP/K14/K14-7/K14-7.jpg'
import img7bgSwitch from '../v2/assets/PROTOTYP/K14/K14-7/K14-7 Switch.jpg'

import img2trigger1img1 from '../v2/assets/PROTOTYP/K14/K14-2/K14-2-TRIGGER 1.jpg'
import img2trigger2img1 from '../v2/assets/PROTOTYP/K14/K14-2/K14-2-TRIGGER 2.jpg'
import img2trigger3img1 from '../v2/assets/PROTOTYP/K14/K14-2/K14-2-TRIGGER 3.jpg'

import img3Atrigger1img1 from '../v2/assets/PROTOTYP/K14/K14-3A/K14-3A-TRIGGER 1.jpg'
import img3Atrigger2img1 from '../v2/assets/PROTOTYP/K14/K14-3A/K14-3A-TRIGGER 2.jpg'
import img3Atrigger3img1 from '../v2/assets/PROTOTYP/K14/K14-3A/K14-3A-TRIGGER 3.jpg'

import img3Btrigger1img1 from '../v2/assets/PROTOTYP/K14/K14-3B/K14-3B-TRIGGER-1.jpg'
import img3Btrigger2img1 from '../v2/assets/PROTOTYP/K14/K14-3B/K14-3B-TRIGGER 2.jpg'
import img3Btrigger3img1 from '../v2/assets/PROTOTYP/K14/K14-3B/K14-3B-TRIGGER 3.jpg'

import img4trigger1img1 from '../v2/assets/PROTOTYP/K14/K14-4/K14-4-TRIGGER 1.jpg'
import img4trigger2img1 from '../v2/assets/PROTOTYP/K14/K14-4/K14-4-TRIGGER 2.jpg'
import img4trigger3img1 from '../v2/assets/PROTOTYP/K14/K14-4/K14-4-TRIGGER 3.jpg'

import img5trigger1img1 from '../v2/assets/PROTOTYP/K14/K14-5/K14-5-TRIGGER 1.jpg'
import img5trigger2img1 from '../v2/assets/PROTOTYP/K14/K14-5/K14-5-TRIGGER 2.jpg'
import img5trigger3img1 from '../v2/assets/PROTOTYP/K14/K14-5/K14-5-TRIGGER 3.jpg'

import img6trigger1img1 from '../v2/assets/PROTOTYP/K14/K14-6/K14-6-TRIGGER 1.jpg'
import img6trigger2img1 from '../v2/assets/PROTOTYP/K14/K14-6/K14-6-TRIGGER 2.jpg'
import img6trigger3img1 from '../v2/assets/PROTOTYP/K14/K14-6/K14-6-TRIGGER 3.jpg'

import img7trigger1img1 from '../v2/assets/PROTOTYP/K14/K14-7/K14-7-TRIGGER 1.jpg'
import img7trigger2img1 from '../v2/assets/PROTOTYP/K14/K14-7/K14-7-TRIGGER 2.jpg'
import img7trigger3img1 from '../v2/assets/PROTOTYP/K14/K14-7/K14-7-TRIGGER 3.jpg'
import img7trigger4img1 from '../v2/assets/PROTOTYP/K14/K14-7/K14-7-TRIGGER 4.jpg'

import img2trigger1voiceoverSK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K14_2_triger 1.mp3";
import img2trigger1voiceoverEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K14_2_triger_1.mp3";
import img2trigger2voiceoverSK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K14_2_triger 2.mp3";
import img2trigger2voiceoverEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K14_2_triger_2.mp3";
import img2trigger3voiceoverSK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K14_2_triger 3.mp3";
import img2trigger3voiceoverEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K14_2_triger_3.mp3";

import img3Atrigger1voiceoverSK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K14_3A_triger 1.mp3";
import img3Atrigger1voiceoverEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K14_3A_triger_1.mp3";
import img3Atrigger2voiceoverSK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K14_3A_triger 2.mp3";
import img3Atrigger2voiceoverEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K14_3A_triger_2.mp3";
import img3Atrigger3voiceoverSK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K14_3A_trigger 3.mp3";
import img3Atrigger3voiceoverEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K14_3A_triger_3.mp3";

import img3Btrigger1voiceoverSK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K14_3B_trigger 1.mp3";
import img3Btrigger1voiceoverEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K14_3B_triger_1.mp3";
import img3Btrigger2voiceoverSK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K14_3B_trigger 2.mp3";
import img3Btrigger2voiceoverEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K14_3B_triger_2.mp3";
import img3Btrigger3voiceoverSK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K14_3B_trigger 3.mp3";
import img3Btrigger3voiceoverEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K14_3B_triger_3.mp3";

import img4trigger1voiceoverSK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K14_4_triger1.mp3";
import img4trigger1voiceoverEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K14_4_triger_1.mp3";
import img4trigger2voiceoverSK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K14_4_triger2.mp3";
import img4trigger2voiceoverEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K14_4_triger_2.mp3";
import img4trigger3voiceoverSK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K14_4_triger3.mp3";
import img4trigger3voiceoverEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K14_4_triger_3.mp3";

import img5trigger1voiceoverSK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K14_5_triger 1.mp3";
import img5trigger1voiceoverEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K14_5_triger_1.mp3";
import img5trigger2voiceoverSK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K14_5_triger 2.mp3";
import img5trigger2voiceoverEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K14_5_triger_2.mp3";
import img5trigger3voiceoverSK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K14_5_triger 3.mp3";
import img5trigger3voiceoverEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K14_5_triger_3.mp3";

import img6trigger1voiceoverSK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K14_6_triger 1.mp3";
import img6trigger1voiceoverEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K14_6_triger_1.mp3";
import img6trigger2voiceoverSK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K14_6_triger 2.mp3";
import img6trigger2voiceoverEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K14_6_triger_2.mp3";
import img6trigger3voiceoverSK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K14_6_triger 3.mp3";
import img6trigger3voiceoverEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K14_6_triger_3.mp3";

import img7trigger1voiceoverSK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K14_7_triger 1.mp3";
import img7trigger1voiceoverEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K14_7_triger_1.mp3";
import img7trigger2voiceoverSK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K14_7_triger 2.mp3";
import img7trigger2voiceoverEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K14_7_triger_2.mp3";
import img7trigger3voiceoverSK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K14_7_triger 3.mp3";
import img7trigger3voiceoverEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K14_7_triger_3.mp3";
import img7trigger4voiceoverSK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K14_7_triger 4.mp3";
import img7trigger4voiceoverEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K14_7_triger_4.mp3";
import bgInstructions from "../v2/assets/PROTOTYP/K11/K_11_PAS_RESIZED.jpg";

const title = {
  sk: <>PRÍKLADY ZELENEJ <br/> ARCHITEKTÚRY V ZAHRANIČÍ</>,
  en: <>examples of green <br/> architecture abroad</>,
}

const title2 = {
  sk: <>BUDOVA IKEA - VIEDEŇ - RAKÚSKO</>,
  en: <>IKEA building - Vienna - Austria</>,
}

const title3 = {
  sk: <>Kancelárska budova Bloomberg - Veľká Británia</>,
  en: <>Bloomberg Office Building - London - UK</>,
}

const title4 = {
  sk: <>Deloitte univerzita - Francúzsko</>,
  en: <>Deloitte University of Paris - France</>,
}

const title5 = {
  sk: <>Cube - Nemecko</>,
  en: <>Cube - Berlin - Germany</>,
}

const title6 = {
  sk: <>BetonSilo - Nemecko</>,
  en: <>BetonSilo - Düsseldorf - Germany</>,
}

const title7 = {
  sk: <>Administratívne centrum - Spojené arabské emiráty</>,
  en: <>Sharjah Administrative Centre - United Arab Emirates</>,
}

const triggers2 = [
  {
    id: 'triggers2-1',
    top: '9.63%',
    left: '16.1%',
    photos: [img2trigger1img1],
    source: '',
    sources: ['https://newatlas.com'],
    voiceover: {
      sk: img2trigger1voiceoverSK,
      en: img2trigger1voiceoverEN,
    },
    title: title2,
    text: {
      sk: <>Sedemposchodová budova na viedenskej stanici Westbahnhof vyrástla len minulý rok a jej výstavbe predchádzali dlhé rokovania s radnicou mesta Viedeň. <b>IKEA</b> chcela totiž najprv postaviť svoj typický modro-žltý obchodný dom s parkoviskom na streche domu pre 500 áut. To sa ale viedenskej radnici nepáčilo a dala IKEA <b>dve hlavné požiadavky.</b></>,
      en: <>The seven-storey building at Vienna Westbahnhof station went up just last year, and its construction was preceded by long negotiations with Vienna City Hall. <b>IKEA</b> first wanted to build its typical blue-and-yellow department store with a 500-car parking lot on its roof. But the Vienna City Council did not like this and made <b>two main demands</b> of IKEA.</>,
    },
  },
  {
    id: 'triggers2-2',
    top: '6.57%',
    left: '69.53%',
    photos: [img2trigger2img1],
    source: '',
    sources: ['https://newatlas.com'],
    voiceover: {
      sk: img2trigger2voiceoverSK,
      en: img2trigger2voiceoverEN,
    },
    title: title2,
    text: {
      sk: <>Žiadne parkovisko pre autá a na streche musia <b>vybudovať zelený park.</b> IKEA nakoniec súhlasila a vypísala 3-kolovú architektonickú súťaž na budúcu podobu ich obchodného domu. Súťaž vyhral architektonický <b>ateliér Querkraft,</b> ktorý navrhol kompaktnú budovu so zelenou fasádou, s terasami so stromami a kvetmi a <b>žiadne parkovisko</b> pre autá, len pre asi <b>200 bicyklov.</b></>,
      en: <>No parking for cars and they must build a <b>green park on the roof.</b> IKEA finally agreed and launched a 3-round architectural competition for the future design of their store. The competition was won by the <b>architectural studio Querkraft,</b> who designed a compact building with a green facade, terraces with trees and flowers and <b>no parking for cars,</b> only for about <b>200 bicycles.</b></>,
    },
  },
  {
    id: 'triggers2-3',
    top: '66.11%',
    left: '77.76%',
    photos: [img2trigger3img1],
    source: '',
    sources: ['https://newatlas.com'],
    voiceover: {
      sk: img2trigger3voiceoverSK,
      en: img2trigger3voiceoverEN,
    },
    title: title2,
    text: {
      sk: <>Obchod stojí na križovatke dvoch liniek metra, troch električkových tratí a <b>prístupný je len pre chodcov a bicyklistov.</b> Ak si vyberiete tovar, ktorý si neviete odviezť na bicykli, dovezie to dopravca na elektromobile.</>,
      en: <>The store stands at the intersection of two subway lines, three tram lines and is <b>only accessible to pedestrians and cyclists.</b> If you pick up an item that you can't take by bike, it will be delivered to you by an electric car.</>,
    },
  },
]

const triggers3A = [
  {
    id: 'triggers3A-1',
    top: '26.57%',
    left: '13.43%',
    photos: [img3Atrigger1img1],
    source: '',
    sources: ['https://seele.com/references/bloomberg-headquarters'],
    voiceover: {
      sk: img3Atrigger1voiceoverSK,
      en: img3Atrigger1voiceoverEN,
    },
    title: title3,
    text: {
      sk: <><b>Spoločnosť Bloomberg</b> v spolupráci s prestížnou architektonickou <b>kanceláriou Foster + Partners</b> vybudovala svoje nové európske sídlo v Londýne na princípoch udržateľnosti. Ide o desaťpodlažnú budovu postavenú takmer v centre Londýna. V roku 2017, kedy bola stavba dokončená, sa táto budova pokladala za <b>najudržateľnejšiu kancelársku budovu na svete.</b> S 98,5-percentným skóre dosiahla budova dovtedy najvyššie hodnotenie, aké kedy získala kancelárska budova v systéme merania udržateľnosti <b>BREEAM.</b></>,
      en: <><b>Bloomberg,</b> in collaboration with the prestigious <b>architecture firm Foster + Partners,</b> has built its new European headquarters in London on the principles of sustainability. It is a ten-storey building constructed almost in the heart of London. In 2017, when it was completed, the building was considered <b>the most sustainable office building in the world.</b> With a score of 98.5%, the building achieved the highest rating ever given to an office building in the <b>BREEAM</b> sustainability measurement system.</>,
    },
  },
  {
    id: 'triggers3A-2',
    top: '75.09%',
    left: '30.67%',
    photos: [img3Atrigger2img1],
    source: '',
    sources: ['https://news.artnet.com'],
    voiceover: {
      sk: img3Atrigger2voiceoverSK,
      en: img3Atrigger2voiceoverEN,
    },
    title: title3,
    text: {
      sk: <>Tieto kvality sú viditeľné na <b>spotrebe vody</b> (o 73 % menej než v štandardnej budove s rovnakou veľkosťou) a <b>emisiách</b> <strong>CO<sub>2</sub></strong> (o 35 % menej než zákonom povolené emisie administratívnych budov vo Veľkej Británii).</>,
      en: <>These qualities are evident in its <b>water consumption</b> (73% less than a standard building of the same size) and <strong>CO<sub>2</sub></strong> <b>emissions</b> (35% less than the legally permitted emissions of office buildings in the UK).</>,
    },
  },
  {
    id: 'triggers3A-3',
    top: '23.7%',
    left: '84.27%',
    photos: [img3Atrigger3img1],
    sources: ['https://lookup.london/bloomberg-london/'],
    source: '',
    voiceover: {
      sk: img3Atrigger3voiceoverSK,
      en: img3Atrigger3voiceoverEN,
    },
    title: title3,
    text: {
      sk: <>Podľa architektov sa úspora CO2 vyrovná 600 tonám za rok – toľko vypustí CO2 auto, keď obíde Zem 120-krát. Na stropoch budovy sú osadené špeciálne navrhnuté panely. Každý z nich pomáha úsporne ovládať vykurovacie, chladiace, svetelné a akustické funkcie budovy a <b>znižuje</b> tak celkovú spotrebu <b>energie o viac ako 40 %.</b></>,
      en: <>According to the architects, the CO2 savings are equivalent to 600 tonnes per year - the amount of CO2 a car emits when it circles the earth 120 times. Specially designed panels are fitted to the building ceilings. Each helps to economically control the building's heating, cooling, lighting and acoustic functions, <b>reducing</b> overall <b>energy consumption by more than 40%.</b></>,
    },
  },
]

const triggers3B = [
  {
    id: 'triggers3B-1',
    top: '58.42%',
    left: '9.47%',
    photos: [img3Btrigger1img1],
    source: '',
    sources: ['https://seele.com/references/bloomberg-headquarters'],
    voiceover: {
      sk: img3Btrigger1voiceoverSK,
      en: img3Btrigger1voiceoverEN,
    },
    title: title3,
    text: {
      sk: <>Na streche budovy sú osadené aj <b>fotovoltické panely.</b> Ďalšou podstatnou súčasťou stratégie udržateľnosti je <b>recyklácia vody.</b></>,
      en: <>Photovoltaic panels are also installed on the roof. Water recycling is another essential part of the sustainability strategy.</>,
    },
  },
  {
    id: 'triggers3B-2',
    top: '20.74%',
    left: '15.78%',
    photos: [img3Btrigger2img1],
    source: '',
    sources: ['https://lookup.london/bloomberg-london/'],
    voiceover: {
      sk: img3Btrigger2voiceoverSK,
      en: img3Btrigger2voiceoverEN,
    },
    title: title3,
    text: {
      sk: <>Dažďová voda <b>zo strechy,</b> voda z <b>chladiacich jednotiek</b> a voda z kúpeľní sa zhromažďuje a používa na <b>splachovanie toaliet.</b></>,
      en: <><b>Rainwater from the roof,</b> water from <b>the cooling units,</b> the sinks, and showers is collected and used to <b>flush the toilets.</b></>,
    },
  },
  {
    id: 'triggers3B-3',
    top: '17.87%',
    left: '80.83%',
    photos: [img3Btrigger3img1],
    source: '',
    sources: ['https://lookup.london/bloomberg-london/'],
    voiceover: {
      sk: img3Btrigger3voiceoverSK,
      en: img3Btrigger3voiceoverEN,
    },
    title: title3,
    text: {
      sk: <>Pri výstavbe budovy použili až <b>60 % betónu z recyklátu.</b> Súčasťou budovy sú živé zelené steny, ktoré polievajú zachytenou dažďovou vodou.</>,
      en: <>Up to <b>60% recycled concrete</b> was used in the building construction. The building includes living green walls watered by the captured rainwater.</>,
    },
  },
]

const triggers4 = [
  {
    id: 'triggers4-1',
    top: '49.53%',
    left: '12.08%',
    photos: [img4trigger1img1],
    source: '',
    sources: ['https://www.dubuisson.eu'],
    voiceover: {
      sk: img4trigger1voiceoverSK,
      en: img4trigger1voiceoverEN,
    },
    title: title4,
    text: {
      sk: <>V Paríži nedávno dostavali školiace centrum pre spoločnosť Deloitte. V budove okrem prednáškových a seminárnych miestností, sú navrhnuté <b>aj izby pre ľudí,</b> ktorí sa prídu <b>školiť.</b></>,
      en: <>A training centre for Deloitte was recently completed in Paris. In addition to lecture and seminar rooms, the building is designed <b>with rooms for training session attendees.</b></>,
    },
  },
  {
    id: 'triggers4-2',
    top: '14.81%',
    left: '73.02%',
    photos: [img4trigger2img1],
    source: '',
    sources: ['https://www.dubuisson.eu'],
    voiceover: {
      sk: img4trigger2voiceoverSK,
      en: img4trigger2voiceoverEN,
    },
    title: title4,
    text: {
      sk: <>Budova bola navrhnutá ako zelená a udržateľná. A nielen budova, ale celý areál je zelený. Vďaka fotovoltike, inštalovanej na streche budovy, je budova energeticky nezávislá od verejnej siete, čím sa <b>znížila uhlíková stopa budovy na minimum.</b></>,
      en: <>The building was designed to be green and sustainable. And not only the building, but the entire campus is green. Thanks to the photovoltaics installed on the roof, the building is energy independent from the public grid, <b>reducing its carbon footprint to a minimum.</b></>,
    },
  },
  {
    id: 'triggers4-3',
    top: '56.01%',
    left: '85.93%',
    photos: [img4trigger3img1],
    source: '',
    sources: ['https://www.dubuisson.eu'],
    voiceover: {
      sk: img4trigger3voiceoverSK,
      en: img4trigger3voiceoverEN,
    },
    title: title4,
    text: {
      sk: <>Areál univerzity je ponorený do zelene, neďaleko je zalesnený park a súčasťou je aj <b>záhrada,</b> kde sa pestujú <b>rôzne druhy zeleniny,</b> ktoré potom univerzita využíva vo vlastnej kuchyni na prípravu jedál. Záhrada aj celý areál je <b>zavlažovaný zachytávanou dažďovou vodou.</b></>,
      en: <>The university campus is immersed in greenery, with a wooded park nearby and a <b>garden</b> where <b>different types of vegetables are grown,</b> then used by the university in its own kitchen to prepare meals. Both the garden and the entire campus are <b>irrigated with captured rainwater.</b></>,
    },
  },
]

const triggers5 = [
  {
    id: 'triggers5-1',
    top: '59.16%',
    left: '10.46%',
    photos: [img5trigger1img1],
    source: '',
    sources: ['https://www.archdaily.com'],
    voiceover: {
      sk: img5trigger1voiceoverSK,
      en: img5trigger1voiceoverEN,
    },
    title: title5,
    text: {
      sk: <>Táto ikonická budova, navrhnutá známou dánskou architektonickou kanceláriou <b>3XN,</b> bola dokončená v roku 2020 a je postavená v samotnom historickom centre Berlína na Washingtonskom námestí. V súčasnosti je to <b>najprogresívnejšia smart budova postavená v Nemecku.</b></>,
      en: <>Designed by the well-known Danish architecture firm <b>3XN,</b> this iconic building was completed in 2020 and is built in the very heart of Berlin historic centre on Washington Square. It is currently <b>the most progressive smart building built in Germany.</b></>,
    },
  },
  {
    id: 'triggers5-2',
    top: '17.03%',
    left: '75.52%',
    photos: [img5trigger2img1],
    source: '',
    sources: ['https://www.ubm-development.com'],
    voiceover: {
      sk: img5trigger2voiceoverSK,
      en: img5trigger2voiceoverEN,
    },
    title: title5,
    text: {
      sk: <>Ide o kancelársku budovu, ktorá má tie <b>najlepšie</b> charakteristiky <b>udržateľnej budovy:</b> takmer <b>nulovú spotrebu energie,</b> čo je dosiahnuté pomocou tepelných čerpadiel aj fotovoltiky, v interiéri je veľa zelene, a veľmi zaujímavo sú navrhnuté aj okolité zelené verejné priestory.</>,
      en: <>It is an office building that has <b>the best</b> characteristics of a <b>sustainable building: almost zero energy consumption,</b> achieved by both heat pumps and photovoltaics, featuring much greenery in the interior, and the surrounding green public spaces are also very interestingly designed.</>,
    },
  },
  {
    id: 'triggers5-3',
    top: '56.01%',
    left: '85.93%',
    photos: [img5trigger3img1],
    source: '',
    sources: ['https://www.busse-partner.com'],
    voiceover: {
      sk: img5trigger3voiceoverSK,
      en: img5trigger3voiceoverEN,
    },
    title: title5,
    text: {
      sk: <>Budova ponúka <b>vysoký užívateľský komfort</b> pre pracujúcich, ktorí môžu využiť aj <b>rozsiahle zelené terasy</b> na najvyššom poschodí budovy. Vonkajšie terasy sú aj na všetkých poschodiach budovy a sú zakomponované do fasád. Budova dostala názov <b>Cube ako kocka,</b> pretože má naozaj <b>tvar akejsi kocky</b> a jej fasády krásne zrkadlia okolité prostredie a panorámu mesta.</>,
      en: <>The building offers a <b>high level of user comfort</b> for workers, who can also take advantage of the extensive green terraces on the top floor of the building. Outdoor terraces are also on all floors and are incorporated into the facades. The building was named <b>Cube</b> because it is indeed <b>shaped as a cube</b> and its facades beautifully mirror the surrounding environment and the city skyline.</>,
    },
  },
]

const triggers6 = [
  {
    id: 'triggers6-1',
    top: '31.01%',
    left: '12.08%',
    photos: [img6trigger1img1],
    source: '',
    sources: ['https://www.db-bauzeitung.de'],
    voiceover: {
      sk: img6trigger1voiceoverSK,
      en: img6trigger1voiceoverEN,
    },
    title: title6,
    text: {
      sk: <>Znakom udržateľnosti a zelenosti mesta či štvrte býva aj to, ako využíva existujúce územia mesta. V Düsseldorfe v časti prístavu bolo nevyužívané územie vrátane budovy betónového sila. Radnica rozhodla, že prístav, vzhľadom na veľmi dobrú polohu v rámci mesta, využije pre <b>vybudovanie novej mestskej štvrte</b> a <b>betónové silo zrekonštruujú na zelenú budovu.</b></>,
      en: <>A sign of the sustainability and greenness of a city or neighbourhood is how it uses existing urban areas. In Düsseldorf, a part of the harbour had unused land, including a concrete silo building. The city hall decided to use the harbour, given its excellent location within the city, <b>to build a new urban quarter and to renovate the concrete silo into a green building.</b></>,
    },
  },
  {
    id: 'triggers6-2',
    top: '51.29%',
    left: '29.06%',
    photos: [img6trigger2img1],
    source: '',
    sources: ['https://www.db-bauzeitung.de'],
    voiceover: {
      sk: img6trigger2voiceoverSK,
      en: img6trigger2voiceoverEN,
    },
    title: title6,
    text: {
      sk: <><b>Betónové silo</b> bolo postavené ešte v roku 1929 a od roku 2000 je <b>pamiatkovo chránené.</b> Betónové silo sa po rekonštrukcii stalo súčasťou areálu Plange Mühle Campus, kde sídlia spoločnosti z oblasti módy, medicíny, architektúry a poradenstva, ako aj rôzne reštaurácie a bufety.</>,
      en: <><b>The concrete silo</b> was built in 1929 and since 2000 has been <b>listed as protected.</b> After its renovation, the concrete silo has become part of the Plange Mühle Campus, which houses companies from the fashion, medical, architecture and consultancy sectors, as well as various restaurants and cafeterias.</>,
    },
  },
  {
    id: 'triggers6-3',
    top: '34.25%',
    left: '82.96%',
    photos: [img6trigger3img1],
    source: '',
    sources: ['https://www.db-bauzeitung.de'],
    voiceover: {
      sk: img6trigger3voiceoverSK,
      en: img6trigger3voiceoverEN,
    },
    title: title6,
    text: {
      sk: <><b>Srdcom</b> tohto kampusu je už <b>spomínané zrekonštruované betónové silo.</b> Z bývalého priemyselného areálu sa stáva <b>vychytená mestská štvrť</b> s veľkorysými verejnými priestormi, s parkom a promenádou a s budovami, ktoré sú certifikovaná podľa najvyšších štandardov zelených budov.</>,
      en: <><b>At the heart</b> of this campus is the said <b>renovated concrete silo.</b> The former industrial site is becoming a <b>distinguished urban district</b> with generous public spaces, a park and promenade, and buildings that are certified to the highest green building standards.</>,
    },
  },
]

const triggers7 = [
  {
    id: 'triggers7-1',
    top: '63.05%',
    left: '10.46%',
    photos: [img7trigger1img1],
    source: '',
    sources: ['https://www.designboom.com'],
    voiceover: {
      sk: img7trigger1voiceoverSK,
      en: img7trigger1voiceoverEN,
    },
    title: title7,
    text: {
      sk: <>Do výberu zaujímavých zelených budov vo svete sme nemohli nezaradiť aj jednu z posledných realizácií vychytenej <b>architektky Zahy Hadid.</b> Ide o riadiace a administratívne centrum jednej z arabských spoločností, ktoré je poháňané solárnou energiou, <b>využíva najnovšie technológie</b> zamerané na dosiahnutie nulovej bilancie emisií skleníkových plynov a spĺňa štandardy <b>LEED Platinum,</b> čo je jeden z najvyšších možných stupňov certifikácie pre zelené budovy.</>,
      en: <>In our selection of interesting green buildings around the world, we couldn't help but include one of the latest projects by renowned architect Zaha Hadid. It is a management and administration centre for an Arab company powered by solar energy, using the latest technologies aimed at achieving zero greenhouse gas emissions balance and meeting the LEED Platinum standards, which is one of the highest possible levels of certification for green buildings.</>,
    },
  },
  {
    id: 'triggers7-2',
    top: '42.03%',
    left: '20.88%',
    photos: [img7trigger2img1],
    source: '',
    sources: ['https://www.designboom.com'],
    voiceover: {
      sk: img7trigger2voiceoverSK,
      en: img7trigger2voiceoverEN,
    },
    title: title7,
    text: {
      sk: <><b>Futuristický návrh</b> budovy je jedným z posledných diel architektky Zahy Hadidovej a radí sa medzi <b>najikonickejšie, digitálne podporované</b> a udržateľné budovy na svete. Tvar budovy, ktorá bola dokončená len minulý rok, a je zasadená <b>do púšte,</b> odráža okolitú krajinu.</>,
      en: <>The building's <b>futuristic design</b> is one of architect Zaha Hadid latest creations and ranks among <b>the most iconic, digitally-enabled</b> and sustainable buildings in the world. The building shape, only completed last year and set <b>in the desert,</b> reflects the surrounding landscape.</>,
    },
  },
  {
    id: 'triggers7-3',
    top: '69.44%',
    left: '72.18%',
    photos: [img7trigger3img1],
    source: '',
    sources: ['https://www.designboom.com'],
    voiceover: {
      sk: img7trigger3voiceoverSK,
      en: img7trigger3voiceoverEN,
    },
    title: title7,
    text: {
      sk: <>Tvarovo teda pripomína <b>piesočné duny.</b> Zároveň architektka využíva tvar budovy aj na <b>minimalizovanie spotreby energie.</b> Všetka energia potrebná pre budovu sa získava pomocou zdrojov s <b>nízkymi a nulovými emisiami uhlíka.</b></>,
      en: <>The shape is therefore reminiscent of <b>sand dunes.</b> At the same time, the architect also uses the building shape to <b>minimise energy consumption.</b> All the energy required for the building is obtained using <b>low and zero carbon sources.</b></>,
    },
  },
  {
    id: 'triggers7-4',
    top: '12.77%',
    left: '80.05%',
    photos: [img7trigger4img1],
    source: '',
    voiceover: {
      sk: img7trigger4voiceoverSK,
      en: img7trigger4voiceoverEN,
    },
    title: title7,
    text: {
      sk: <>Využíva sa aj <b>fotovoltika,</b> ktorá je zasadená do okolitých terénnych úprav. <b>Inteligentný systém</b> riadenia budovy <b>automaticky upravuje osvetlenie a teplotu</b> v závislosti od obsadenosti a dennej doby, povoľuje vstup do budovy na základe snímania tváre a má aj iné vychytávky.</>,
      en: <><b>Photovoltaics</b> are also used, which are set into the surrounding landscaping. <b>Smart building management system automatically adjusts lighting and temperature</b> depending on occupancy and time of day, allowing entry to the building based on facial scanning, and has other gadgets.</>,
    },
  },
]

const chapter14 = {
  url: '/chapter-14',
  name: 'chapter14',
  slides: [
    {
      Component: ChapterStart,
      props: {
        title
      },
    },
    {
      Component: Video,
      props: {
        video: {
          sk: video1SK,
          en: video1EN,
        },
      },
    },
    {
      Component: Instructions,
      props: {
        background: img2bg,
        strip2: true,
      },
    },
    {
      Component: Strip2,
      props: {
        title: title2,
        images: [
          {
            image: img2bg,
            sx: {
              width: '100%',
              zIndex: '3',
              objectFit: 'cover',
            },
          },
          {
            image: img2bgSwitch,
            showOnSwitch: true,
            sx: {
              width: '100%',
              zIndex: '4',
              objectFit: 'cover',
            },
          },
        ],
        triggers: triggers2,
      },
    },
    {
      Component: Strip2,
      props: {
        title: title3,
        images: [
          {
            image: img3Abg,
            sx: {
              width: '100%',
              zIndex: '3',
              objectFit: 'cover',
            },
          },
          {
            image: img3AbgSwitch,
            showOnSwitch: true,
            sx: {
              width: '100%',
              zIndex: '4',
              objectFit: 'cover',
            },
          },
        ],
        triggers: triggers3A,
      },
    },
    {
      Component: Strip2,
      props: {
        title: title3,
        images: [
          {
            image: img3Bbg,
            sx: {
              width: '100%',
              zIndex: '3',
              objectFit: 'cover',
            },
          },
          {
            image: img3BbgSwitch,
            showOnSwitch: true,
            sx: {
              width: '100%',
              zIndex: '4',
              objectFit: 'cover',
            },
          },
        ],
        triggers: triggers3B,
      },
    },
    {
      Component: Strip2,
      props: {
        title: title4,
        images: [
          {
            image: img4bg,
            sx: {
              width: '100%',
              zIndex: '3',
              objectFit: 'cover',
            },
          },
          {
            image: img4bgSwitch,
            showOnSwitch: true,
            sx: {
              width: '100%',
              zIndex: '4',
              objectFit: 'cover',
            },
          },
        ],
        triggers: triggers4,
      },
    },
    {
      Component: Strip2,
      props: {
        title: title5,
        images: [
          {
            image: img5bg,
            sx: {
              width: '100%',
              zIndex: '3',
              objectFit: 'cover',
            },
          },
          {
            image: img5bgSwitch,
            showOnSwitch: true,
            sx: {
              width: '100%',
              zIndex: '4',
              objectFit: 'cover',
            },
          },
        ],
        triggers: triggers5,
      },
    },
    {
      Component: Strip2,
      props: {
        title: title6,
        images: [
          {
            image: img6bg,
            sx: {
              width: '100%',
              zIndex: '3',
              objectFit: 'cover',
            },
          },
          {
            image: img6bgSwitch,
            showOnSwitch: true,
            sx: {
              width: '100%',
              zIndex: '4',
              objectFit: 'cover',
            },
          },
        ],
        triggers: triggers6,
      },
    },
    {
      Component: Strip2,
      props: {
        title: title7,
        images: [
          {
            image: img7bg,
            sx: {
              width: '100%',
              zIndex: '3',
              objectFit: 'cover',
            },
          },
          {
            image: img7bgSwitch,
            showOnSwitch: true,
            sx: {
              width: '100%',
              zIndex: '4',
              objectFit: 'cover',
            },
          },
        ],
        triggers: triggers7,
      },
    },
  ],
}

export default chapter14
