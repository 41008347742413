import React from 'react'
import SliderWrapper from '../../components/Slider/SliderWrapper'
import VideoPlayer from '../../components/Video/VideoPlayer'
import VideoOverlay from '../../components/Video/VideoOverlay'
import useVideoPlayer from '../../hooks/useVideoPlayer'
import ButtonLeft from "../../v2/components/Buttons/ButtonLeft";
import ButtonRight from "../../v2/components/Buttons/ButtonRight";

const Video = ({
  isEnding,
  chapter,
  video,
  videoPreview,
  goToPrevSlide,
  goToNextSlide,
  language,
}) => {
    const {
    isShownPreview,
    setIsShownPreview,
    isPLaying,
    setIsPlaying,
    onPlayClick,
  } = useVideoPlayer()

  return (
    <SliderWrapper flexCenter>
      <VideoPlayer
        video={video[language]}
        isPLaying={isPLaying}
        setIsPlaying={setIsPlaying}
        setIsShownPreview={setIsShownPreview}
      />

      <VideoOverlay
        videoPreview={videoPreview}
        isPLaying={isPLaying}
        isShownPreview={isShownPreview}
        onPlayClick={onPlayClick}
        chapter={chapter}
      />

        <ButtonLeft
            variant={1}
            isShown={!isPLaying}
            onClick={goToPrevSlide}
        />

        <ButtonRight
            variant={1}
            isShown={!isPLaying}
            onClick={goToNextSlide}
        />
    </SliderWrapper>
  )
}

export default Video
