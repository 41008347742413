import React from 'react'
import Box from '@mui/material/Box'
import InfoText from './InfoText'
import SliderWrapper from '../Slider/SliderWrapper'
import {useLanguageContext} from "../../contexts/LanguageContext";
import Button from "../../v2/components/Buttons/Button";
import BackgroundWave from "../../v2/components/BackgroundWave/BackgroundWave";
import background from "../../v2/assets/PROTOTYP/K00/K00-0.png";
import fonts from "../../styles/fonts";
import Typography from "@mui/material/Typography";
import ButtonLeft from "../../v2/components/Buttons/ButtonLeft";
import ButtonRight from "../../v2/components/Buttons/ButtonRight";

function toggleFullScreen() {
    if ((document.fullScreenElement && document.fullScreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)) {
        if (document.documentElement.requestFullScreen) {
            document.documentElement.requestFullScreen();
        } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) {
            document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
        }
    } else {
        if (document.cancelFullScreen) {
            document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
        }
    }
}

const Info = ({ goToNextSlide, goToPrevSlide, language }) => {
    const { setLanguage } = useLanguageContext()

  return (
    <SliderWrapper>
      <BackgroundWave
          disabled
          background={background}
      />

        <ButtonLeft
            isShown
            onClick={goToPrevSlide}
        />

        <ButtonRight
            isShown
            onClick={goToNextSlide}
        />

      <Box
        sx={{
          width: '100%',
          height: '100%',
          paddingTop: '20vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        }}
      >
          <Typography
              sx={{
                  marginTop: '2%',
                  textAlign: 'center',
                  ...fonts.Rubik['32px']({
                      fontWeight: '700',
                      color: '#E8AEBA',
                  }),
              }}
          >
              {language === 'sk' ? (
                  <>
                      Pre najlepší zážitok <br/>
                      z kurzu odporúčame zobrazenie <br/> na celú obrazovku.
                  </>
              ) : (
                  <>
                      For the best experience <br/> we recommend viewing the <br/> course in full screen.
                  </>
              )}
          </Typography>

          <Box
              sx={{
                  display: 'flex',
                  gap: '34px',
                  marginTop: '26px',
              }}
          >
              <Button
                  sx={{
                      minWidth: '250px',
                  }}
                  onClick={toggleFullScreen}
              >
                  full screen
              </Button>
          </Box>
      </Box>
    </SliderWrapper>
  )
}

export default Info
