import SliderWrapper from '../../../components/Slider/SliderWrapper'
import React, { memo, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import useSound from 'use-sound'
import ButtonMenu from "../Buttons/ButtonMenu";
import ButtonLeft from "../Buttons/ButtonLeft";
import ButtonRight from "../Buttons/ButtonRight";
import Header from "../Header/Header";
import Logo from "../Logo/Logo";
import Typography from "@mui/material/Typography";
import fonts from "../../../styles/fonts";
import ButtonPlay from "../Buttons/ButtonPlay";
import ButtonPause from "../Buttons/ButtonPause";
import ButtonRead from "../../../components/Buttons/ButtonRead";
import ButtonClose from "../../../components/Buttons/ButtonClose";
import {MouseParallax} from "react-just-parallax";
import ReactPlayer from "react-player";
import AdditionalStudy from "../AdditionalStudy/AdditionalStudy";
import Source from "../Source";

const Animated2 = ({
    animation,
  voiceover,
  isActive,
  language,
  text,
  title,
  goToPrevSlide,
  goToNextSlide,
    extra,
                       images,
                       imagesSwitch,
                       imagesLang,
    loop = true,
                       additionalStudy,
    source,
}) => {
  const [isLoaded, setIsLoaded] = useState(false)
    const [isShownOverlay, setIsShownOverlay] = useState(false)
    const [isSwitched, setIsSwitched] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [playVoiceover, { stop, pause }] = useSound(voiceover?.[language], {
    onload: () => {
      setIsLoaded(true)
    },
    onend: () => {
      setIsPlaying(false)
    },
  })

  const onPlay = () => {
    setIsPlaying(true)
    playVoiceover()
  }

  const onPause = () => {
    setIsPlaying(false)
    pause()
  }

  const onStop = () => {
    setIsPlaying(false)
    stop()
  }

  useEffect(() => {
    if (isLoaded) {
      if (isActive) {
        onPlay()
      } else {
        onStop()
      }
    }

    return () => onStop()
  }, [isLoaded, isActive])

  useEffect(() => {
    setIsLoaded(false)
    onStop()
  }, [language])

  return (
    <SliderWrapper flexCenter>
        <ButtonMenu
            variant={1}
            sx={{
                zIndex: '20',
            }}
        />

        <Logo
            color={'#112232'}
            sx={{
                zIndex: '18',
            }}
        />

      {voiceover && (
        <>
          <ButtonPlay
            sx={{
              zIndex: '20',
              opacity: isPlaying ? '1' : '0',
              pointerEvents: !isPlaying ? 'none' : 'all',
              transition: 'opacity 500ms ease 0s',
            }}
            onClick={onPause}
          />

          <ButtonPause
            sx={{
              zIndex: '20',
              opacity: !isPlaying ? '1' : '0',
              pointerEvents: isPlaying ? 'none' : 'all',
              transition: 'opacity 500ms ease 0s',
            }}
            onClick={onPlay}
          />
        </>
      )}

        {text && (
            <>
                <ButtonPlay
                    variant={1}
                    sx={{
                        zIndex: '23',
                        opacity: isShownOverlay && !isPlaying ? '1' : '0',
                        pointerEvents: isShownOverlay && isPlaying ? 'none' : 'all',
                        transition: 'opacity 500ms ease 0s',
                        right: '116px',
                    }}
                    onClick={onPlay}
                />

                <ButtonPause
                    variant={1}
                    sx={{
                        zIndex: '23',
                        opacity: isShownOverlay && isPlaying ? '1' : '0',
                        pointerEvents: isShownOverlay && !isPlaying ? 'none' : 'all',
                        transition: 'opacity 500ms ease 0s',
                        right: '116px',
                    }}
                    onClick={onPause}
                />

                <ButtonRead
                    sx={{
                        zIndex: '24',
                        opacity: !isShownOverlay ? '1' : '0',
                        pointerEvents: isShownOverlay ? 'none' : 'all',
                        transition: 'opacity 500ms ease 0s',
                    }}
                    onClick={() => setIsShownOverlay(!isShownOverlay)}
                />

                <ButtonClose
                    sx={{
                        zIndex: '24',
                        opacity: isShownOverlay ? '1' : '0',
                        pointerEvents: !isShownOverlay ? 'none' : 'all',
                        transition: 'opacity 500ms ease 0s',
                    }}
                    onClick={() => setIsShownOverlay(!isShownOverlay)}
                />
            </>
        )}

      <ButtonLeft
        isShown
        variant={1}
        onClick={goToPrevSlide}
        sx={{
          zIndex: '18',
        }}
      />

        <ButtonRight
            isShown
            variant={1}
            onClick={goToNextSlide}
            sx={{
                zIndex: '18',
            }}
        />

      <Header text={title[language]} />

        {animation && (
            <Box
                sx={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        top: '0',
                        left: '0',
                        position: 'absolute',
                        objectFit: 'cover',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            top: '0',
                            left: '0',
                            position: 'absolute',
                            '& video': {
                                objectFit: 'cover',
                            },
                        }}
                    >
                        <ReactPlayer
                            url={animation}
                            playing={isActive}
                            loop={loop}
                            muted
                            width={'100%'}
                            height={'100%'}
                        />
                    </Box>
                </Box>
            </Box>
        )}

        {images && (
            <Box
                sx={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                }}
            >
                {images?.map((image) => (
                    <Box
                        key={image.image}
                        sx={{
                            width: '100%',
                            height: '100%',
                            top: '0',
                            left: '0',
                            position: 'absolute',
                            objectFit: 'cover',
                            ...image.sx,
                        }}
                    >
                        {image.parallax ? (
                            <MouseParallax key={image.image} strength={0.08} lerpEase={0.15}>
                                <Box
                                    component={'img'}
                                    src={image.image}
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        top: '0',
                                        left: '0',
                                        position: 'absolute',
                                        objectFit: 'cover',
                                        ...image.sx,
                                    }}
                                />
                            </MouseParallax>
                        ) : (
                            <Box
                                key={image.image}
                                component={'img'}
                                src={image.image}
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    top: '0',
                                    left: '0',
                                    position: 'absolute',
                                    objectFit: 'cover',
                                    ...image.sx,
                                }}
                            />
                        )}
                    </Box>
                ))}

                {imagesSwitch && (
                    <>
                        {imagesSwitch.map((image) => (
                            <Box
                                key={image.image}
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    top: '0',
                                    left: '0',
                                    position: 'absolute',
                                    objectFit: 'cover',
                                    ...image.sx,
                                }}
                            >
                                {image.parallax ? (
                                    <MouseParallax
                                        key={image.image}
                                        strength={0.08}
                                        lerpEase={0.15}
                                    >
                                        <Box
                                            component={'img'}
                                            src={image.image}
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                                top: '0',
                                                left: '0',
                                                position: 'absolute',
                                                objectFit: 'cover',
                                                opacity: isSwitched ? '1' : '0',
                                                transition: 'opacity 500ms ease 0s',
                                                ...image.sx,
                                            }}
                                        />
                                    </MouseParallax>
                                ) : (
                                    <Box
                                        key={image.image}
                                        component={'img'}
                                        src={image.image}
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            top: '0',
                                            left: '0',
                                            position: 'absolute',
                                            objectFit: 'cover',
                                            opacity: isSwitched ? '1' : '0',
                                            transition: 'opacity 500ms ease 0s',
                                            ...image.sx,
                                        }}
                                    />
                                )}
                            </Box>
                        ))}
                    </>
                )}

                {imagesLang &&
                    imagesLang[language] &&
                    imagesLang[language].map((image) => (
                        <Box
                            key={image.image}
                            // component={'img'}
                            // src={image.image}
                            sx={{
                                width: '100%',
                                height: '100%',
                                top: '0',
                                left: '0',
                                position: 'absolute',
                                objectFit: 'cover',
                                ...image.sx,
                            }}
                        >
                            {image.parallax ? (
                                <MouseParallax
                                    key={image.image}
                                    strength={0.08}
                                    lerpEase={0.15}
                                >
                                    <Box
                                        component={'img'}
                                        src={image.image}
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            top: '0',
                                            left: '0',
                                            position: 'absolute',
                                            objectFit: 'cover',
                                            ...image.sx,
                                        }}
                                    />
                                </MouseParallax>
                            ) : (
                                <Box
                                    key={image.image}
                                    component={'img'}
                                    src={image.image}
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        top: '0',
                                        left: '0',
                                        position: 'absolute',
                                        objectFit: 'cover',
                                        ...image.sx,
                                    }}
                                />
                            )}
                        </Box>
                    ))}
            </Box>
        )}

        {text && (
            <Box
                sx={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    opacity: isShownOverlay ? '1' : '0',
                    pointerEvents: !isShownOverlay ? 'none' : 'all',
                    transition: 'opacity 500ms ease 0s',
                    background: '#150b0be6',
                    zIndex: '22',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    sx={{
                        ...fonts.Rubik['42px']({
                            color: '#3AD3A4',
                        }),
                        maxWidth: '62.5%',
                        '& b': {
                            color: '#E8AEBA',
                        },
                    }}
                >
                    {text[language]}
                </Typography>
            </Box>
        )}

        {extra && extra[language]}

        {additionalStudy && <AdditionalStudy additionalStudy={additionalStudy} language={language} />}

        {source && <Source>{source}</Source>}
    </SliderWrapper>
  )
}

export default memo(Animated2)
