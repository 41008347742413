import Quiz from '../components/Quiz/Quiz'
import QuizResult from '../components/Quiz/QuizResult'
import videoPreview2 from '../assets/_Video/05_End_Preview.png'
import video4SK from '../assets/_Video/_End/05_End_SK-2.mp4'
import video4EN from '../assets/_Video/_End/05_End_EN-2.mp4'
import Video from '../components/Video/Video'
import Partners from '../components/Partners/Partners'
import ChapterStart from "../v2/components/ChapterStart/ChapterStart";
import Competition from "../v2/components/Competition";

const title = {
  sk: 'KVÍZ',
  en: 'Quiz',
}

const quiz = {
  url: '/quiz',
  name: 'chapter0',
  slides: [
    {
      Component: ChapterStart,
      props: {
        title
      },
    },
    Quiz,
    QuizResult,
    Partners,
    Competition
  ],
}

export default quiz
