import strip1Background from '../v2/assets/PROTOTYP/K11/K11-PAS.png'

import trigger1img1 from '../v2/assets/PROTOTYP/K11/K11-TRIGGER-1-1.jpg'
import trigger1img2 from '../v2/assets/PROTOTYP/K11/K11-TRIGGER-1-2.jpg'
import trigger1img3 from '../v2/assets/PROTOTYP/K11/K11-TRIGGER-1-3.jpg'
import trigger2img1 from '../v2/assets/PROTOTYP/K11/K11-TRIGGER-2-1.jpg'
import trigger2img2 from '../v2/assets/PROTOTYP/K11/K11-TRIGGER-2-2.jpg'
import trigger2img3 from '../v2/assets/PROTOTYP/K11/K11-TRIGGER-2-3.jpg'
import trigger3img1 from '../v2/assets/PROTOTYP/K11/K11-TRIGGER-3-1.jpg'
import trigger3img2 from '../v2/assets/PROTOTYP/K11/K11-TRIGGER-3-2.jpg'
import trigger3img3 from '../v2/assets/PROTOTYP/K11/K11-TRIGGER-3-3.jpg'
import trigger4img1 from '../v2/assets/PROTOTYP/K11/K11-TRIGGER-4-1.jpg'
import trigger4img2 from '../v2/assets/PROTOTYP/K11/K11-TRIGGER-4-2.jpg'
import trigger4img3 from '../v2/assets/PROTOTYP/K11/K11-TRIGGER-4-3.jpg'

import trigger1voiceoverSK from '../v2/assets/VOICEOVER/VOICEOVER_SK/K11_Triger 1 Fotovoltika.mp3'
import trigger1voiceoverEN from '../v2/assets/VOICEOVER/VOICEOVER_EN/K11_triger 1.mp3'
import trigger2voiceoverSK from '../v2/assets/VOICEOVER/VOICEOVER_SK/K11_Triger 2 Technológie.mp3'
import trigger2voiceoverEN from '../v2/assets/VOICEOVER/VOICEOVER_EN/K11_triger 2.mp3'
import trigger3voiceoverSK from '../v2/assets/VOICEOVER/VOICEOVER_SK/K11_Triger 3.mp3'
import trigger3voiceoverEN from '../v2/assets/VOICEOVER/VOICEOVER_EN/K11_triger 3.mp3'
import trigger4voiceoverSK from '../v2/assets/VOICEOVER/VOICEOVER_SK/K11_Triger 4.mp3'
import trigger4voiceoverEN from '../v2/assets/VOICEOVER/VOICEOVER_EN/K11_triger 4.mp3'

const strip3Images = [
  {
    image: strip1Background,
    sx: {
      zIndex: '3',
      position: 'relative',
    },
  },
]

const strip3Triggers = [
  {
    id: 'strip1Triggers1',
    top: '43.51%',
    left: '6.18%',
    photos: [trigger1img1, trigger1img2, trigger1img3],
    source: '',
    voiceover: {
      sk: trigger1voiceoverSK,
      en: trigger1voiceoverEN,
    },
    title: {
      sk: 'TECHNOLÓGIE V ZELENEJ ARCHITEKTÚRE A STAVEBNÍCTVE',
      en: 'Technologies in green architecture and construction sector',
    },
    text: {
      sk: <><strong>Fotovoltika na strechách a fasádách budov</strong> <br/><br/> Slnko svieti všade a <b>fotovoltaické panely</b> je možné inštalovať v podstate kdekoľvek, aj na fasádu či strechu budov. Aj na Slovensku sa využíva fotovoltika, aj keď v porovnaní s inými krajinami, v tejto oblasti veľmi zaostáváme. Pritom použitie fotovoltiky v budovách je najrýchlejšie realizovateľným nástrojom ako využiť <b>obnoviteľné zdroje energie</b> a tým znížiť energetické náklady na prevádzku budov.</>,
      en: <><strong>Photovoltaics on roofs and building facades</strong> <br/><br/> The sun shines everywhere and <b>photovoltaic panels</b> can be installed virtually anywhere, including on the facade or roof of buildings. Photovoltaics are also being used in Slovakia, although we are lagging far behind compared to other countries in this area. The use of photovoltaics in buildings is the fastest way to <b>use renewables</b> thus reducing the energy costs of building operation.</>,
    },
  },
  {
    id: 'strip1Triggers2',
    top: '34.44%',
    left: '33.3%',
    photos: [trigger2img1, trigger2img2, trigger2img3],
    source: '',
    voiceover: {
      sk: trigger2voiceoverSK,
      en: trigger2voiceoverEN,
    },
    title: {
      sk: 'TECHNOLÓGIE V ZELENEJ ARCHITEKTÚRE A STAVEBNÍCTVE',
      en: 'Technologies in green architecture and construction sector',
    },
    text: {
      sk: <><strong>Technológie pre chladenie budov</strong> <br/><br/> V dôsledku <b>klimatickej zmeny</b> sme svedkami <b>čoraz horúcejších období leta</b> aj u nás. Nie je už žiadnym prekvapením, ak sa teploty v lete vyšplhajú aj <b>nad 36 stupňov C.</b> V dôsledku toho sa enormne prehrievajú aj interiéry budov a čoraz viac riešime práve chladenie budov počas leta.</>,
      en: <><strong>Technologies for building cooling</strong> <br/><br/> Due to the <b>climate change,</b> we are seeing <b>increasingly hot summers</b> here too. It is no longer a surprise when summer temperatures climb <b>above 36 degrees C.</b> As a result, the interiors of buildings are also overheating enormously and we are increasingly dealing with the cooling of buildings during the summer.</>,
    },
  },
  {
    id: 'strip1Triggers3',
    top: '65.55%',
    left: '47.63%',
    photos: [trigger3img1, trigger3img2, trigger3img3],
    source: '',
    voiceover: {
      sk: trigger3voiceoverSK,
      en: trigger3voiceoverEN,
    },
    title: {
      sk: 'TECHNOLÓGIE V ZELENEJ ARCHITEKTÚRE A STAVEBNÍCTVE',
      en: 'Technologies in green architecture and construction sector',
    },
    text: {
      sk: <><strong>Technológie pre chladenie budov</strong> <br/><br/> Chladenie budov v lete je už väčším problémom ako ich vykurovanie v zime. <b>Klasické klimatizácie však nie sú východiskom.</b> Tie spotrebuvávajú veľmi veľa energie zo siete a preto odborníci <b>vyvíjajú nové technológie</b> aj na chladenie budov, ktoré nazaťažujú životné prostredie.</>,
      en: <><strong>Technologies for building cooling</strong> <br/><br/> Cooling buildings in summer is already a bigger problem than heating them in winter. However, <b>conventional air conditioners are not the solution.</b> They consume a lot of energy from the grid, which is why experts are also <b>developing new technologies</b> for cooling buildings that are not hard on the environment.</>,
    },
  },
  {
    id: 'strip1Triggers4',
    top: '31.57%',
    left: '80.26%',
    photos: [trigger4img1, trigger4img2, trigger4img3],
    source: '',
    sources: ['https://www.beka-klima.de', 'https://www.juflex.sk', 'https://whirlon-vykurovanie.sk'],
    voiceover: {
      sk: trigger4voiceoverSK,
      en: trigger4voiceoverEN,
    },
    title: {
      sk: 'TECHNOLÓGIE V ZELENEJ ARCHITEKTÚRE A STAVEBNÍCTVE',
      en: 'Technologies in green architecture and construction sector',
    },
    text: {
      sk: <><strong>Technológie pre chladenie budov</strong> <br/><br/> Relatívnou novinkou je napr. <b>technológia chladených stropov.</b> Princíp tejto technológie spočíva v tom, že <b>potrubím v strope cirkuluje chladná voda,</b> ktorá ochladzuje interiér budovy. Dokonca v Londýne je tento systém použitý v rámci celej jednej mestskej štvrte, kde sa čerpá chladná voda do potrubia z rieky Temža. Je to ekologické, energeticky nenáročné a nezaťažuje to životné prostredie.</>,
      en: <><strong>Technologies for building cooling</strong> <br/><br/> For example, the <b>technology of cooled ceilings</b> is a relative novelty. The principle of this technology is that <b>cold water circulates through ceiling pipes to cool the building interior.</b> Even in London, this system is being used in one borough, where cool water is pumped into pipes from the Thames. It is environmentally friendly, energy-efficient and does not put a strain on the environment.</>,
    },
  },
]

export { strip3Images, strip3Triggers }
