import React from 'react'
import ChapterStart from "../v2/components/ChapterStart/ChapterStart";
import Video from "../components/Video/Video";
import video1SK from "../v2/assets/PROTOTYP/K01/K01-VIDEO.mp4";
import video1EN from "../v2/assets/PROTOTYP/K01/K01 uvod ENG gg.mp4";

const title = {
  sk: 'ÚVOD DO TÉMY',
  en: 'Introduction to the topic',
}

const chapter1 = {
  url: '/chapter-1',
  name: 'chapter1',
  slides: [
    {
      Component: ChapterStart,
      props: {
        title
      },
    },
    {
      Component: Video,
      props: {
        video: {
          sk: video1SK,
          en: video1EN,
        },
      },
    },
  ],
}

export default chapter1
