import React from 'react'
import ChapterStart from "../v2/components/ChapterStart/ChapterStart";
import Video from "../components/Video/Video";
import Animated2 from "../v2/components/Animated/Animated2";

import video1SK from "../v2/assets/PROTOTYP/K13/K13_01_1.mp4";
import video1EN from "../v2/assets/PROTOTYP/K13/K13 ENG gg.mp4";

import img1bg from '../v2/assets/PROTOTYP/K13/K13_2A.jpg'
import img1parallax from '../v2/assets/PROTOTYP/K13/K13_2A.png'

import img2bg from '../v2/assets/PROTOTYP/K13/K13_2B.jpg'
import img2parallax from '../v2/assets/PROTOTYP/K13/K13_2B.png'

import img3bg from '../v2/assets/PROTOTYP/K13/K13_2C.jpg'
import img3parallax from '../v2/assets/PROTOTYP/K13/K13_2C.png'

import img4bg from '../v2/assets/PROTOTYP/K13/K13_2D.jpg'
import img4parallax from '../v2/assets/PROTOTYP/K13/K13_2D.png'

import img5bg from '../v2/assets/PROTOTYP/K13/K13_3A.jpg'
import img5parallax from '../v2/assets/PROTOTYP/K13/K13_3A.png'

import img6bg from '../v2/assets/PROTOTYP/K13/K13_3B.jpg'
import img6parallax from '../v2/assets/PROTOTYP/K13/K13_3B.png'

import img7bg from '../v2/assets/PROTOTYP/K13/K13_3C.jpg'

import img8bg from '../v2/assets/PROTOTYP/K13/K13_4A.jpg'
import img8parallax from '../v2/assets/PROTOTYP/K13/K13_4A.png'

import img9bg from '../v2/assets/PROTOTYP/K13/K13_4B.jpg'
import img9parallax from '../v2/assets/PROTOTYP/K13/K13_4B.png'

import img10bg from '../v2/assets/PROTOTYP/K13/K13_5A.jpg'

import img11bg from '../v2/assets/PROTOTYP/K13/K13_5B.jpg'

import img12bg from '../v2/assets/PROTOTYP/K13/K13_5C.jpg'
import img12parallax from '../v2/assets/PROTOTYP/K13/K13_5C.png'

import img13bg from '../v2/assets/PROTOTYP/K13/K13_6A.jpg'
import img13parallax from '../v2/assets/PROTOTYP/K13/K13_6A.png'

import img14bg from '../v2/assets/PROTOTYP/K13/K13_6B.jpg'
import img14parallax from '../v2/assets/PROTOTYP/K13/K13_6B.png'

import img15bg from '../v2/assets/PROTOTYP/K13/K13_6C.jpg'
import img15parallax from '../v2/assets/PROTOTYP/K13/K13_6C.png'

import img16bg from '../v2/assets/PROTOTYP/K13/K13_7A.jpg'
import img16parallax from '../v2/assets/PROTOTYP/K13/K13_7A.png'
import img17bg from '../v2/assets/PROTOTYP/K13/K13_7B.jpg'
import img17parallax from '../v2/assets/PROTOTYP/K13/K13_7B.png'

import img18bg from '../v2/assets/PROTOTYP/K13/K13_8A.jpg'
import img18parallax from '../v2/assets/PROTOTYP/K13/K13_8A.png'
import img19bg from '../v2/assets/PROTOTYP/K13/K13_8B.jpg'
import img19parallax from '../v2/assets/PROTOTYP/K13/K13_8B.png'

import img20bg from '../v2/assets/PROTOTYP/K13/K13_9A.jpg'
import img20parallax from '../v2/assets/PROTOTYP/K13/K13_9A.png'
import img21bg from '../v2/assets/PROTOTYP/K13/K13_9B.jpg'
import img21parallax from '../v2/assets/PROTOTYP/K13/K13_9B.png'

import voiceoverK13_2ASK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K13_2_A.mp3";
import voiceoverK13_2AEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K13_2_A.mp3";

import voiceoverK13_2BSK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K13_2_B.mp3";
import voiceoverK13_2BEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K13_2_B.mp3";

import voiceoverK13_2CSK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K13_2_C.mp3";
import voiceoverK13_2CEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K13_2_C.mp3";

import voiceoverK13_2DSK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K13_2_D.mp3";
import voiceoverK13_2DEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K13_2_D.mp3";

import voiceoverK13_3ASK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K13_3_A.mp3";
import voiceoverK13_3AEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K13_3_A.mp3";

import voiceoverK13_3BSK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K13_3_B.mp3";
import voiceoverK13_3BEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K13_3_B.mp3";

import voiceoverK13_3CSK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K13_3_C.mp3";
import voiceoverK13_3CEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K13_3_C.mp3";

import voiceoverK13_4ASK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K13_4_A.mp3";
import voiceoverK13_4AEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K13_4_A.mp3";

import voiceoverK13_4BSK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K13_4_B.mp3";
import voiceoverK13_4BEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K13_4_B.mp3";

import voiceoverK13_5ASK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K13_5_A.mp3";
import voiceoverK13_5AEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K13_5_A.mp3";

import voiceoverK13_5BSK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K13_5_B.mp3";
import voiceoverK13_5BEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K13_5_B.mp3";

import voiceoverK13_5CSK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K13_5_C.mp3";
import voiceoverK13_5CEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K13_5_C.mp3";

import voiceoverK13_6ASK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K13_6_A.mp3";
import voiceoverK13_6AEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K13_6_A.mp3";

import voiceoverK13_6BSK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K13_6_B.mp3";
import voiceoverK13_6BEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K13_6_B.mp3";

import voiceoverK13_6CSK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K13_6_C.mp3";
import voiceoverK13_6CEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K13_6_C.mp3";

import voiceoverK13_70SK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K13_7_0 oprava.mp3";
import voiceoverK13_70EN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K13_7 oprava.mp3";

import voiceoverK13_7ASK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K13_7_A.mp3";
import voiceoverK13_7AEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K13_7_A.mp3";

import voiceoverK13_8ASK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K13_8.mp3";
import voiceoverK13_8AEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K13_8.mp3";

import voiceoverK13_9ASK from "../v2/assets/VOICEOVER/VOICEOVER_SK/K13_9.mp3";
import voiceoverK13_9AEN from "../v2/assets/VOICEOVER/VOICEOVER_EN/K13_9.mp3";
import TextSlide from "../v2/components/Animated/TextSlide";

const title = {
  sk: <>PRÍKLADY ZELENEJ <br/> ARCHITEKTÚRY NA SLOVENSKU</>,
  en: <>Examples of green <br/> architecture in Slovakia</>,
}

const title2 = {
  sk: 'Einpark Offices - Bratislave',
  en: 'Einpark Offices - Bratislava',
}

const title3 = {
  sk: 'Pradiareň 1900 - Bratislave',
  en: 'Yarn spinning mill 1900 - Bratislava',
}

const title4 = {
  sk: 'Zukermandel a budova ČSOB - Bratislava',
  en: 'Zukermandel and the ČSOB building - Bratislava',
}

const title5 = {
  sk: 'Komplex Bastion Office Center - Košice',
  en: 'Bastion Office Centre complex - Košice',
}

const title6 = {
  sk: 'Rodinný dom - Horná Mičiná',
  en: 'Family house - Horná Mičiná',
}

const title7 = {
  sk: 'RIVER PARK - BRATISLAVA',
  en: 'RIVER PARK - BRATISLAVA',
}

const title8 = {
  sk: 'EUROVEA CITY 2 - BRATISLAVA',
  en: 'EUROVEA CITY 2 - BRATISLAVA',
}

const title9 = {
  sk: 'WESTEND - BRATISLAVA',
  en: 'WESTEND - BRATISLAVA',
}

const text2A = {
  sk: (
      <>
        Toto petržalské bizniscentrum sa môže označiť za jednu z <b>najekologickejších budov v Európe</b> a to vďaka tomu, že ako prvá kancelárska budova v Európe sa stala <b>uhlíkovo neutrálnou budovou.</b> Ide o novostavbu, ktorá bola dokončená koncom roka 2020.
      </>
  ),
  en: (
      <>
        This business centre in Petržalka can claim to be <b>one of the greenest buildings in Europe,</b> thanks to the fact that it is the first office building in Europe to become <b>carbon neutral</b> during operation. It is a new building that was completed at the end of 2020.
      </>
  )
}
const text2B = {
  sk: (
      <>
        Einpark Offices vzniklo na dovtedy prázdnom a nevyužitom pozemku pri Einsteinovej ulici v Petržalke. Budova má 7 podlaží. V podzemných priestoroch sú garáže. Na návrhu budovy sa podieľala <b>kancelária A1 Architecture</b> v spolupráci s prestížnym <b>slovinským štúdiom OFIS Architects.</b>
      </>
  ),
  en: (
      <>
        Einpark Offices was built on a previously vacant and unused plot of land off the Einsteinova Street in Petržalka. The building has 7 floors. There are garages in the underground. The building was designed by <b>A1 Architecture</b> in cooperation with the prestigious <b>Slovenian studio OFIS Architects.</b>
      </>
  )
}
const text2C = {
  sk: (
      <>
        A čím sa objekt odlišuje od iných bizniscentier v Bratislave? Svojou <b>úrovňou zelenosti a udržateľnosti.</b> Jej súčasťou je zelená pobytová strecha, tepelné čerpadlá, rozličné inteligentné technológie, 75 parkovacích miest pre bicykle alebo 7 nabíjacích staníc pre automobily.
      </>
  ),
  en: (
      <>
        And what makes the building different from other business centres in Bratislava? Its <b>level of greenness and sustainability.</b> It includes a green residence roof, heat pumps, various smart technologies, 75 parking spaces for bicycles or 7 charging stations for cars.
      </>
  )
}
const text2D = {
  sk: (
      <>
        Navyše, <b>prevádzka a doprava do budovy</b> je <b>uhlíkovo neutrálna,</b> čo majú potvrdené aj certifikátom <b>LEED Zero Carbon.</b> Táto budova tak dosiahla takú mieru zelenosti, akú doteraz dosahuje len niekoľko budov na celom svete a v Európe okrem Einparku ešte jedna.
      </>
  ),
  en: (
      <>
        In addition, <b>the building operation is carbon neutral,</b> as confirmed by their <b>LEED Zero Carbon</b> certification. The building has thus achieved a level of greenness that few buildings in the world, and one in Europe besides Einpark, have achieved to date.
      </>
  )
}

const text3A = {
  sk: (
      <>
          <b>Pradiareň 1900</b> je zrekonštruovaná historická budova v Bratislave. Pôvodná budova Pradiarne patrila k ikonám priemyselnej architektúry 20. storočia na Slovensku a pôvodne slúžila ako pradiareň v rámci areálu Bratislavskej cvernovej továrne. Budova bola plne dokončená v roku 1920 a krátko nato sa stala <b>najväčším priemyselným výrobným objektom</b> v Bratislave.V roku 2007 bola Pradiareň zaradená do zoznamu národných kultúrnych pamiatok, ale chátrala.
      </>
  ),
  en: (
      <>
        <b>Yarn spinning mill 1900</b> is a renovated historical building in Bratislava. The original yarn spinning mill building was one of the icons of 20th century industrial architecture in Slovakia and originally served as a spinning mill within the premises of the Bratislava sawing thread factory. The building was fully completed in 1920 and shortly thereafter became <b>the largest industrial production building</b> in Bratislava. In 2007 the spinning mill was included in the list of national cultural monuments, but it was falling into disrepair.
      </>
  )
}
const text3B = {
  sk: (
      <>
          V roku <b>2018</b> sa vlastník budovy rozhodol, že budovu pôvodnej pradiarne <b>zrekonštruuje</b> a to tak, aby budova po rekonštrukcii <b>spĺňala všetky požiadavky a kritériá zelenej budovy.</b> Budova po dokončení rekonštrukcie získala najvyšší možný certifikát zelenosti <b>BREEAM Outstanding.</b> Budova sa tak zaradila medzi <b>1 %</b> budov na svete s takýmto hodnotením a je prvou rekonštruovanou budovou s takýmto certifikátom na Slovensku.
      </>
  ),
  en: (
      <>
        In <b>2018,</b> the owner of the building decided to <b>renovate</b> the building of the original spinning mill so that the building after the renovation <b>would meet all the requirements and criteria of a green building.</b> Upon completion of the renovation, the building was awarded the highest possible <b>BREEAM Outstanding</b> green building certificate. The building is among the top <b>1%</b> of buildings in the world with such a rating and is the first renovated building with such a certificate in Slovakia.
      </>
  )
}
const text3C = {
  sk: (
      <>
        Za jej rekonštrukciou stoja skúsení <b>architekti Peter Bouda a Ivan Masár.</b> Budova Pradiarne je veľmi pozitívnym príkladom rekonštruovanej zelenej budovy, ktorá sa zelenou stalo po premyslenej obnove. V súčasnosti sú tam umiestnené obchody, kancelárie a aj priestory pre kultúru.
      </>
  ),
  en: (
      <>
        Behind its reconstruction are experienced <b>architects Peter Bouda and Ivan Masár.</b> The spinning mill building is a very positive example of a renovated green building that has become green after a well-thought-out renovation. Currently it houses shops, offices and cultural spaces.
      </>
  )
}

const text4A = {
  sk: (
      <>
        Celý komplex <b>novostavby Zukermandlu,</b> ktorý sa nachádza na bratislavskom nábreží tesne pod bratislavských hradom, predstavuje zelenú a udržateľnú architektúru.
        <br/>
        Autormi Zukermandlu sú opäť <b>architekti Peter Bouda a Ivan Masár</b> a ide o polyfunkčný komplex s kancelárskými, obchodnými a obytnými priestormi, s veľmi dobre riešenými verejnými priestormi, kde je <b>veľa zelene.</b>
      </>
  ),
  en: (
      <>
        The entire complex of the <b>new Zukermandel building,</b> located on the Bratislava waterfront just below Bratislava Castle, represents green and sustainable architecture.
        <br/>
        The authors of Zukermandel are again <b>architects Peter Bouda and Ivan Masár</b> and it is a multifunctional complex with office, commercial and residential spaces, with very well-designed public spaces, with <b>rich greenery.</b>
      </>
  )
}
const text4B = {
  sk: (
      <>
        Budovy v rámci komplexu šetria energiami, znižujú uhlíkovú stopu, využívajú energiu z obnoviteľných zdrojov energie – fotovoltika na strechách budov a viaceré strechy v rámci komplexu sú zelené. V komplexe Zukermandlu sa nachádza aj budova ČSOB, ktorá je pokladaná za jednu z prvých skutočných smart budov na Slovensku.
      </>
  ),
  en: (
      <>
        The buildings within the complex save energy, reduce the carbon footprint, use renewable energy - photovoltaics on the roofs of the buildings and several roofs within the complex are green. The Zukermandel complex also includes the ČSOB building, which is considered to be one of the first true smart buildings in Slovakia and has been awarded a BREEAM Excellent certificate.
      </>
  )
}

const text5A = {
  sk: (
      <>
        Aj v metropole východu Slovenska bolo postavených už viacero zelených budov a nedávno pribudol aj nový <b>administratívno-obchodný komplex Bastion Office,</b> ktorý vznikol na ploche bývalého parkoviska.
      </>
  ),
  en: (
      <>
        Multiple green buildings have already been built in the eastern Slovak metropolis, and recently a new <b>office and commercial complex Bastion Office</b> has been added, which was built on the area of a former car park.
      </>
  )
}
const text5B = {
  sk: (
      <>
        Certifikát zelenosti a udržateľnosti <b>LEED Gold</b> si budova získala vďaka radu <b>progresívnych riešení.</b> Budova je chladená podzemnou vodou, voda zo studne sa využíva aj ako úžitková na splachovanie toaliet, umývanie áut, zavlažovanie a podobne. Vzduch, ktorý prúdi do budovy, sa filtruje, intenzita vetrania v každej zóne sa osobitne nastavuje podľa priebežne nameranej koncentrácie CO2 a optimálnych hodnôt vlhkosti vzduchu.
      </>
  ),
  en: (
      <>
        The building has earned the <b>LEED Gold</b> green and sustainability certificate thanks to a number of <b>progressive solutions.</b> The building is cooled by underground water, and well water is also used as utility water for toilet flushing, car washing, irrigation, and more. The air flowing into the building is filtered, the ventilation intensity in each zone is separately adjusted according to the continuously measured CO2 concentration and optimum humidity values.
      </>
  )
}
const text5C = {
  sk: (
      <>
        Veľkou devízou budovy je tiež <b>sálavé kúrenie i chladenie zabudované v podlahe,</b> ktoré v každom ročnom období vytvára priaznivú klímu bez pocitov prievanu. Okrem toho sú v bizniscentre energeticky úsporné LED svietidlá, inteligentné ovládanie intenzity svetla či bezbariérové vstupy.
      </>
  ),
  en: (
      <>
        The building's great asset is also the <b>radiant heating and cooling built into the floor,</b> creating good climate in all seasons with no feeling of draught. In addition, the business centre features energy-saving LED lighting, smart light intensity control and wheelchair-accessible entrances.
      </>
  )
}

const text6A = {
  sk: (
      <>
        Predchádzajúce príklady, ktoré sme pre vás vybrali, boli všetko väčšie projekty, kde má zmysel podstúpiť proces merania a certifikácie zelenosti budov. <b>Pri rodinných domoch to nie je potrebné,</b> lebo každá rodina, čo býva vo svojom dome, má účty za energie <b>sama pod kontrolou</b> a tiež nepotrebuje certifikát na to, aby vedela, či sa v dome dobre cíti, či pri výstavbe boli použité ekologické materiály a aké technológie použila pri jeho výstavbe.
      </>
  ),
  en: (
      <>
        The previous examples we have selected were all larger projects where it makes sense to undergo the process of measuring and certifying the greenness of the buildings. This is <b>not necessary for single-family houses</b> because each family living in their house has their own energy bills <b>under control</b> and also does not need a certificate to know if they are comfortable in their house, if green materials were used in its construction and what technologies were used in its construction.
      </>
  )
}
const text6B = {
  sk: (
      <>
        <b>Rodinný dom v Hornej Mičinej</b> v banskobystrickom kraji navrhol <b>architekt Daniel Bizoň.</b> Na prvý pohľad ide o dom, ktorý pripomína tradičný slovenský rodinný dom. Ale je to zelený dom, ktorý z energetického hľadiska je dokonca plusový. To znamená, že si sám vyrobí viac energie, ako spotrebuje a to vďaka použitiu fotovoltiky na streche domu.
      </>
  ),
  en: (
      <>
        <b>The house in Horna Mičina</b> in the Banská Bystrica region was designed by <b>architect Daniel Bizoň.</b> At first glance, it is a house resembling a traditional Slovak family house. But it is a green house, which even has positive energy balance. Due to roof-mounted photovoltaics it produces more energy than it consumes.
      </>
  )
}
const text6C = {
  sk: (
      <>
        V dome je tiež použitá aktívna rekuperačná vetracia jednotka, ktorá zabezpečuje <b>zdravé vnútorné prostredie a spätné získavanie tepla.</b> Rodinný dom taktiež umožňuje šetrné využívanie vody, na závlahu záhrady a aj na splachovanie WC využívajú dažďovú vodu.
      </>
  ),
  en: (
      <>
        The house also uses an active heat recovery ventilation unit, which ensures a <b>healthy indoor environment and heat recovery.</b> The house also allows for water conservation, using rainwater to irrigate the garden and to flush the toilets.
      </>
  )
}

const textSlideText = {
    sk: (
        <>
            JTRE, ako mestotvorný developer, si uvedomuje zásadný vplyv výstavby na životné prostredie a klimatické zmeny, ale aj na zdravie, bezpečnosť a pohodu užívateľov aj danej časti mesta. Preto je v centre našej pozornosti udržateľnosť vo všetkých jej stránkach – ekonomickej, environmentálnej aj
            sociálnej. Veríme, že kvalitným návrhom, zodpovednou výstavbou a riadením projektov dokážeme prispieť k lepšiemu a udržateľnejšiemu mestskému prostrediu všade tam, kde dávame nový priestor pre život.
        </>
    ),
    en: (
        <>
            JTRE, as an urban developer, is aware of the fundamental impact of construction on the environment and climate change, in addition to the health, safety and well-being of the users and the part of the city in question. Therefore, sustainability in all its aspects - economic, environmental and social - is at the forefront of our attention. We believe that through quality design, responsible construction and project management, we can contribute to a better and more sustainable urban environment wherever we provide new space to live.
        </>
    ),
}

const text7A = {
  sk: (
      <>
        Kancelárske priestory v River Parku majú regulovateľné inteligentné vykurovanie a chladenie, ktoré je <b>zabezpečené aj vodou z Dunaja,</b> ktorý tečie doslova pod oknami budov. Budovy postupne vylepšujeme z hľadiska energetickej efektívnosti a pribudne aj vybavenie pre cyklistov.
      </>
  ),
  en: (
      <>
        The office spaces in River Park have controllable smart heating and cooling, that is <b>also sourced by water from the Danube,</b> which literally flows underneeth the building windows. The buildings are gradually being improved in terms of energy efficiency and adding facilities for cyclists.
      </>
  )
}

const text8A = {
  sk: (
      <>
        Najnovšie postavené budovy Pribinova 34 a Pribinova 40 v novovznikajúcej zóne Eurovea City <b>sa uchádzajú o najvyššie stupne certifikácie BREEAM,</b> ktorá hodnotí trvalú udržateľnosť budov. Súčasťou sú bezpečné statia pre bicykle a kvalitné zázemie pre cyklistov, vrátane šatní.
      </>
  ),
  en: (
      <>
        The most recently built buildings Pribinova 34 and Pribinova 40 in the emerging Eurovea City zone are <b>competing for the highest levels of BREEAM certification,</b> that assesses the sustainability of buildings. They include secure bike racks and quality facilities for cyclists, including showers and changing rooms.
      </>
  )
}

const text9A = {
  sk: (
      <>
        Westend biznis zóna sa môže pochváliť nielen zelenou strechou, výbornou občianskou vybavenosťou, ale aj <b>skvelou dostupnosťou vďaka cyklotrase.</b> Budovy Westend Plaza a Gate už získali certifikáty BREEAM, na ďalších sa pracuje.
      </>
  ),
  en: (
      <>
        The Westend business zone not only boasts a green roof, excellent amenities, but also <b>great accessibility thanks to a cycle route.</b> The Westend Plaza and Gate buildings have been awarded BREEAM certificates, with more in the pipeline.
      </>
  )
}

const chapter13 = {
  url: '/chapter-13',
  name: 'chapter13',
  slides: [
    {
      Component: ChapterStart,
      props: {
        title
      },
    },
    {
      Component: Video,
      props: {
        video: {
          sk: video1SK,
          en: video1EN,
        },
      },
    },

    {
      Component: Animated2,
      props: {
        images: [
          {
            image: img1bg
          },
          {
            image: img1parallax,
            parallax: true,
          },
        ],
        title: title2,
        voiceover: {
          sk: voiceoverK13_2ASK,
          en: voiceoverK13_2AEN,
        },
        text: text2A,
          source: 'https://3a3.sk'
      },
    },
    {
      Component: Animated2,
      props: {
        images: [
          {
            image: img2bg
          },
          {
            image: img2parallax,
            parallax: true,
          },
        ],
        title: title2,
        voiceover: {
          sk: voiceoverK13_2BSK,
          en: voiceoverK13_2BEN,
        },
        text: text2B,
          source: 'www.ceeqa.com'
      },
    },
    {
      Component: Animated2,
      props: {
        images: [
          {
            image: img3bg
          },
          {
            image: img3parallax,
            parallax: true,
          },
        ],
        title: title2,
        voiceover: {
          sk: voiceoverK13_2CSK,
          en: voiceoverK13_2CEN,
        },
        text: text2C,
          source: 'ZDROJ: REFRESHER / PAULINA SČEPKOVÁ'
      },
    },
    {
      Component: Animated2,
      props: {
        images: [
          {
            image: img4bg
          },
          {
            image: img4parallax,
            parallax: true,
          },
        ],
        title: title2,
        voiceover: {
          sk: voiceoverK13_2DSK,
          en: voiceoverK13_2DEN,
        },
        text: text2D,
          source: 'www.facebook.com'
      },
    },

    {
      Component: Animated2,
      props: {
        images: [
          {
            image: img5bg
          },
          {
            image: img5parallax,
            parallax: true,
          },
        ],
        title: title3,
        voiceover: {
          sk: voiceoverK13_3ASK,
          en: voiceoverK13_3AEN,
        },
        text: text3A,
          source: 'www.pradiaren1900.com',
      },
    },
    {
      Component: Animated2,
      props: {
        images: [
          {
            image: img6bg
          },
          {
            image: img6parallax,
            parallax: true,
          },
        ],
        title: title3,
        voiceover: {
          sk: voiceoverK13_3BSK,
          en: voiceoverK13_3BEN,
        },
        text: text3B,
          source: 'www.pradiaren1900.com',
      },
    },
    {
      Component: Animated2,
      props: {
        images: [
          {
            image: img7bg
          },
        ],
        title: title3,
        voiceover: {
          sk: voiceoverK13_3CSK,
          en: voiceoverK13_3CEN,
        },
        text: text3C,
          source: 'www.pradiaren1900.com',
      },
    },

    {
      Component: Animated2,
      props: {
        images: [
          {
            image: img9bg
          },
          {
            image: img9parallax,
            parallax: true,
          },
        ],
        title: title4,
        voiceover: {
          sk: voiceoverK13_4BSK,
          en: voiceoverK13_4BEN,
        },
        text: text4B,
          source: 'www.jtre.sk',
      },
    },
    {
      Component: Animated2,
      props: {
        images: [
          {
            image: img8bg
          },
          {
            image: img8parallax,
            parallax: true,
          },
        ],
        title: title4,
        voiceover: {
          sk: voiceoverK13_4ASK,
          en: voiceoverK13_4AEN,
        },
        text: text4A,
          source: 'www.jtre.sk',
      },
    },

    {
      Component: Animated2,
      props: {
        images: [
          {
            image: img10bg
          },
        ],
        title: title5,
        voiceover: {
          sk: voiceoverK13_5ASK,
          en: voiceoverK13_5AEN,
        },
        text: text5A,
          source: 'https://www.bastion-kosice.sk',
      },
    },
    {
      Component: Animated2,
      props: {
          images: [
              {
                  image: img12bg
              },
              {
                  image: img12parallax,
                  parallax: true,
              },
          ],
        title: title5,
        voiceover: {
          sk: voiceoverK13_5BSK,
          en: voiceoverK13_5BEN,
        },
        text: text5B,
          source: 'https://www.bastion-kosice.sk',
      },
    },
    {
      Component: Animated2,
      props: {
          images: [
              {
                  image: img11bg
              },
          ],
        title: title5,
        voiceover: {
          sk: voiceoverK13_5CSK,
          en: voiceoverK13_5CEN,
        },
        text: text5C,
          source: 'https://www.bastion-kosice.sk',
      },
    },

    {
      Component: Animated2,
      props: {
        images: [
          {
            image: img13bg
          },
          {
            image: img13parallax,
            parallax: true,
          },
        ],
        title: title6,
        voiceover: {
          sk: voiceoverK13_6ASK,
          en: voiceoverK13_6AEN,
        },
        text: text6A,
          source: 'Martin Dubovský',
      },
    },
    {
      Component: Animated2,
      props: {
        images: [
          {
            image: img14bg
          },
          {
            image: img14parallax,
            parallax: true,
          },
        ],
        title: title6,
        voiceover: {
          sk: voiceoverK13_6BSK,
          en: voiceoverK13_6BEN,
        },
        text: text6B,
          source: 'Martin Dubovský',
      },
    },
    {
      Component: Animated2,
      props: {
        images: [
          {
            image: img15bg
          },
          {
            image: img15parallax,
            parallax: true,
          },
        ],
        title: title6,
        voiceover: {
          sk: voiceoverK13_6CSK,
          en: voiceoverK13_6CEN,
        },
        text: text6C,
          source: 'Martin Dubovský',
      },
    },

      {
          Component: TextSlide,
          props: {
              voiceover: {
                  sk: voiceoverK13_70SK,
                  en: voiceoverK13_70EN,
              },
              text: textSlideText,
          },
      },

    {
      Component: Animated2,
      props: {
        images: [
          {
            image: img16bg
          },
          {
            image: img16parallax,
            parallax: true,
          },
        ],
        title: title7,
        voiceover: {
          sk: voiceoverK13_7ASK,
          en: voiceoverK13_7AEN,
        },
        text: text7A,
      },
    },
    {
      Component: Animated2,
      props: {
        images: [
          {
            image: img17bg
          },
          {
            image: img17parallax,
            parallax: true,
          },
        ],
        title: title7,
      },
    },

    {
      Component: Animated2,
      props: {
        images: [
          {
            image: img18bg
          },
          {
            image: img18parallax,
            parallax: true,
          },
        ],
        title: title8,
        voiceover: {
          sk: voiceoverK13_8ASK,
          en: voiceoverK13_8AEN,
        },
        text: text8A,
          source: 'www.jtre.sk',
      },
    },
    {
      Component: Animated2,
      props: {
        images: [
          {
            image: img19bg
          },
          {
            image: img19parallax,
            parallax: true,
          },
        ],
        title: title8,
          source: 'www.jtre.sk',
      },
    },

    {
      Component: Animated2,
      props: {
        images: [
          {
            image: img20bg
          },
          {
            image: img20parallax,
            parallax: true,
          },
        ],
        title: title9,
        voiceover: {
          sk: voiceoverK13_9ASK,
          en: voiceoverK13_9AEN,
        },
        text: text9A,
          source: 'www.jtre.sk',
      },
    },
    {
      Component: Animated2,
      props: {
        images: [
          {
            image: img21bg
          },
          {
            image: img21parallax,
            parallax: true,
          },
        ],
        title: title9,
          source: 'www.jtre.sk',
      },
    },
  ],
}

export default chapter13
