import { keyframes } from '@emotion/react'

const wave = keyframes`
  0% {
    transform: translateX(-0);
  }

  50% {
    transform: translateX(-2.5%);
  }

  100% {
    transform: translateX(0);
  }
`

const wave2 = keyframes`
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5%);
  }

  100% {
    transform: translateY(5%);
  }
`

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(58, 211, 164, 0.8);
  }

  50% {
    box-shadow: 0 0 0 30px rgba(58, 211, 164, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(58, 211, 164, 0);
  }
`
const rotate360 = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`

const pulseAnimation = {
  animation: `${pulse} 1.5s infinite`,
}

const rotate360Animation = {
  animation: `${rotate360} 30s infinite linear`,
}

export { wave, wave2, fadeIn, pulseAnimation, rotate360Animation }
