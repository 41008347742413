import React from "react";
import Typography from "@mui/material/Typography";

const Logo = ({ color = '#3AD3A4', sx, }) => {
    return (
        <Typography
            component={'a'}
            href={'https://www.eduvision.sk/'}
            target={'_blank'}
            sx={{
                color,
                position: 'absolute',
                bottom: '4.16%',
                right: '2.81%',
                zIndex: '1',
                textDecoration: 'unset',
                ...sx,
            }}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="149" height="19" viewBox="0 0 149 19" fill="none">
                <path d="M0 18.7198H13.52V15.1047H4.66797V10.8451H11.3284V8.04277H4.66797V3.89528H13.2639V0.280236H0V18.7198Z" fill="#112232"/>
                <path d="M15.4547 18.7198H23.5951C29.1739 18.7198 32.6749 15.5531 32.6749 9.47198C32.6749 3.41888 28.9747 0.280236 22.9974 0.280236H15.4547V18.7198ZM20.1226 15.1047V3.89528H23.2536C26.0145 3.89528 28.0354 5.52065 28.0354 9.47198C28.0354 13.3673 26.1284 15.1047 23.5382 15.1047H20.1226Z" fill="#112232"/>
                <path d="M43.3113 19C48.5485 19 51.9072 16.2817 51.9072 11.5737V0.280236H47.78V11.4056C47.78 13.9277 46.0438 15.1888 43.5675 15.1888C41.0912 15.1888 39.3549 13.9277 39.3549 11.4056V0.280236H34.6869V11.5737C34.6869 16.2817 38.074 19 43.3113 19Z" fill="#112232"/>
                <path d="M60.6603 18.7198H66.4668L73.6111 0.280236H68.8293L63.9336 14.6283H63.8766L58.8671 0.280236H53.5445L60.6603 18.7198Z" fill="#112232"/>
                <path d="M75.4977 18.7198H80.1656V0.280236H75.4977V18.7198Z" fill="#112232"/>
                <path d="M90.6877 19C97.3766 19 99.1697 16.1976 99.1697 13.059C99.1697 11.3215 98.4582 10.0044 97.1204 9.30383C96.0103 8.71534 94.4164 8.37906 92.3101 8.04277C90.7162 7.79056 88.7522 7.4823 88.0406 7.17404C87.386 6.8938 87.1298 6.38938 87.1298 5.49262C87.1298 4.25959 88.2114 3.33481 90.7446 3.33481C93.3063 3.33481 94.4164 4.48378 94.4164 6.58554H98.5436C98.5436 2.43805 96.6081 0 90.6592 0C85.2512 0 82.4903 2.41003 82.4903 5.88495C82.4903 7.65044 83.1165 8.93953 84.3119 9.69616C85.3935 10.3968 87.2721 10.7611 89.1222 11.0413C90.6877 11.2655 92.6232 11.5457 93.4486 11.826C94.1887 12.0782 94.5302 12.5826 94.5302 13.3673C94.5302 14.5442 93.8187 15.6652 90.6592 15.6652C87.5283 15.6652 86.7028 14.3761 86.7028 12.3864H82.5472C82.5472 15.7773 84.255 19 90.6877 19Z" fill="#112232"/>
                <path d="M101.515 18.7198H106.183V0.280236H101.515V18.7198Z" fill="#112232"/>
                <path d="M118.498 19C124.162 19 128.546 15.441 128.546 9.41593C128.546 3.50295 124.162 0 118.498 0C112.834 0 108.451 3.50295 108.451 9.41593C108.451 15.441 112.834 19 118.498 19ZM118.498 15.385C115.538 15.385 113.289 13.1711 113.289 9.44395C113.289 5.77286 115.538 3.61504 118.498 3.61504C121.458 3.61504 123.707 5.77286 123.707 9.44395C123.707 13.1711 121.458 15.385 118.498 15.385Z" fill="#112232"/>
                <path d="M130.67 18.7198H135.053L134.939 4.42773H134.968L143.478 18.7198H149V0.280236H144.617L144.759 12.6667H144.731L137.387 0.280236H130.67V18.7198Z" fill="#112232"/>
            </svg>
        </Typography>
    )
}

export default Logo
