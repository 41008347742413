import React from 'react'
import Instructions from '../components/Instructions/Instructions'
import ChapterTitle from "../v2/components/ChapterTitle/ChapterTitle";
import backgroundk02 from "../v2/assets/PROTOTYP/K08/K08-0.png";
import Video from "../components/Video/Video";
import video1SK from "../v2/assets/PROTOTYP/K08/K08_01_1.mp4";
import video1EN from "../v2/assets/PROTOTYP/K08/K08_ eng gg.mp4";
import Strip from "../components/Strip/Strip";
import animation1 from '../v2/assets/PROTOTYP/K08/K08-PAS-anim-1.webm'
import animation2 from '../v2/assets/PROTOTYP/K08/K08-PAS-anim-2.webm'
import animation3 from '../v2/assets/PROTOTYP/K08/K08-PAS-anim-3.webm'
import animation4 from '../v2/assets/PROTOTYP/K08/K08-PAS-anim-4.webm'
import animation1Safari from '../v2/assets/PROTOTYP/K08/K08-PAS-anim-1.mov'
import animation2Safari from '../v2/assets/PROTOTYP/K08/K08-PAS-anim-2.mov'
import animation3Safari from '../v2/assets/PROTOTYP/K08/K08-PAS-anim-3.mov'
import animation4Safari from '../v2/assets/PROTOTYP/K08/K08-PAS-anim-4.mov'
import {strip2Images, strip2Triggers} from "../strips/strip2";
import bg from "../v2/assets/PROTOTYP/K08/K08-DOPLNKOVESTUDIUM.jpg";
import bgInstructions from "../v2/assets/PROTOTYP/K08/K08-PAS_RESIZED.jpg";
import { isSafari } from 'react-device-detect'

const chapter8 = {
  url: '/chapter-8',
  name: 'chapter8',
  slides: [
    {
      Component: ChapterTitle,
      props: {
        title: {
          sk: <>SMART BUDOVY A ZELENÉ <br/> MESTSKÉ ŠTVRTE</>,
          en: <>Smart buildings</>,
        },
        background: backgroundk02,
      },
    },
    {
      Component: Video,
      props: {
        video: {
          sk: video1SK,
          en: video1EN,
        },
      },
    },
    {
      Component: Instructions,
      props: {
        background: bgInstructions,
        strip1: true,
      },
    },
    {
      Component: Strip,
      props: {
        images: strip2Images,
        triggers: strip2Triggers,
        animations: [
          {
            animation: isSafari ? animation1Safari : animation1,
            sx: {},
          },
          {
            animation: isSafari ? animation2Safari : animation2,
            sx: {
              left: '28.33055%',
            },
          },
          {
            animation: isSafari ? animation3Safari : animation3,
            sx: {
              left: '56.661101%',
            },
          },
          {
            animation: isSafari ? animation4Safari : animation4,
            sx: {
              left: '84.9916527%',
            },
          },
        ],
        scrollHandler: (percentage, language) => {
          return {
            sk: 'SMART BUDOVY A ZELENÉ MESTSKÉ ŠTVRTE',
            en: 'Smart buildings',
          }[language]
        },
        additionalStudy: [
          {
            img: bg,
            text: {
              sk: (
                  <>
                    <b>Nie však každá inteligentná budova je zelenou budovou, resp. smart budovou.</b> <br/><br/> Ak sa v budove síce zbierajú údaje o jej prevádzke, ale zároveň budova nespĺňa kritéria zelenej budovy, akými sú <b>nízka energetická náročnosť,</b> zachytávanie a spätné <b>využívanie dažďovej vody,</b> uplatnenie <b>obnoviteľných zdrojov energie, dostatok zelene</b> a parkovacích miest pre bicykle, atď., potom takúto budovu nemôžeme nazvať smart budovou.</>
              ),
              en: (
                  <>
                    <b>However, not every smart building is a green building or smart building.</b>
<br/><br/>
                    If data on its operation is collected in a building, but at the same time the building does not meet the criteria of being green, such as <b>low energy intensity,</b> rainwater collection and reuse, application of <b>renewable energy, sufficient green space</b> and bicycle parking, etc., then such a building cannot be called a smart building.
                  </>
              ),
            },
          },
        ],
      },
    },
  ],
}

export default chapter8
