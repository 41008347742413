import React from 'react'
import Instructions from '../components/Instructions/Instructions'
import ChapterTitle from "../v2/components/ChapterTitle/ChapterTitle";
import backgroundk02 from "../v2/assets/PROTOTYP/K11/K11-0.png";
import Strip from "../components/Strip/Strip";
import animation1 from '../v2/assets/PROTOTYP/K11/K11-PAS-anim-1.webm'
import animation2 from '../v2/assets/PROTOTYP/K11/K11-PAS-anim-2.webm'
import animation3 from '../v2/assets/PROTOTYP/K11/K11-PAS-anim-3.webm'
import animation1Safari from '../v2/assets/PROTOTYP/K11/K11-PAS-anim-1.mov'
import animation2Safari from '../v2/assets/PROTOTYP/K11/K11-PAS-anim-2.mov'
import animation3Safari from '../v2/assets/PROTOTYP/K11/K11-PAS-anim-3.mov'
import {strip3Images, strip3Triggers} from "../strips/strip3";
import bgInstructions from "../v2/assets/PROTOTYP/K11/K_11_PAS_RESIZED.jpg";
import { isSafari } from 'react-device-detect'

const chapter11 = {
  url: '/chapter-11',
  name: 'chapter11',
  slides: [
    {
      Component: ChapterTitle,
      props: {
        title: {
          sk: <>TECHNOLÓGIE V ZELENEJ <br/> ARCHITEKTÚRE A <br/> STAVEBNÍCTVE</>,
          en: <>Technologies in green <br/> architecture and <br/> construction sector</>,
        },
        background: backgroundk02,
      },
    },
    {
      Component: Instructions,
      props: {
        background: bgInstructions,
      },
    },
    {
      Component: Strip,
      props: {
        images: strip3Images,
        triggers: strip3Triggers,
        animations: [
          {
            animation: isSafari ? animation1Safari : animation1,
            sx: {},
          },
          {
            animation: isSafari ? animation2Safari : animation2,
            sx: {
              left: '35.539267%',
            },
          },
          {
            animation: isSafari ? animation3Safari : animation3,
            sx: {
              left: '71.078534%',
            },
          },
        ],
        scrollHandler: (percentage, language) => {
          return {
            sk: 'TECHNOLÓGIE V ZELENEJ ARCHITEKTÚRE A STAVEBNÍCTVE',
            en: 'Technologies in green architecture and construction sector',
          }[language]
        },
      },
    },
  ],
}

export default chapter11
