import React from 'react'
import Box from '@mui/material/Box'
import base from '../../assets/_Menu/DaA_M_Base.png'
import Typography from '@mui/material/Typography'
import MenuSectionButton from './MenuSectionButton'
import fonts from '../../styles/fonts'
import imageShadow from '../../styles/shadows'
import Button from "../../v2/components/Buttons/Button";

const MenuSection = ({ title, buttons, sx }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        maxWidth: '36.45833333333333%',
          gap: '8px',
          flex: '1',
          ...sx,
      }}
    >
        <Typography
            sx={{
              ...fonts.Rubik['32px']({
                  color: '#3AD3A4',
                  fontWeight: '700',
              }),
                textTransform: 'uppercase',
                textAlign: 'center',
                marginBottom: '22px',
            }}
      >
        {title}
      </Typography>

      {buttons.map((button, index) => (
          <Button
              key={button.text}
              sx={{
                  ...fonts.Rubik['18px']({
                      fontWeight: '700',
                  }),
                  padding: '0 22px',
                  flex: '1',
                  minHeight: '5.5vh',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
              }}
              onClick={button.onClick}
          >
              {button.text}
          </Button>
        // <MenuSectionButton key={button.text} offset={index} {...button} />
      ))}
    </Box>
  )
}

export default MenuSection
