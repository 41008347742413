import React from 'react'
import Animated from "../v2/components/Animated/Animated";
import ChapterTitle from "../v2/components/ChapterTitle/ChapterTitle";
import backgroundk02 from "../v2/assets/PROTOTYP/K09/K09-0.png";
import img1 from '../v2/assets/PROTOTYP/K09/K09-1.jpg'
import animation2 from '../v2/assets/PROTOTYP/K09/K09-2/K09-2.mp4'
import voiceoverK02_1SK from '../v2/assets/VOICEOVER/VOICEOVER_SK/K09_1.mp3'
import voiceoverK02_1EN from '../v2/assets/VOICEOVER/VOICEOVER_EN/K09_1.mp3'
import voiceoverK02_2SK from '../v2/assets/VOICEOVER/VOICEOVER_SK/K09_2.mp3'
import voiceoverK02_2EN from '../v2/assets/VOICEOVER/VOICEOVER_EN/K09_2.mp3'
import bg from "../v2/assets/PROTOTYP/K09/K09-DOPLNKOVESTUDIUM-1.jpg";
import bg2 from "../v2/assets/PROTOTYP/K09/K09-DOPLNKOVESTUDIUM-2.jpg";

const title1 = {
  sk: 'ZELENÉ STAVEBNÍCTVO',
  en: 'Green building sector',
}

const textImage1 = {
  sk: (
      <>
          Na to, aby sa mohli stavať naozaj zelené budovy, alebo aby sa staršie budovy mohli obnoviť tak, že sa z nich po obnove stanú zelené budovy, potrebujeme mať k dispozícií <b>ekologické stavebné materiály a inovatívne technológie.</b>
      </>
  ),
  en: (
      <>
          In order to build truly green buildings, or to renovate older buildings so that they become green when renovated, we need to have <b>green building materials and innovative technologies at our disposal.</b>
      </>
  )
}

const textImage2 = {
  sk: (
      <>
          <b>Aké sú to ekologické stavebné materiály?</b>
          <br/><br/>
          To sú také, pri výrobe ktorých sa využívajú <b>lokálne suroviny,</b> napr. piesok, štrk, voda, drevo, atď. a zároveň sa pri ich výrobe nespotrebováva veľa energie, alebo sú technologické linky na výrobu týchto stavebných materiálov <b>zásobované energiou z obnoviteľných zdrojov</b> ako napr. zo slnka, vetra, vody, atď.
      </>
  ),
  en: (
      <>
          <b>What are green building materials?</b>
          <br/><br/>
          They are those that use <b>local raw materials,</b> such as sand, gravel, water, wood, etc., and do not consume much energy in their production, or the process lines for producing these building materials are <b>supplied with energy from renewable sources</b> such as the sun, wind, water, etc.
      </>
  )
}

const chapter9 = {
  url: '/chapter-9',
  name: 'chapter9',
  slides: [
    {
      Component: ChapterTitle,
      props: {
        title: {
            sk: <>ZELENÉ STAVEBNÍCTVO</>,
            en: <>Green building sector</>,
        },
        background: backgroundk02,
      },
    },
    {
      Component: Animated,
      props: {
        placement: 'right',
          image: img1,
        title: title1,
        voiceover: {
          sk: voiceoverK02_1SK,
          en: voiceoverK02_1EN,
        },
        text: textImage1,
      },
    },
    {
      Component: Animated,
      props: {
        placement: 'right',
        animation: animation2,
        title: title1,
        voiceover: {
            sk: voiceoverK02_2SK,
            en: voiceoverK02_2EN,
        },
        text: textImage2,
          loop: false,
          additionalStudy: [
              {
                  img: bg,
                  text: {
                      sk: (
                          <>
                              Odborníci zo stavebníctva neustále pracujú na tom, aby sa <b>stavebné materiály</b> vyrábali čo najefektívnejšie a <b>s minimálnymi dopadmi na životné prostredie.</b> Čoraz viac sa snažia o to, aby sa v stavebníctve používali produkty, materiály a suroviny opakovane. Na našej planéte je totiž <b>obmedzené množstvo surovín</b> a pri ich spotrebe je nutné sa zamyslieť nielen nad ich množstvom, ale aj nad ich kvalitou.
<br/><br/>
                              Používaním <b>recyklovaných</b> surovín, chránime kvalitnejšie primárne suroviny, ktoré potom môžu byť použité na mieste, kde sú nenahraditeľné. Tak maximálne využijeme materiálový potenciál každej suroviny.
                          </>
                      ),
                      en: (
                          <>
                              Building industry professionals are constantly working to produce <b>building materials</b> as efficiently as possible and with <b>minimal impact on the environment.</b> Increasingly, they are striving to reuse products, materials and raw materials in the construction industry. There is a <b>finite amount</b> of raw materials on the planet and when consuming them, one must think not only about the quantity but also the quality thereof.
<br/><br/>
                              By using <b>recycled</b> raw materials, we are protecting higher quality primary raw materials that can then be used in a place where they are irreplaceable. In this way, we maximise the material potential of each raw material.
                          </>
                      ),
                  },
              },
              {
                  img: bg2,
                  text: {
                      sk: (
                          <>
                              Zároveň odborníci v stavebníctve vymýšľajú aj rôzne <b>nové technológie,</b> ktorými nahradzujú roky používané technológie, ktoré ale neúmerne zaťažujú naše životné prostredie a vypúšťajú veľké množstvo emisií skleníkových plynov.
<br/><br/>
                              Ako príklad môžeme uviesť vývoj <b>tepelného čerpadla,</b> alebo objav fotovoltiky či využitie solárnych článkov na ohrev teplej vody v budovách.
                              <br/><br/>
                              Podrobnejšie sme sa týmto technológiám venovali už v e-kurze v roku 2018, preto si princíp ich fungovania môžete pozrieť tam: <b><a style={{ color: '#112232' }} href="http://kurz2018.detiaarchitektura.sk" target={'_blank'}>http://kurz2018.detiaarchitektura.sk</a></b>
                          </>
                      ),
                      en: (
                          <>
                              At the same time, construction professionals are also inventing various <b>new technologies</b> to replace those used for years, but which place a disproportionate burden on our environment and emit large quantities of greenhouse gas emissions.
<br/><br/>
                              Examples include the development of the <b>heat pump,</b> or the discovery of photovoltaics, or the use of solar cells to heat hot water in buildings.
                              <br/><br/>
                              We covered these technologies in more detail in our 2018 e-course, so you can see the principle of their operation there: <b><a style={{ color: '#112232' }} href="http://kurz2018.detiaarchitektura.sk" target={'_blank'}>http://kurz2018.detiaarchitektura.sk</a></b>
                          </>
                      ),
                  },
              },
          ],
      },
    },
  ],
}

export default chapter9
