import React from "react";
import video from "../../assets/PROTOTYP/UI/panak-anim-menu-loop.webm";
import videoSafari from "../../assets/PROTOTYP/UI/panak-anim-menu-loop.mov";
import video1 from "../../assets/PROTOTYP/UI/panak-anim-cast-kapitola-stop.webm";
import video1Safari from "../../assets/PROTOTYP/UI/panak-anim-cast-kapitola-stop.mov";
import Box from "@mui/material/Box";
import ReactPlayer from "react-player";
import { isSafari } from 'react-device-detect'

const PersonAnimation = ({ isActive, variant = 0 }) => {
    const getUrl = () => {
        if (variant === 0) {
            return isSafari ? videoSafari : video
        }

        return isSafari ? video1Safari : video1
    }

   return (
        <Box
            sx={{
                position: 'absolute',
                bottom: '0',
                left: '0',
                width: '100%',
                height: '100%',
                '& div': {
                    width: '100% !important',
                    height: '100% !important',
                },
                '& video': {
                    width: 'auto !important',
                    height: '100%',
                    objectFit: 'fill',
                },
            }}
        >
            <ReactPlayer
                url={getUrl()}
                autoPlay={variant === 0}
                loop={variant === 0}
                playing={variant === 0 ? true : isActive}
                muted={true}
            />
        </Box>
    )
}

export default PersonAnimation
